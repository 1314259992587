import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from "../service/auth.service";
import userService from '../service/user.service';
import Loading from '../components/Loading';
import backgroundImg from '../../src/images/ECI88_Background.png';
// import { toast } from 'react-toastify';
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';
import ChangePassword2 from '../components/layout/ChangePassword2/ChangePassword2';
import Footer from '../components/Footer';
import useToast from '../utils/useToast';

const initialValues = {
    old_password: "",
    password: "",
    password2: "",
};

function ChangePwd() {
    const navigate = useNavigate()
    const [values, setValues] = useState(initialValues);
    const [userId, setUserId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

  const configData = useContext(ConfigContext)
  const {t} = useTranslation();


    const [validations, setValidations] = useState({
        old_password: "",
        password: "",
        password2: "",
        refreshVal: "",
    });

    const {
        old_password: currentPW,
        password: newPW,
        password2: newPWConfirm,
        refreshVal,
    } = validations;

    const getUserInfo = async () => {
        try {
            const userInfo = await userService.getBalance();
            setUserId(userInfo.data.user.id);
        } catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e) => {
        setValues((values) => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await authService.changePassword({ values, userId });
            toast.success('The password modification has been completed successfully.')
            // navigate('/')
        } catch (err) {
            console.log(err.response.data);
            setValidations({
                ...validations,
                old_password: err.response.data && err.response.data.old_password && err.response.data.old_password.old_password,
                password: err.response.data && err.response.data.password,
                password2: err.response.data && err.response.data.password2,
                refreshVal: err.response.data && err.response.data.detail,
            });
        }
    };

    useEffect(() => {
        const initialSetting = async () => {
            try {
                setIsLoading(true);
                await getUserInfo();
                if (userId) {
                    setIsLoading(true);
                }
            } catch (err) {
                console.log(err);
            }
            setIsLoading(false);
        };
        initialSetting();
    }, [localStorage.getItem("user"), userId]);

    return (
        <>
            {toast.ToastContainer}
            {
                (configData && (configData.change_password_style == "1")) || (configData && !configData.change_password_style)?
                <div className='t3' style={{ backgroundImage: `url(${backgroundImg})` }}>
                    <div id="page">
                        <div className="page-content pb-0" style={{margin: "0 15px"}}>
                            <div data-card-height="cover-full" className="card mb-0" style={{ backgroundImage: `url(${backgroundImg})` }}>
                                <div className="card-center">
                                    <div className="px-4">
                                        <div className="card-center">
                                                <h1 className="font-26 color-highlight">{t("Change_Password")}</h1>
                                                <p className="input-title my-1 font-14" style={{color: configData?configData.text_color :"" }}>{t("Current_password")} :</p>
                                                <div className={`input-style has-borders no-icon validate-field mb-4  ${values.user ? "input-style-active" : ""}`}>
                                                    <input
                                                        name="old_password"
                                                        onChange={handleChange}
                                                        type="text"
                                                        className={`form-control validate-name text-white`}
                                                        id="form1a"
                                                        placeholder={t("Current_password")}
                                                    />
                                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                                    <div className={`${currentPW === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{currentPW}</div>
                                                    <div className={`${refreshVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{refreshVal}</div>
                                                </div>

                                                <p className="input-title my-1 font-14" style={{color: configData?configData.text_color :"" }}>{t("New_password")} :</p>
                                                <div className="input-style has-borders no-icon validate-field mb-4">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="form-control validate-text text-white"
                                                        id="form3"
                                                        placeholder={t("New_password")}
                                                        onChange={handleChange}
                                                    />
                                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                                    <div className={`${newPW === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{newPW}</div>
                                                </div>
                                                <p className="input-title my-1 font-14" style={{color: configData?configData.text_color :"" }}>{t("Confirm_your_new_password")} :</p>

                                                <div className={`input-style has-borders no-icon validate-field mb-4  ${values.password2 ? "input-style-active" : ""}`}>
                                                    <input
                                                        type="password"
                                                        name="password2"
                                                        className={`form-control validate-password text-white`}
                                                        id="form1a"
                                                        placeholder={t("Confirm_your_new_password")}
                                                        onChange={handleChange}
                                                    />
                                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                                    <div className={`${newPWConfirm === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{newPWConfirm}</div>
                                                </div>

                                                <div
                                                    data-back-button className="btn mx-auto w-100 btn-full btn_green register_btn mt-4 text-uppercase"
                                                    onClick={handleSubmit}
                                                    style={{backgroundColor : configData?configData.secondary_color:""}}
                                                >
                                                    {t("Change_Password")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* <div className="card-overlay bg-black opacity-85"></div> */}
                            </div>
                        </div>
                        <div className="t1">
                            <Footer />
                        </div>
                    </div>
                    {
                        isLoading && <Loading />
                    }
                </div>
                :null
            }
            {
                (configData && (configData.change_password_style == "2"))?
                <ChangePassword2 />
                :null
            }
        </>
    )
}

export default ChangePwd