import React, { useContext, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import OtpInput from 'react-otp-input';
// import { toast } from "react-toastify";
import { Input } from "antd";
import authService from "../service/auth.service";
import { ConfigContext } from '../ConfigContext';
import useToast from '../utils/useToast';

function VerifyTac() {
    const [code, setCode] = useState();
    const [newPass, setNewPass] = useState();
  const configData = useContext(ConfigContext)
  const toast = useToast();


    const navigate = useNavigate();
    const { state } = useLocation();

    const VerifyCode = async () => {
        if (code) {
            if (code.length < 5) {
                return toast.error("OTP cannot be less than 5 digit!");
            }

            if (!newPass) {
                return toast.error("Please enter your new password!");
            }

            if (newPass.length < 6) {
                return toast.error(
                    "Password length should be equal or more than 6 characters!"
                );
            }

            try {
                const mobile = state.mobile;
                const password = newPass;
                const tac = code;
                const values = {
                    mobile,
                    password,
                    tac,
                };
                const result = await authService.resetPassword(values);

                if (result.status === 500 || result.status === 400) {
                    toast.error("Something went wrong! Please try again");
                }

                toast.success(`Password changed successfully!`);
                navigate("/signin");
            } catch (err) {
                console.log(err)
                if (err.response) {
                    const data = err.response.data;
                    if (data.password) {
                        toast.error(data.password[0]);
                    } else if (data.not_found) {
                        toast.error("TAC or mobile is invalid!");
                    }
                } else {
                    toast.error("Something went wrong! Please try again");
                }
            }
        } else {
            toast.error("OTP cannot be empty!");
        }
    };

    return (
        <div className='t3'>
            {toast.ToastContainer}
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0">
                        <div className="card-center">
                            <div className="content px-4">
                                <div className="text-center">
                                    <h1 className="font-26" style={{color: configData?configData.text_color_secondary :""}}>Change Password</h1>
                                    <p className="font-600 mb-1 font-16" style={{color: configData?configData.text_color :""}}>Enter 5 digit code that has been sent to +{state.cc} {state.mobile} and change the password.</p>
                                </div>
                                <div className="text-center mx-n3">
                                    <OtpInput
                                        containerStyle={{
                                            marginTop: "1.5rem",
                                            columnGap: "10px",
                                            justifyContent: "space-between",
                                        }}
                                        inputStyle={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "8px",
                                            color: "black",
                                            fontSize: "2rem",
                                            fontWeight: "bold",
                                        }}
                                        value={code}
                                        onChange={setCode}
                                        numInputs={5}
                                        inputType="number"
                                        separator={<span>-</span>}
                                        renderInput={(props) => (
                                            <input {...props} pattern="[0-9]*" type="number" />
                                        )}
                                        shouldAutoFocus
                                    />
                                    <Input.Password
                                        style={{ height: "50px", marginTop: "1.5rem", fontWeight: "500" }}
                                        placeholder="Enter new password"
                                        onChange={e => setNewPass(e.target.value)}
                                    />
                                    <div
                                        data-back-button className="btn btn-full font-600 font-15 gradient-highlight mt-4 rounded-xs"
                                        onClick={VerifyCode}
                                    >
                                        SUBMIT
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-overlay bg-black opacity-85"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyTac