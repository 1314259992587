import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BalanceSection from "../components/BalanceSection";
import { ConfigContext } from "../ConfigContext";
import userService from "../service/user.service";
import { useTranslation } from "react-i18next";
import BalanceSectionNew from "./BalanceSectionNew";

const BalanceWrapperNew = ({
  currentUser,
  getWalletInfo,
  walletInfo,
  StopProduct,
}) => {
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const { t } = useTranslation();
  const SITE_NAME = process.env.REACT_APP_SITE_NAME;

  const [bank, setBank] = useState(null);
  const [progressCash, setProgressCash] = useState(null)
  const [minWithdrawal, setMinWithdrawal] = useState(0)
  const [turnOver, setTurnOver] = useState(null)
  const [isLoading, setLoading] = useState(false);


  const fetchBanks = async () => {
    try {
      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        setBank({
          ...bank,
          metaData: bankData.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getTurnover = async () => {
    try {
      const turnover = await userService.getTurnover();
      if (turnover.data && turnover.data.length) {
        setTurnOver(turnover.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (turnOver) {
      const ttype = turnOver.ttype;
      const cash_balance = parseFloat(turnOver.cash_balance).toFixed(2);
      const min_turnover = parseFloat(turnOver.min_turnover).toFixed(2);
      const total_bet = parseFloat(turnOver.total_bet).toFixed(2);
      const min_rollover = parseFloat(turnOver.min_rollover).toFixed(2);
      let progressCashRate = 0
      if(ttype === "TO"){
        progressCashRate = ((cash_balance / min_turnover)*100).toFixed(2);
        setMinWithdrawal(min_turnover);
      }else if (ttype === "RO") {
        progressCashRate = ((total_bet / min_rollover)*100).toFixed(2);
        setMinWithdrawal(min_rollover);
      }
      setProgressCash(progressCashRate);
    }
  }, [turnOver]);

  useEffect(() => {
    fetchBanks();
    getTurnover();
  }, []);

  return (
    <div className="balance_wrapper">
      {/* Site Name for demo purpose, will have to remove after demo */}
      {(SITE_NAME !== "NNNN8888") ? (
        <div>
          <div className="login-container w-full flex-col" style={{borderRadius: "1rem", border: "2px solid #E6960C", boxShadow: "0 0 12px rgba(230, 150, 12, 0.8), 0 0 12px rgba(230, 150, 12, 0.8) inset"}}>
           <div className="d-flex">
           <div className="left-column p-1" style={{ display: "flex", alignItems: "center",  justifyContent: "center", flex: 1, borderRight: "2px solid #E6960C" }}>
              {
                !currentUser?
                  <div className="h-100 d-flex flex-column justify-content-center">
                    <div className="login-btn" onClick={() => navigate("/signin")}>
                      <img
                        src={configData ? configData.home_login_btn : ""}
                        className="login-image"
                        alt=""
                      />
                    </div>
                    <div className="register-btn" onClick={() => navigate("/signup")}>
                      <img
                        src={configData ? configData.home_register_btn : ""}
                        className="register-image"
                        alt=""
                      />
                    </div>
                  </div>
                  :
                  <>
                    <div className="center">
                      <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}:</div>
                      <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                      {configData && configData.currency_symbol} {currentUser && walletInfo ? walletInfo.balance : "0.00"}
                      </div>
                    </div>
                    {configData && configData.enable_chips && 
                    <div style={{ marginTop: "5px" }} className="center">
                      <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Chips")}:</div>
                      <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                        {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}
                      </div>
                    </div>
                    }
                  </>
                
              }
            </div>
            <div className="right-column" style={{flex: 2}}>
              <div className="pbalance h-100">
                <BalanceSectionNew
                  getWalletInfo={getWalletInfo}
                  walletInfo={walletInfo}
                  StopProduct={StopProduct}
                  bank={bank}
                />
              </div>
            </div>
           </div>
          {/* <div class="w-100 px-1 pt-1 profile-winover" style={{boxShadow:"none", border:"none",borderRadius:"0px", borderTop: "2px solid #E6960C"}}>
            <p class="my-1 text-center" style={{ color: configData ? configData.text_color : "" }}>
                {turnOver && turnOver.ttype == "RO" ? t("Rollover_Requirement") : t("Bonus_Winover_Requirement")}: <br />
                <span style={{ fontSize: "1.2rem" }} class="profile-text">
                    <span style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}: </span>
                    <span style={{ color: configData ? configData.text_color_secondary : "" }}>{currentUser && minWithdrawal > 0 ? Number(minWithdrawal).toFixed(2) : "0.00"}</span>
                </span>
                <a onClick={async (e) => {
                    setLoading(true)
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                }}>
                    <img class="ml-1 mt-n2" style={{ height: "15px" }} src={require("../images/Profile/refresh.png")} />
                </a>
            </p>
            <div class="progress mb-3 profile-progressbar-outer">
              <div class="progress-bar profile-progressbar-bar" role="progressbar" style={{ width: progressCash ? `${progressCash}%` : "0%", backgroundColor: configData ? configData.secondary_color : "" }} aria-valuenow="34.5" aria-valuemin="0" aria-valuemax="100">
                  {progressCash && (progressCash>100)?"100.00":progressCash}%
              </div>
            </div>
          </div> */}
          </div>
        </div>
      ) : ( 
        /* For demo purpose, will have to remove after demo */
        currentUser && (
          <div>
            <div className="login-container w-full" style={{borderRadius: "1rem", border: "2px solid #E6960C", boxShadow: "0 0 12px rgba(230, 150, 12, 0.8), 0 0 12px rgba(230, 150, 12, 0.8) inset"}}>
            <div className="left-column p-1" style={{ display: "flex", alignItems: "center",  justifyContent: "center", flex: 1, borderRight: "2px solid #E6960C" }}>
                  <div className="center">
                    <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Cash")}:</div>
                    <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                      {configData && configData.currency_symbol} {currentUser && walletInfo ? walletInfo.balance : "0.00"}
                    </div>
                  </div>
                  {configData && configData.enable_chips && 
                  <div style={{ marginTop: "5px" }} className="center">
                    <div className="balance_game" style={{ color: configData ? configData.text_color : "" }}>{t("Chips")}:</div>
                    <div className="balance_credit" style={{ color: configData ? configData.text_color_secondary : "" }}>
                      {currentUser && walletInfo ? walletInfo.chips_balance : "0.00"}
                    </div>
                  </div>
                  }
              </div>
              <div className="right-column" style={{flex: 2}}>
                <div className="pbalance h-100">
                  <BalanceSectionNew
                    getWalletInfo={getWalletInfo}
                    walletInfo={walletInfo}
                    StopProduct={StopProduct}
                    bank={bank}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default BalanceWrapperNew;
