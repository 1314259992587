import React, { useContext, useEffect, useRef, useState } from "react";
import "./LuckyWheel2.css"; // Make sure to create this CSS file to style your wheel
import { Wheel } from "react-custom-roulette";
import { Icon } from "@iconify/react";
import TransitionsModal from "../../common/TransitionsModal";
import { ConfigContext } from "../../../ConfigContext";
import userService from "../../../service/user.service";
import useToast from "../../../utils/useToast";
import { useTranslation } from "react-i18next";

const LuckyWheel2 = ({ selectedIndex, getWalletInfo, fetchTokens, tokens }) => {
  const canvasRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [segments, setSegments] = useState([]);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeCongraz, setPrizeCongraz] = useState(false);
  const [prizeCongrazOpen, setPrizeCongrazOpen] = useState(false);
  const [informationOpen, setInformationOpen] = useState(false);
  const [error, setError] = useState(null);
  const configData = useContext(ConfigContext)
  const toast = useToast();
  const { t } = useTranslation();

  const fetchSegments = async () => {
    try {
      const data = await userService.getLuckyWheelSlots();
      const API_URL = process.env.REACT_APP_APIURL;
      setLoading(true);
      if (data.data && data.data.length) {
        setSegments(
          data.data.map((v) => {
            return v.image?{
              option: `${v.amount}`,
              style: { backgroundColor: v.color, color: "#fff" },
              image: { uri: v.image?`${API_URL}${v.image}`:'', offsetY: 200, sizeMultiplier: 0.5},
              id: v.id,
            }:{
              option: `${v.amount}`,
              style: { backgroundColor: v.color, color: "#fff" },
              id: v.id,
            }
          })
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSegments();
    fetchTokens();
  }, []);

  const handleSpinClick = async () => {
    setLoading(true);
    try {
      const data = await userService.spinLuckyWheel();
      getWalletInfo();
      fetchTokens();
      if (data.data.id) {
        const selectedSlot = segments.findIndex((v) => v.id == data.data.id);
        setPrizeNumber(selectedSlot);
        setMustSpin(true);
        // setPrizeCongraz(data.data.terms_condition)
        setPrizeCongraz(`${t('Congratulations_reward_get')} ${configData && configData.currency_symbol} ${data.data.amount}`)
        setLoading(false);
        setError(null);
      } else {
        setError({ msg: "Something went wrong" });
      }
    } catch (error) {
      // console.log(error?.response)
      if (error && error.response && error.response.data && error.response.data.error) {
        setError({ msg: error.response.data.error });
      } else {
        setError({ msg: error.message });
      }
      setLoading(false);
    }
  };

  return (
    <>
    <TransitionsModal
        title={"Error"}
        open={error ? true : false}
        onClose={() => setError(false)}
        style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
        modalStyle={{zIndex:10000}}
        container={() => document.getElementById("root")}
      >
        <div>
          <div className="flex flex-row justify-center">
            <Icon icon="codicon:error" color="#F27474" width={70} />
          </div>
          <p className="text-md font-medium my-3 text-white">
            {error && error.msg ? error.msg : null}
          </p>
          <button
            className={`btn btn-sm bg-red-dark rounded-xs text-uppercase
                                  font-700 btn-l mt-3 me-2 h-12`}
            onClick={() => setError(false)}
          >
            {"Ok"}
          </button>
        </div>
      </TransitionsModal>
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: "100%",
        margin: "auto",
      }}
    >
      <div
        _ngcontent-bdv-c19=""
        class="wespin2-spin-wheel-div"
        style={{ display: "flex", position: "absolute", background: `url(${require("../../../images/luckywheel_bg.jpg")})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }}
      >
        <div
          _ngcontent-bdv-c19=""
          class="wespin2-spin-btn"
        >
          {" "}
        </div>
        <div
          className="lucky-wheel_container2"
          style={{
            position: "absolute",
            width: "75%",
            height: "75%",
            top: "5%",
            left: "50%",
            transform: "translateX(-50%) rotate(-45deg)",
          }}
        >
          {segments.length && (
            <Wheel
              outerBorderWidth={1}
              outerBorderColor="#FFCA4A"
              radiusLineWidth={1}
              radiusLineColor="#FFCA4A"
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={segments}
              backgroundColors={["#3e3e3e", "#df3428"]}
              textColors={["#ffffff"]}
              onStopSpinning={() => {
                setMustSpin(false);
                setPrizeCongrazOpen(true)
              }}
              disableInitialAnimation={true}
              pointerProps={{
                src: require("../../../images/pointer-img.png"),
                style: {
                  width: "30px",
                  transform: "rotate(45deg)",
                  top: "44px",
                  right: "32px"
                }
              }}
            />
          )}
        </div>
        <div className="d-flex mt-auto mb-4 mx-auto justify-content-center" style={{ zIndex: "10", marginTop: "150%" }}>
          <div className="tokens_container">
            {tokens} <br />
            Play
          </div>
          <button
            className={`btn btn-sm rounded-xs text-uppercase font-700 btn-l h-12 `}
            style={{ background: configData ? configData.secondary_color : "" }}
            onClick={() => !loading && handleSpinClick()}
          >
            {!loading ? (mustSpin ? "Spinning" : "Spin") : "Loading"}{" "}
          </button>
          <Icon style={{ position: 'absolute', bottom: 20, right: 20 }} icon="mdi:information-outline" width={30} onClick={() => setInformationOpen(true)}/>
          {/* <Icon style={{ position: 'absolute', bottom: 20, right: 20 }} icon="mdi:success-circle-outline" color="#4CAF50" width={30} /> */}
        </div>
      </div>
    </div>
    <TransitionsModal
        title={""}
        open={prizeCongrazOpen}
        style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
      >
        <div>
          <div className="flex flex-row justify-center">
            <Icon icon="mdi:success-circle-outline" color="#4CAF50" width={70} />
          </div>
          <p className="text-md font-medium mt-3">
            {prizeCongraz}
          </p>
          <button
            onClick={() => setPrizeCongrazOpen(false)}
            className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
          font-700 w-100 btn-l mt-3 h-12`}
          >
            {t("OK")}
          </button>
        </div>
      </TransitionsModal>
      <TransitionsModal
        title={"Lucky Wheel T&C"}
        open={informationOpen}
        style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
      >
        <div>
          <div className="flex flex-row justify-center">
            <img src={configData && configData.luckywheel_information_images} style={{ width: "100%"}}/>
          </div>
          <button
            onClick={() => setInformationOpen(false)}
            className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
          font-700 w-100 btn-l mt-3 h-12`}
          >
            {t("OK")}
          </button>
        </div>
      </TransitionsModal>
    {/* <TransitionsModal
        // title="Congratulation !"
        open={prizeCongrazOpen}
        onClose={() => setPrizeCongrazOpen(false)}
        containerStyle={{
          width: "60%", 
          overflowY: "auto", 
          maxWidth: "960px"
        }}
        style={{
          textAlign: "center",
          borderRadius: "15px",
        }}
      >
        <div
            className="sun-editor-editable"
            style={{
              padding: "10px"
            }}
            dangerouslySetInnerHTML={{
            __html: `${prizeCongraz}`,
            }}
        ></div>
      </TransitionsModal> */}
    </>
  );
};

export default LuckyWheel2;
