import React from 'react';
import { useTranslation } from 'react-i18next';

function BankItem({ item, bankName }) {
    const {t} = useTranslation();

    return (
        <div className="bank-acc-item">
            <div className="d-flex flex-row items-center">
                <img
                    src={item.icon}
                    alt="bank-thumbnail"
                    className="w-20 h-16 rounded-md"
                    style={{ objectFit: "cover" }}
                />
                <div className="ml-1">
                    <p className="h5 font-bold">
                        {t("Bank")}: {bankName}
                    </p>
                    <p className="text-15 font-medium">{t("Account")}: {item.number}</p>
                </div>
            </div>
            <p className="text-15" style={{background: "rgba(255, 255, 255, 0.1)", padding: "5px", marginTop: "10px"}}>{t("Account")} {t("Holder")}: {item.name}</p>
        </div>
    );
}

export default BankItem;
