import React, { useContext } from 'react'
import Forget from '../Forget'
import ForgetV2 from '../ForgetV2'
import { ConfigContext } from '../../ConfigContext';
import Forget4 from '../../components/layout/ForgetPassword/ForgetPassword4';

function ForgetPassword() {
  const configData = useContext(ConfigContext);
  const signupVersion = configData && configData.signup_version ? configData.signup_version : process.env.REACT_APP_SIGNUP_VERSION;

  return (
    <>
    {configData && (configData.login_style == "4")?<Forget4 />:(signupVersion === "V1" ? (
      <Forget />
    ) : (
      <ForgetV2 />
    ))}
  </>
  )
}

export default ForgetPassword