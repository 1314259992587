import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../ConfigContext";
import tokenService from "../../../service/token.service";
import authService from "../../../service/auth.service";
import { Icon } from "@iconify/react";
import Input4 from "./Input4";
import Button4 from "./Button4";

const initialValues = {
  username: "",
  password: "",
};

function SignIn4({ setAuth }) {
  const [values, setValues] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
  const configData = useContext(ConfigContext);
  const { t } = useTranslation();

  const [validations, setValidations] = useState({
    username: "",
    password: "",
    refresh: "",
  });
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token_id = searchParams.get("token_id");
  const signupVersion =
    configData && configData.signup_version
      ? configData.signup_version
      : process.env.REACT_APP_SIGNUP_VERSION;

  useEffect(() => {
    if (token_id) {
      tokenService.removeUser();
      handleStealthLogin(token_id);
    }
  }, [token_id]);

  const handleStealthLogin = async (tokenId) => {
    setLoading(true);
    try {
      const { data } = await authService.stealthLogin(tokenId);
      if (data && data.access) {
        setTimeout(() => {
          tokenService.setUser(data);
          setLoading(false);
          navigate("/");
          setAuth(true);
        }, 500);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const {
    username: nameVal,
    password: passVal,
    refresh: refreshVal,
  } = validations;

  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await authService.login(values);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      navigate("/");
      setAuth(true);
    } catch (err) {
      console.log("err ==> ", err);
      let username_msg;
      let password_message;
      let refresh_msg;
      if (err.response && err.response.data && err.response.data.username) {
        username_msg = err.response.data.username;
      }
      if (err.response && err.response.data && err.response.data.refresh) {
        refresh_msg = err.response.data.refresh;
      }
      if (err.response && err.response.data && err.response.data.detail) {
        refresh_msg = err.response.data.detail;
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
      }
      setValidations({
        ...validations,
        username: username_msg,
        password: password_message,
        refresh: refresh_msg,
      });
      setLoading(false);
    }
  };

  return (
    <div className="signin4_container" style={{ padding: "52px 25px 0" }}>
      <div
        className="logo_container d-flex justify-content-center"
        style={{ marginBottom: "41.6px" }}
      >
        <img
          style={{ height: "92px" }}
          src={configData ? configData.logo : ""}
          alt=""
        />
      </div>
      <div
        className="logo_container d-flex mb-4"
        style={{ width: "100%", borderRadius: "12px", overflow: "hidden" }}
      >
        <img
          src={configData ? configData.login_image : ""}
          alt=""
          style={{ width: "100%" }}
        />
      </div>
      <div className="mb-3">
        <Input4
          leftIcon={
            <Icon
              icon="ph:user-bold"
              fontSize="26px"
              color={configData ? configData.text_color : ""}
            />
          }
          rightIcon={
            <Icon
              icon="basil:cross-solid"
              fontSize="40px"
              color={configData ? configData.text_color : ""}
              onClick={() =>
                setValues((values) => {
                  return { ...values, username: "" };
                })
              }
            />
          }
          type="text"
          name="username"
          value={values.username}
          placeholder="Username"
          onChange={handleChange}
        />
      </div>
      {validations && <span class="standard-required-message">{validations.username}</span>}
      <div>
        <Input4
          leftIcon={
            <Icon
              icon="mdi:password-outline"
              fontSize="26px"
              color={configData ? configData.text_color : ""}
            />
          }
          rightIcon={
            <Icon
              icon={showPassword ? "tabler:eye" : "tabler:eye-off"}
              fontSize="30px"
              color={configData ? configData.text_color : ""}
              onClick={() => setShowPassword(!showPassword)}
            />
          }
          type={showPassword ? "text" : "password"}
          name="password"
          value={values.password}
          placeholder="Password"
          onChange={handleChange}
        />
      </div>
      {validations && <span class="standard-required-message-red">{validations.password}</span>}
      <p
        className="input-title text-right font-14 mb-5"
        style={{
          color: configData ? configData.text_color : "",
          textAlign: "right",
        }}
        onClick={(e) => {
          e.preventDefault();
          signupVersion && signupVersion === "V3"
            ? navigate("/contactus")
            : navigate("/forget-password");
        }}
      >
        {t("Forget_Password")}
      </p>
      <Button4 onClick={handleSubmit}>{t('LOGIN')}</Button4>
      <div className="d-flex mt-3" style={{gap: "10px"}}>
        <div className="login4-card p-2 d-flex flex-column justify-content-center align-items-center" style={{flex: 1, borderRadius: "12px", background: "rgba(255,255,255,.2)"}} onClick={() =>{
            navigate("/signup")
        }}>
          <Icon
            icon="mingcute:user-4-line"
            fontSize="30px"
            color={configData ? configData.text_color : ""}
          />
          <p style={{color: configData ? configData.text_color : ""}}>{t("REGISTER")}</p>
        </div>
        <div className="login4-card p-2 d-flex flex-column justify-content-center align-items-center" style={{flex: 1, borderRadius: "12px", background: "rgba(255,255,255,.2)"}} onClick={() =>{
            navigate("/")
        }}>
          <Icon
            icon="mingcute:user-4-line"
            fontSize="30px"
            color={configData ? configData.text_color : ""}
          />
          <p style={{color: configData ? configData.text_color : ""}}>{t("Home")}</p>
        </div>
        <div className="login4-card p-2 d-flex flex-column justify-content-center align-items-center" style={{flex: 1, borderRadius: "12px", background: "rgba(255,255,255,.2)"}} onClick={() =>{
            navigate("/contactus")
        }}>
          <Icon
            icon="mingcute:user-4-line"
            fontSize="30px"
            color={configData ? configData.text_color : ""}
          />
          <p style={{color: configData ? configData.text_color : ""}}>{t("Contact_Us")}</p>
        </div>
      </div>
    </div>
  );
}

export default SignIn4;
