import React, { useContext } from 'react'
import "../layout.css"
import { ConfigContext } from '../../../ConfigContext'

function HistoryTabs2({ defaultActiveKey, activeKey, items, onChange }) {
  const configData = useContext(ConfigContext)
  return (
    <div className='d-flex flex-column w-100'>
      <div className="reporting-nav-bar w-100" style={{border: configData?`1px solid ${configData.border_color}`:""}}>
        {
          items.map(item=>(
            item &&
            <a href="#" style={{backgroundColor: configData?((activeKey == item.key)?configData.secondary_color:""):"", color: configData?((activeKey == item.key)?configData.text_color:""):""}} onClick={() => onChange(item.key)}>
              {item.label}
            </a>
          ))
        }
      </div>
      {
        items.filter(item=>activeKey==item.key)[0].children
      }
    </div>
  )
}

export default HistoryTabs2