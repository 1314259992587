import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import LayoutCard from "../common/LayoutCard";
import { ConfigContext } from "../../../ConfigContext";
import userService from "../../../service/user.service";
import authService from "../../../service/auth.service";
import Footer from "../../Footer";
import MenuNotification from "../../MenuNotification";
import "../layout.css"
import Loading from "../../Loading";

const initialValues = {
    amount: "",
    customer_bank_account: "",
    bank_number: "",
};

function Withdraw2({ walletInfo }) {
    // const { mode } = useContext(UserContext);

    const [values, setValues] = useState(initialValues);
    const [cuBanks, setCuBanks] = useState([]);
    const [bankNumber, setBankNumber] = useState();
    const [isLoading, setLoading] = useState(false);
    const [startedGames, setStartedGames] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [isTxnModal, setIsTxnModal] = useState(false);
    const [txnId, setTxnId] = useState(false);
    const { t } = useTranslation();

    const configData = useContext(ConfigContext)

    const [creditTypes] = useState([
        {
            key: "1",
            value: "CA",
            label: t("Cash"),
        },
        {
            key: "2",
            value: "CH",
            label: t("Chips"),
        },
    ]);
    const [creditTypesCash] = useState([
        {
            key: "1",
            value: "CA",
            label: t("Cash"),
        },
    ]);

    const [selectedCredit, setSelectedCredit] = useState("");
    const [selectedBank, setSelectedBank] = useState(null);

    const navigate = useNavigate();

    const [validations, setValidations] = useState({
        amount: "",
        customer_bank_account: "",
        non_field_errors: "",
        turnover_error: "",
        credit: ""
    });

    const {
        amount: amountVal,
        customer_bank_account: bank_Val,
        non_field_errors: nonVal,
        credit: creditValue,
    } = validations;

    const getCustomBanklist = async () => {
        const custombanks = await userService.getCustomBanks();
        let custombanklist = [];
        custombanks.data.forEach((game) => {
            if (game) {
                custombanklist.push({
                    value: game.id,
                    label: game.name,
                    icon: game.icon,
                    number: game.number,
                    name: game.name,
                });
            }
        });
        setCuBanks(custombanklist);
    };
    const getStartedGames = async (currentUser) => {
        try {
          const startedProducts = await userService.getStartedGames(currentUser);
          if (startedProducts.data) {
            setStartedGames(startedProducts.data);
          }
        } catch (error) {
          setStartedGames([]);
        }
    };
    const StopProduct = async (id) => {
        setLoading(true);
        try {
          await userService.stopProduct(id);
        } catch (err) {
          console.log(err);
        }
        window.location.reload();
        setLoading(false);
    };
    useEffect(() => {
        if (walletInfo && walletInfo.user && walletInfo.user.id) {
          getStartedGames(walletInfo.user.id);
        } 
    }, [walletInfo]);
    const handleWithdraw = async (e) => {
        e.preventDefault();
        // setLoading(true);

        try {
            const response = await userService.withdraw(values);
            setTxnId(response.data.id);
            setIsTxnModal(true);
            navigate("/history", { state: selectedCredit });

            setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values);
            let non_msg;
            let amount_msg;
            let bank_msg;
            let credit_msg;
            let turnover_error_msg;

            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.amount
            ) {
                amount_msg = err.response.data.amount;
            }
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.credit_type
            ) {
                credit_msg = err.response.data.credit_type;
            }
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.customer_bank_account
            ) {
                bank_msg = err.response.data.customer_bank_account;
            }
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.non_field_errors
            ) {
                non_msg = err.response.data.non_field_errors;
            }
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.turnover_error
            ) {
                turnover_error_msg = err.response.data.turnover_error;
            }
            setValidations({
                ...validations,
                amount: amount_msg,
                customer_bank_account: bank_msg,
                non_field_errors: non_msg,
                turnover_error: turnover_error_msg,
                credit: credit_msg,
            });
        }
        // setLoading(false);
    };

    const handleCreditChange = (e, newCreditType) => {
        setSelectedCredit(newCreditType.value);
        setValues((values) => {
            return { ...values, credit_type: newCreditType.value };
        });
    };

    const handleChange = (e, newBank) => {
        const bankNumber = cuBanks.find((item) => item.value === newBank.value).number;
        setBankNumber(bankNumber);
        setValues((values) => {
            return { ...values, customer_bank_account: newBank.value };
        });
    };

    // const handleNumChange = (e) => {
    //   const RE = /^\d*\.?\d{0,18}$/;
    //   if (RE.test(e.target.value) || e.target.value === "") {
    //     setValues((values) => {
    //       return { ...values, [e.target.name]: e.target.value };
    //     });
    //   }
    // };

    const maxAmount = (val) => {
        if (walletInfo) {
            setValues((values) => {
                return { ...values, amount: val };
            });
        }
    };

    const getUserInfo = async () => {
        const user = await authService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    };

    useEffect(() => {
        if (selectedCredit) {
            switch (selectedCredit) {
                case 'CA':
                    maxAmount(walletInfo.balance);
                    break;
                case 'CH':
                    maxAmount(walletInfo.chips_balance);
                    break;
                default:
                    maxAmount(0);
            }
        }
    }, [selectedCredit]);

    useEffect(() => {
        getCustomBanklist();
        getUserInfo();
    }, []);

    return (
        <>
            <div className="withdraw2-container layout2 px-2 py-3" style={{color: configData?configData.text_color:"#fff", backgroundColor: configData?configData.primary_color:"#151515"}}>
                <div class="tab-menu-container" style={{border: configData?`1px solid ${configData.secondary_color}`:"", color: configData?configData.text_color:"#fff"}}>
                    <a style={{color: configData?configData.text_color:"#999", borderRight: configData?configData.border_color_secondary:"#000"}} href="#" data-active="false" onClick={(e) => {
                        e.preventDefault();
                        navigate("/deposit");
                    }}>
                        {t("Deposit")}
                    </a>
                    <a style={{color: configData?configData.text_color:"#999", borderRight: configData?configData.border_color_secondary:"#000", backgroundColor: configData?configData.secondary_color:""}} href="#" data-active="true" onClick={(e) => {
                        e.preventDefault();
                        navigate("/withdraw");
                    }}>
                        {t("Withdraw")}
                    </a>
                </div>
                <LayoutCard>
                    <div className="deposit-form-group">
                        <div className="form-group">
                            <div className="to-account-label-container">
                                <label style={{color: configData?configData.text_color:"#fff"}} for="ToAccount">{t("To_Number")}</label>
                                {/* <span id="view_all_available_banks">View All</span> */}
                            </div>
                            <select style={{
                                backgroundColor: configData?configData.inputs_bg:"", 
                                color: configData?configData.text_color:"",
                                border: "1px solid #e4ae60"
                            }} onChange={(e) => {
                                const newBank = JSON.parse(e.target.value)
                                setSelectedBank(newBank)
                                handleChange(e, newBank)
                            }} name="merchant_bank_account" id="deposit_bank_select" className="form-control" >
                                <option disabled selected>
                                    {t("Select_Bank")}
                                </option>
                                {
                                    cuBanks.map((bank, index) => (
                                        <option value={JSON.stringify(bank)}>
                                            {bank.name}|{bank.number}
                                        </option>
                                    ))
                                }
                            </select>
                            {/* <span className="standard-required-message">Please select company bank to deposit</span> */}
                        </div>
                        {
                            validations &&
                            <span class="standard-required-message">{validations.customer_bank_account}</span>
                        }
                        {
                            selectedBank &&
                            <div className="form-group">
                                <div data-section="input" data-bank-type="pulsa" className="bank-info" id="bank_info" data-high-priority="false">
                                    <div data-bank-info="header">
                                        <h1 id="bank_info_account_name"></h1>
                                        <div id="bank_info_logo">
                                            <img src={selectedBank.icon} style={{ display: "block" }} /></div>
                                        <h3 id="bank_info_name" className="bank-name">{selectedBank.name}</h3>
                                    </div>
                                    <div data-bank-info="details" style={{color: configData?configData.text_color:""}}>
                                        <h2 id="bank_info_account_no">{selectedBank.number}</h2>
                                    </div>
                                    <hr />
                                    <div data-bank-info="actions">
                                        <button className="copy-bank-account-button" id="copy_bank_account_button" type="button" style={{color: configData?configData.text_color_secondary:""}}>
                                            {t("Copy")}
                                        </button>
                                    </div>
                                    {/* <input id="bank_info_account_no_hidden_input" name="ToAccountNumber" type="hidden" value="0813-8906-0076" /> */}
                                </div>
                            </div>
                        }
                    </div>

                </LayoutCard>
                <LayoutCard>
                    <div className="deposit-form-group">
                        <div className="form-group">
                            <div className="to-account-label-container">
                                <label style={{color: configData?configData.text_color:"#fff"}} for="ToAccount">{t("Credit_Type")}</label>
                            </div>
                            <select style={{
                                backgroundColor: configData?configData.inputs_bg:"", 
                                color: configData?configData.text_color:"",
                                border: "1px solid #e4ae60"
                            }} onChange={(e) => {
                                const newCreditType = JSON.parse(e.target.value)
                                handleCreditChange(e, newCreditType)
                            }}
                                name="merchant_bank_account" id="deposit_bank_select" className="form-control" >
                                <option disabled selected>
                                    {t("Credit_Type")}
                                </option>
                                {
                                    creditTypesCash.map((creditType, index) => (
                                        <option value={JSON.stringify(creditType)}>
                                            {creditType.label}
                                        </option>
                                    ))
                                }
                            </select>
                            {/* <span className="standard-required-message">Please select company bank to deposit</span> */}
                        </div>
                        {
                            validations &&
                            <span class="standard-required-message">{validations.merchant_bank_account}</span>
                        }
                    </div>

                </LayoutCard>
                <LayoutCard>
                    <div className="form-group deposit-form-group">
                        <label style={{color: configData?configData.text_color:"#fff"}} for="Amount">{t("Amount")}</label>
                        <div className="amount-container">
                            <input style={{
                                backgroundColor: configData?configData.inputs_bg:"", 
                                color: configData?configData.text_color:"",
                                border: "1px solid #e4ae60"
                                }} readOnly autocomplete="off" className="form-control deposit_amount_input" data-val="true" data-val-required="The Amount field is required." id="Amount" name="amount" type="text" value={values.amount} />
                            {/* <span className="standard-required-message">Please input number only for deposit amount.</span> */}
                            {
                                values &&
                                <div className="real-deposit-amount" id="real_deposit_amount" data-title={t("Total_Amount_Need")}>{values.amount}</div>
                            }
                        </div>
                        {
                            validations &&
                            <span class="standard-required-message">{validations.amount}</span>
                        }
                    </div>
                </LayoutCard>
                {
                    validations && 
                    validations.non_field_errors ? 
                        <span class="standard-required-message">{validations.non_field_errors}</span>
                    : validations.turnover_error && 
                    <div>
                    {
                        <span class="standard-required-message">
                        {validations.turnover_error && validations.turnover_error.map((item, index) => (
                            t(item)
                        ))}
                        </span>
                    }
                    </div>
                }
                <div className="row">
                    <div className="col-6" style={{ textAlign: "center "}}>
                        <button
                        style={{
                            backgroundColor: configData ? configData.withdraw_chip_bg : "",
                            color: configData ? configData.text_color : ""
                        }}
                        className="secondary-button text-uppercase" 
                        onClick={async (e) => {
                            setLoading(true)
                            startedGames.forEach(startedGame => {
                            StopProduct(startedGame.id)
                            })
                            setTimeout(() => {
                            setLoading(false)
                            }, 500);
                        }}
                        >
                        {t("Refresh")}
                        </button>
                    </div>
                    <div className="col-6" style={{ textAlign: "center "}}>
                        <button 
                        style={{
                            backgroundColor: configData?configData.secondary_color:"", 
                            color: configData?configData.text_color:""
                        }} 
                        className="secondary-button text-uppercase" 
                        onClick={handleWithdraw}>
                            {t("Withdraw")}
                        </button>
                    </div>
                </div>

                
                <div className="d-flex justify-content-center" 
                style={{ color: "white", fontWeight: "bold", marginTop: "15px" }}>
                {t("Refresh_before_submit")}
                </div>
            </div>
            <div className="t1">
                <Footer />
                {isLoading && <Loading />}
            </div>
        </>
    );
}

export default Withdraw2;
