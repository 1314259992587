import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import MenuSidebar from "./MenuSidebar";
import { ConfigContext } from "../ConfigContext";
import Marquee from "react-fast-marquee";
import userService from "../service/user.service";

function Subline() {
  const [newsfeeds, setNewsfeeds] = useState([]);
  const [socials, setSocials] = useState([]);

  const configData = useContext(ConfigContext);

  const getNewsfeedsList = async () => {
    try {
      const newslist = await userService.getNewsfeeds();
      setNewsfeeds(newslist && newslist.data ? newslist.data : []);
    } catch (err) {
      console.log(err);
    }
  };
  const socialContact = async () => {
    try {
      const data = await userService.getSocials();
      if (data && data.data && data.data.length) {
        setSocials(data.data);
      }
    } catch (error) {
      setSocials([]);
    }
  };
  useEffect(() => {
    getNewsfeedsList();
    socialContact();
  }, []);

  return (
    <div className="t1">
      <div className="page">
        <div
          className="noticebar mb-1"
          style={{
            // height: "30px",
            backgroundImage: `url(${configData ? configData.marquee_bg : ""})`,
          }}
        >
          <Marquee
            style={{
              height: `${configData ? configData.marquee_height : 10}px`,
            }}
          >
            {newsfeeds.map((feed, newsindex) => (
              <span
                key={newsindex}
                style={{
                  color: configData ? configData.marquee_text_color : "",
                }}
              >
                {feed.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            ))}
          </Marquee>
        </div>
        <div className="subline">
          {socials && socials.length
            ? socials.map((social) => (
                <div className="cell col-6 col-md-4">
                  <a
                    key={social.id}
                    target={social.action_link ? "_blank" : ""}
                    href={social.action_link ? social.action_link : "#"}
                  >
                    <img
                      src={social.image}
                      alt={social.provider}
                      className="subline_image"
                      style={{
                        border: `2px solid  ${
                          configData ? configData.border_color : ""
                        }`,
                        height: "auto",
                        borderRadius: "15px",
                      }}
                    />
                  </a>
                </div>
              ))
            : null}
        </div>
      </div>
      <Footer />
      <MenuSidebar />
    </div>
  );
}

export default Subline;
