import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import userService from "../service/user.service";
import Loading from "../components/Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

function DGPayEWallets() {
  const [ewalletsList, setEWalletsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();
  const [ewalletTypes, setEWalletTypes] = useState([])


  useEffect(() => {
    getMerchantEWalletslist();
  }, []);

  const getBackgroundClass = (item) => {
    if (item.name === "Dana") {
      return configData?configData.deposit_dana :"";
    } else if (item.name === "OVO") {
      return configData?configData.deposit_ovo :"";
    } else if (item.name === "ShopeePay") {
      return configData?configData.deposit_shopeepay :"";
    } else if (item.name === "Qris") {
      return configData?configData.deposit_qris :"";
    } else if (item.name === "TNG") {
      return configData?configData.deposit_qris :"";
    } else if (item.name === "DQR") {
      return configData?configData.deposit_qris :"";
    }
    return "";
  };

  const getMerchantEWalletslist = async () => {
    setLoading(true);
    const ewallets = await userService.getMerchantEWallets('dgpay-ewallet');
    setEWalletsList(ewallets.data);
    setLoading(false);
  };
  
  useEffect(() => {
    if (configData && configData.country == "MY") {
      console.log("ewalletsList", ewalletsList, configData.country)
      setEWalletTypes(ewalletsList)
    } else {
      setEWalletTypes([{id:10,name:"Dana"},{id:11,name:"OVO"},{id:12,name:"ShopeePay"},{id:30,name:"Qris"}])
    }
  }, [ewalletsList, configData])
  
    return (
        <div className='page' style={{ height: 'calc(100vh - 114px)', overflow: 'scroll' }}>
            <div className="deposit-wrap d-flex flex-wrap justify-center align-center">
            <div className="deposit-title">
                <h6 className="deposit-texttitle" style={{ color: configData?configData.text_color_secondary :"" }}>{t("Choose_a_Payment_Method")}</h6>
            </div>
                <div className="mx-2 rounded-m">
                {
                     ewalletTypes.map((item, index) => {
                        return (
                            <div className={`deposit_payment`} key={index} onClick={() => navigate(`/dgpay-ewallet/${item.id}`)}>
                                <div className="tng-background" style={{backgroundImage: `url(${getBackgroundClass(item)})`}}>
                                    <div className="d-flex flex-col justify-center" style={{ width: "calc(100% - 45px)" }}></div>
                                </div>
                            </div>

                        )
                    })
                  }
                </div>
            </div>
            <div className="t1">
                <Footer />
            </div>
            <MenuNotification />
            {
                isLoading && <Loading />
            }
        </div>
    )
}

export default DGPayEWallets;
