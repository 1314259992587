import React, {useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import MenuSidebar from "../components/MenuSidebar";

import { Empty, Form, Input, Modal } from "antd";
import userService from "../service/user.service";
// import { toast } from "react-toastify";
import SkeletonWrapper from "../components/common/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import QRCode from "react-qr-code";

import authService from "../service/auth.service";
import { ConfigContext } from "../ConfigContext";
import useToast from "../utils/useToast";

function Settings({setCurrentUser }) {
    const navigate = useNavigate();
  const configData = useContext(ConfigContext)


  // const { mode } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  // const [orders, setOrders] = useState([]);
  // const [transfers, setTransfers] = useState([]);
  // const [bonuses, setBonuses] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalOpen, setModal] = useState(false);
  const [referModalOpen, setReferModal] = useState(false);
  const [bankModalOpen, setBankModal] = useState(false);
  const [userId] = useState("");
  const toast = useToast();


  const handleFormFailed = () => {
    toast.error("No inputs should be empty!");
  };

  const handleFormFinish = async (values) => {
    setModal(false);
    try {
      await authService.changePassword({ values, userId:wallet.user.id });
      toast.success("Password changed successfully!");
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.old_password) {
          const msg = data.old_password.old_password;
          return toast.error(msg);
        } else if (data.password) {
          const msg = data.password[0];
          return toast.error(msg);
        } else if (data.password2) {
          const msg = data.password2[0];
          return toast.error(msg);
        } else if (data.detail) {
          const msg = data.detail[0];
          return toast.error(msg);
        } else {
          return toast.error("Failed to change the password!");
        }
      }
    }
  };

  const getWalletInfo = async () => {
    setLoading(true);
    try {
      const wallet = await userService.getBalance();
      setWallet(wallet.data);
      // getTransactions(wallet.data.user.id);
    } catch (err) {
      console.log(err);
    }
  };

  // const getTransactions = async (userId) => {
  //   setLoading(true);
  //   try {
  //     const orderData = await userService.getOrders(userId);
  //     const transferData = await userService.getTransfers(userId);
  //     const bonusData = await userService.getBonues(userId);
  //     if (orderData.data) {
  //       setOrders(orderData.data);
  //     }
  //     if (transferData.data) {
  //       setTransfers(transferData.data);
  //     }
  //     if (bonusData.data) {
  //       setBonuses(bonusData.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setLoading(false);
  // };

  const fetchCustomerBank = async () => {
    setLoading(true);
    try {
      const result = await userService.getCustomBanks();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!wallet) {
      getWalletInfo();
    }
  }, [wallet]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchCustomerBank();
  }, []);

  const handleLogout = () => {
    authService.logout();
    setCurrentUser(null);
    navigate("/signin");
  };

  

  return (
    <div className="t1">
      {toast.ToastContainer}
      <div className="page">
        <div className="page-content footer-clear">
          {loading ? (
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <SkeletonWrapper>
                <Skeleton circle width={100} height={100} />
                <Skeleton
                  style={{ marginTop: "1rem" }}
                  count={2}
                  width={windowWidth - 32}
                  height={100}
                  borderRadius={16}
                />
                <Skeleton
                  style={{ marginTop: "2rem" }}
                  count={1}
                  width={windowWidth - 32}
                  height={16}
                />
                <Skeleton
                  style={{ marginTop: "1rem" }}
                  count={4}
                  width={windowWidth - 32}
                  height={80}
                />
              </SkeletonWrapper>
            </div>
          ) : (
            <>
              {wallet ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "1rem",
                    padding: "1rem",
                  }}
                >
                  
                  <div style={{ marginTop: "8px", width: "100%" }}>
                  <div className="balance_game" style={{fontSize:"28px",marginBottom:"5px", color: configData?configData.text_color :""}}>
                  {wallet && wallet.username
                    ? wallet.username
                    : "No Account"}
                  </div>
                <div className="balance_credit" style={{fontSize:"24px", color: configData?configData.text_color_secondary :""}}> 
                RM{wallet && wallet.balance ? wallet.balance : "0"}
                </div>
              
                  <div id="settings">
                  <div className="setting_head mb-3 mt-3">SETTING
                    <div className="change_pswd w-100" 
                    onClick={e => {
                    e.preventDefault()
                    navigate("/account")
                    }}
                    >
                    </div> 
                  </div>
                  <div className="setting_head mt-3 mb-3"
                    onClick={e => {
                    e.preventDefault()
                    navigate("/account")
                }}
                >
                  <img src={require("../image/icon-account.png")} alt="Account Logo" style={{width:"30px", height:"30px",marginLeft:"5px ", marginRight:"10px"}} />
                  Game Account
                  </div>

                  <div className="setting_head mt-3 mb-3"
                  onClick={e => {
                  setBankModal(true);
                }}
                >
                  <img src={require("../image/icon-bank.png")} alt="Bank Logo" style={{width:"30px", height:"30px",marginLeft:"5px ", marginRight:"10px"}} />
                  Bank Account
                  </div>

                  <div className="setting_head mt-3 mb-3"
                  onClick={e => {
                  setModal(true);
                }}
                >
                  <img src={require("../image/icon-password.png")} alt="Pass Logo" style={{width:"30px", height:"30px",marginLeft:"5px ", marginRight:"10px"}} />
                  Change Password
                  </div>

                  <div className="setting_head mt-3 mb-3"
                  onClick={e => {
                  setReferModal(true);
                }}
                >
                  <img src={require("../image/icon-qrcode.png")} alt="QR Logo" style={{width:"30px", height:"30px",marginLeft:"5px ", marginRight:"10px"}} />
                  View QR Code
                  </div>

                  <div className="setting_head mt-3 mb-3" href=".#"
                  onClick={handleLogout}
                >
                  <img src={require("../image/icon-logout.png")} alt="Logout Logo" style={{width:"30px", height:"30px",marginLeft:"5px ", marginRight:"10px"}} />
                  Logout
                  </div>
            </div>
                    <div style={{ marginTop: "1rem " }}>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <Empty description="No account!" />
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
        <MenuNotification />
        <MenuSidebar />
      </div>
      <Modal
        title="Change Password"
        open={modalOpen}
        footer={[]}
        onCancel={() => setModal(false)}
      >
        <div className="modal-box">
          <Form onFinish={handleFormFinish} onFinishFailed={handleFormFailed}>
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px", marginTop: "1.5rem" }}
                placeholder="Current password"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px" }}
                placeholder="New password"
              />
            </Form.Item>
            <Form.Item
              name="password2"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
            >
              <Input.Password
                style={{
                  marginBottom: "8px",
                  padding: "12px",
                }}
                placeholder="Confirm new password"
              />
            </Form.Item>
            <div className="balance_btns" style={{ width: "100%" }}>
              <button
                type="submit"
                className="btns"
                style={{
                  width: "100%",
                  flex: 1,
                  border: "none",
                  padding: "10px 0",
                }}
              >
                Change Password
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Refer App"
        open={referModalOpen}
        footer={[]}
        onCancel={() => setReferModal(false)}
      >
        <div>
          {wallet ? (
            <div className="center" style={{ paddingTop: "1rem" }}>
              <QRCode value={wallet.referral_link} />
              <Input
                style={{ marginTop: "1rem" }}
                value={wallet.referral_link}
              />
            </div>
          ) : (
            <Empty description="Unable to load wallet!" />
          )}
        </div>
      </Modal>

      <Modal
        title="Bank Account"
        open={bankModalOpen}
        footer={[]}
        bodyStyle={{ height: "200px" }}
        style={{ height: "200px" }}
        onCancel={() => setBankModal(false)}
      >
        <div className="modal-box">
          <Form onFinish={handleFormFinish} onFinishFailed={handleFormFailed}>
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px", marginTop: "1.5rem", height: "60px" }}
                placeholder="Current password"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px", height: "60px" }}
                placeholder="New password"
              />
            </Form.Item>
            <Form.Item
              name="password2"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
            >
              <Input.Password
                style={{
                  marginBottom: "8px",
                  padding: "12px",
                  height: "60px",
                }}
                placeholder="Confirm new password"
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default Settings
