import countriesData from "./countries.json"

// const AVAILABLE_LANG = process.env.REACT_APP_AVAILABLE_LANG?JSON.parse(process.env.REACT_APP_AVAILABLE_LANG):files;
// export const languages = AVAILABLE_LANG.map(file => {
//     return {
//         key: file,
//         file: require(`./lang/${file}.json`),
//     }
// });

export const countries = countriesData.map(country => {
    return {
        ...country,
        flag: require(`./flags/${country.country}-flag.png`),
    }
});