import React from 'react'

function MenuNotification() {
    return (
        <div id="menu-notifications"
            className="offcanvas offcanvas-top offcanvas-detached rounded-m d-block"
            style={{ backgroundColor: "#fff" }}
        >
            <div className="menu-size" style={{ height: "90vh" }}>
                <div className="content pb-2">
                    <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">Deposit $100 Approved</h5>
                            <p className="mb-0 font-11 opacity-70">14th March 03:14 AM</p>
                        </div>
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                <i className="fa fa-check color-green-dark fa-3x"></i>
                            </span>
                        </div>
                    </a>
                    <div className="divider my-2 opacity-50"></div>
                    <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">Withdraw $100 Rejected</h5>
                            <p className="mb-0 font-11 opacity-70">14th March 03:14 AM</p>
                        </div>
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                <i className="fa-solid fa-circle-minus color-red-dark fa-3x"></i>
                                {/* <i className="fa-solid fa-ban color-red-dark fa-3x"></i> */}
                            </span>
                        </div>
                    </a>
                    <div className="divider my-2 opacity-50"></div>
                    <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">Rebate $10 Approved</h5>
                            <p className="mb-0 font-11 opacity-70">14th March 03:14 AM</p>
                        </div>
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                <i className="fa fa-check color-green-dark fa-3x"></i>
                            </span>
                        </div>
                    </a>
                    <div className="divider my-2 opacity-50"></div>
                    <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">Deposit $100 Approved</h5>
                            <p className="mb-0 font-11 opacity-70">14th March 03:14 AM</p>
                        </div>
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                <i className="fa fa-check color-green-dark fa-3x"></i>
                            </span>
                        </div>
                    </a>
                    <div className="divider my-2"></div>
                </div>
            </div>
        </div>
    )
}

export default MenuNotification