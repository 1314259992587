import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ConfigContextProvider } from './ConfigContext';
import { LanguageContextProvider } from './i18n/LanguageContext';
import { initializeI18next } from './i18n';


const root = ReactDOM.createRoot(document.getElementById('root'));

// export const ConfigContext = React.createContext()
initializeI18next();

root.render(
    <BrowserRouter>
    <LanguageContextProvider>
    <ConfigContextProvider>
        <App />
    </ConfigContextProvider>
    </LanguageContextProvider>
    </BrowserRouter>
);
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
