import React, { useContext} from 'react'
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';

function Captcha({setCaptcha, setText, captcha, valid}) {
    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    let rString = randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    const refreshString = () => {
        setCaptcha(rString)
    }
  

    const configData = useContext(ConfigContext);
    const { t } = useTranslation();
    return (
        <div className="form-group mb-2 standard-password-field">
            <label style={{ color: configData ? configData.text_color : "#fff" }}>
                {t("Validation_Code")}
            </label>
            <div className="captcha_input">
               
               <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} 
               type="text" 
               name="validation-code" 
               className="form-control w-50" 
               placeholder={t("Validation_Code")} 
               onChange={(e) => setText(e.target.value)} 
               id="password_input"
               maxLength="6" />
                
                <div className="captcha-container">
                    <span className="captcha_text">{captcha}</span>
                    <i class="fa-solid fa-rotate" onClick={() => refreshString()}></i>
                </div>
            
            </div>
            <div>{valid && <span class="standard-required-message">Wrong Captcha !!!</span>}</div>

        </div>
    )
}

export default Captcha