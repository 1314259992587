import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../service/auth.service";
import UserService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

export default function BalanceSectionThai({
  getWalletInfo,
  walletInfo,
  StopProduct,
  bank,
}) {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [startedGames, setStartedGames] = useState([]);
  // const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [turnOver, setTurnOver] = useState(null)
  const configData = useContext(ConfigContext)
  const [progressCash, setProgressCash] = useState(null)
  const [minWithdrawal, setMinWithdrawal] = useState(0)

  const getNotificationInfo = async () => {
    // const getNotification = await UserService.getNotificationCount();
    // setCount(getNotification.data.count);
  };

  useEffect(() => {
    const fetchWalletInfo = async () => {
      const user = AuthService.getCurrentUser();
      if (user) {
        await getWalletInfo();
        setCurrentUser(user);
        getNotificationInfo();
      }
    };
    fetchWalletInfo();
  }, [localStorage.getItem("user")]);
  const getStartedGames = async (userId) => {
    try {
      const startedProducts = await UserService.getStartedGames(userId);
      if (startedProducts.data) {
        setStartedGames(startedProducts.data);
      }
    } catch (error) {
      setStartedGames([]);
    }
  };

  const getTurnover = async () => {
    try {
      const turnover = await UserService.getTurnover();
      if (turnover.data && turnover.data.length) {
        setTurnOver(turnover.data[0])
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (turnOver) {
      const ttype = turnOver.ttype;
      const cash_balance = parseFloat(turnOver.cash_balance).toFixed(2);
      const min_turnover = parseFloat(turnOver.min_turnover).toFixed(2);
      const total_bet = parseFloat(turnOver.total_bet).toFixed(2);
      const min_rollover = parseFloat(turnOver.min_rollover).toFixed(2);
      let progressCashRate = 0
      if(ttype === "TO"){
        progressCashRate = ((cash_balance / min_turnover)*100).toFixed(2);
        setMinWithdrawal(min_turnover);
      }else if (ttype === "RO") {
        progressCashRate = ((total_bet / min_rollover)*100).toFixed(2);
        setMinWithdrawal(min_rollover);
      }
      setProgressCash(progressCashRate);
    }
  }, [turnOver]);


  useEffect(() => {
    if (walletInfo && walletInfo.user && walletInfo.user.id) {
      getStartedGames(walletInfo.user.id);
    }
    getTurnover()
  }, [walletInfo]);

  // const checkBg = (val) => {
  //   if (val && (val[0] === "#" || val.includes("rgb") || val.includes("hsl"))) {
  //     return val
  //   }
  //   return `url(${val})`

  //   // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
  //   //   return `url(${val})`
  //   // }
  //   // return val;
  // }
  const checkBg = (configData, type) => {
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return {backgroundColor: configData[configData[type]]}
    }
    return {backgroundImage: `url(${configData[configData[type]]})`}
  }

  return (
    <div style={{borderRadius:"0px 1rem 1rem 0px", ...checkBg(configData, "wallet_container_bg_type") }} className="balance-container w-full d-flex flex-column">
      <div className="balance-context w-full">
        <div className="right-column h-full d-flex w-full">
          <div className="balance_btns d-flex w-full" style={{marginLeft: "auto"}}>
            <div className="balance-img_container cursor-pointer" role="button">
              <img
                style={{width: "5rem"}}
                src={configData ? configData.wallet_deposit : ""}
                alt="Deposit"
                onClick={(e) => {
                  navigate("/deposit");
                }}
              />
            </div>
            <div className="balance-img_container cursor-pointer" role="button">
              <img
                style={{width: "5rem"}}
                src={configData ? configData.wallet_withdraw : ""}
                alt="Withdraw"
                onClick={(e) => {
                  navigate("/withdraw");
                }}
              />
            </div>
            <div className="balance-img_container cursor-pointer" role="button">
              <img
                style={{width: "5rem"}}
                src={configData ? configData.wallet_refresh : ""}
                alt="Refresh"
                onClick={async (e) => {
                  setLoading(true)
                  startedGames.forEach(startedGame => {
                    StopProduct(startedGame.id)
                  })
                  setTimeout(() => {
                    setLoading(false)
                  }, 500);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  );
}
