import "./component.css";
import React, { useEffect, useState } from "react";
import userService from "../service/user.service";
import PromoItem from "./listsItems/PromoItem";
import { useTranslation } from "react-i18next";

const PromoHorizontalList = () => {
  const [promos, setPromos] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  const getPromos = async () => {
    setLoading(true);
    const games = await userService.getPromotions();
    if (games.data) {
      setPromos(games.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPromos();
  }, []);

  return (
    <div className="promo-horizontal-scroll-list">
      {promos.length > 0 && (
        <>
          <h5 className="promo-title">{t("Promo_Rewards")}</h5>
          <div className="scrollable-container">
            <div className="scrollable-content">
              {promos.length > 0 &&
                promos.map((item) => <PromoItem key={item.id} item={item} />)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PromoHorizontalList;
