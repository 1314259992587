import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import MenuNotification from '../components/MenuNotification';
import MenuSidebar from '../components/MenuSidebar';
import LiveChat from '../components/LiveChat';
import userService from '../service/user.service';

const CONTACTUS_OPTION = process.env.REACT_APP_CONTACTUS_OPTION
const Contactus = () => {
  const [socials, setSocials] = useState([])
    const handleClick = (link) => {
      if (link) {
        window.open(link, '_blank');
      }
    };

    useEffect(() => {
      if (CONTACTUS_OPTION === "external") {
        socialContact()
      } else if (CONTACTUS_OPTION === "supportboard") {
        const livechatEle = document.querySelector("#live-chat")
        livechatEle.style.display="block";
        return () => {
          livechatEle.style.display="none";
        }
      }
    },[])

    const socialContact = async () =>{
      try {
        const data = await userService.getSocials()
        if (data && data.data && data.data.length) {
          setSocials(data.data)
        }
      } catch (error) {
        setSocials([])
      }
    }
  return (
    <div className="t1">
      <div className="page">
        {
          CONTACTUS_OPTION === "external"?
          <div className="page-content">
            <div className="contact_us_container">
              {/* <LiveChat/> */}
              {
                socials && socials.length?socials.map(social=>(
                  <a key={social.id} onClick={()=> handleClick(social.action_link)} target="_blank" href='/#'>
                    <img src={social.image} alt={social.provider} className="contact_image"/>
                  </a>
                )):null
              }
            </div>
          </div>
          :null
        }
      </div>
      <Footer />
      {/* <MenuNotification /> */}
      <MenuSidebar />
    </div>
  );
};

export default Contactus;
