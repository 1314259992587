import React, { useState, useEffect } from "react";
import { Modal } from "antd"
import userService from "../service/user.service";

const amountlist = [
    { id: 1, value: '50' },
    { id: 2, value: '100' },
    { id: 3, value: '200' },
    { id: 4, value: '300' },
    { id: 5, value: '500' },
];

const ReloadCard = ({ tyid, typelist, getCouponTypes }) => {
    const initialValues = {
        ttype: "KD",
        amount: '',
        coupon: "",
        coupon_type: `${tyid}`,
    };

    const [values, setValues] = useState(initialValues);

    const [validations, setValidations] = useState({
        amount: "",
        coupon: "",
        non_field_errors: ""
    })

    const {
        amount: amountVal,
        coupon: couponVal,
        non_field_errors: nonVal,
    } = validations

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    const handleDeposit = async e => {
        e.preventDefault();

        let response;
        try {
            response = await userService.createDeposit(values);
            Modal.success({
                content: <p>Deposit is successful. <br />
                    Transaction ID: {response.data.id}</p>,
            });
            setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let coupon_msg;
            let non_msg;

            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.coupon) {
                coupon_msg = err.response.data.coupon;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations, amount: amount_msg, coupon: coupon_msg, non_field_errors: non_msg
            });
        }
    }

    useEffect(() => {
        getCouponTypes();
    }, [])

    return (
        <div className="reload-wrap">
            <h2 className="my-2">{typelist && typelist.length > 0 && typelist.find((ty) => ty.id === tyid).name}</h2>
            <div className="form-custom form-label">
                <input
                    name="amount"
                    type="number"
                    className="form-control rounded-xs"
                    id="c1"
                    placeholder="Amount"
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
            </div>

            <div className="d-flex text-center justify-content-between tab-scroll-wrap">
                {amountlist.map((item) => (
                    <div className="w-fit pr-10 zIndex0" key={item.id}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value ? 'border' : 'bg-highlight'}`}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-3 form-custom form-label">
                <input
                    name="coupon"
                    type="tel"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder="Number"
                    onChange={handleNumChange}
                    value={values.coupon}
                />
                {/* <div className="invalid-feedback">Phone Number is missing or is invalid.</div> */}
                <div className={`${couponVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{couponVal}</div>
                <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>
            </div>


            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
                onClick={handleDeposit}
            >
                Deposit
            </button>
        </div>
    );
};

export default ReloadCard;
