import React, { useState, useEffect, useContext } from "react";
import MenuSidebar from "../components/MenuSidebar";
import AuthService from "../service/auth.service";
// import backgroundImg from "../../src/images/ECI88_Header.jpg";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../ConfigContext";
import { Button, Popover, Typography } from "@mui/material";
import { LanguageContext } from "../i18n/LanguageContext";
import { countries, languages } from "../i18n/languages";
import TransitionsModal from "./common/TransitionsModal";

const countryObj = {
  "my": require("../images/my-flag.png"),
  "sg": require("../images/sg-flag.png")
}
function Header({ walletInfo, setAuth }) {
  const [currentUser, setCurrentUser] = useState(null);
  const configData = useContext(ConfigContext)
  const { language, setLanguage } = useContext(LanguageContext)
  const [languageModal, setLanguageModal] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, [localStorage.getItem("user")]);

  const navigate = useNavigate();

  const checkBg = (configData) => {
    if (!configData) {
      return {}
    }
    if (configData[configData.header_bg_type][0] === "#" || configData[configData.header_bg_type].includes("rgb") || configData[configData.header_bg_type].includes("hsl")) {
      return {backgroundColor: configData[configData.header_bg_type]}
    }
    return {backgroundImage: `url(${configData[configData.header_bg_type]})`}
  }
  const handleClick = () => {
    setLanguageModal(true);
  };

  
  return (
    <div  style={{ zIndex: "111" }}>
      <div
        className="page-title d-flex justify-content-end my-0"
        style={{
          // backgroundImage: `url(${backgroundImg})`,
          // background: configData ? checkBg(configData[configData.header_bg_type]) : "",
          ...checkBg(configData),
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="align-self-center me-auto t3 custom-icon w-full" style={{height: configData && (configData.language_toggle_position == "sidebar")?"45px":"auto"}}>
        {configData && configData.enable_sidebar &&
          <a
            href=".#"
            data-bs-toggle="offcanvas"
            data-bs-target="#menu-sidebar"
            data-menu="menu-main"
            style={{order: configData && (configData.sidebar_toggle_position == "right")? 3:0}}
          >
            <i className="fa-solid fa-bars" style={{ color: configData ? configData.text_color_secondary : "" }}></i>
          </a>
        }
          <div style={{ order: 1, flex:1, position:"relative"}} className="d-flex align-items-center" >
            <img
              onClick={() => navigate("../../")}
              src={configData ? configData.logo : ""}
              alt=""
              className="header-image"
              style={{ height: "50px", margin:"0 auto" }}
            />
        { 
         configData && configData.sub_line ?
        (<div className="header_subline" style={{ order: 2, position:"absolute", right:"0"}}>
          <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }}
           onClick={(e) => {
            e.preventDefault();
            navigate("/subline")
        }}
           >
            <img src={configData ? configData.subline_icon : ""} alt="Referral" />
            <span style={{lineHeight:"13px", fontWeight:"bold"}}>EXPRESS</span>
          </div>
        </div>)
        : null
      }       
          </div>
          {
            configData && (configData.language_toggle_position !== "sidebar") &&
            <div className="currency_icon d-flex align-items-center" style={{order: configData && (configData.sidebar_toggle_position == "right")? 0:3}}>
              <div role="button">
                <img onClick={handleClick} src={language && language.img ? language.img : countryObj["my"]} alt="" />
              </div>
            </div>
          }
        </div>
      </div>
      <div className="t1">
        <MenuSidebar
          currentUser={currentUser}
          walletInfo={walletInfo}
          setCurrentUser={setCurrentUser}
          setAuth={setAuth}
        />
      </div>
      <TransitionsModal
        title={"Select Language"}
        className="antd-promotion-modal"
        footer={[]}
        open={languageModal}
        onClose={() => { setLanguageModal(false)}}
        style={{ textAlign: "center", height: "100%" }}
        containerStyle={{ height: "100%" }}
      >
        <div>
          {
            countries.map(country => {
              const all_languages = configData && configData.available_languages?country.supported_languages.filter(supported_language => configData.available_languages.split(",").includes(supported_language.lang)):country.supported_languages;
              return (
              <Typography key={country} className={`${all_languages.length?"d-flex":"d-none"} align-items-center mb-2`} onClick={() => {
                // setLanguage({
                //   lang: language.key,
                //   img: language.img
                // })
              }} sx={{ py: 1, px: 1, fontSize: 20, borderBottom: "1px solid #ccc" }}>
                <img style={{ width: "30px", marginRight: "10px" }} src={country.flag} alt="" />

                {
                  all_languages.map((supported_language, index) => {
                    return <div key={supported_language.lang}>
                            <div role="button" onClick={() => {
                              setLanguage({
                                lang: supported_language.lang,
                                img: country.flag,
                                country: country.country
                              })
                              setLanguageModal(false)
                            }} 
                            style={{ 
                              color: language && (language.country == country.country) && (language.lang === supported_language.lang) ? 
                                    (configData ? configData.text_color_secondary : "") : 
                                    configData ? configData.text_color : "" }}>{supported_language.label}
                                    {index<(all_languages.length-1) ? " | ": ""}
                            </div>
                          </div>
                  })
                }
              </Typography>
            )})
          }
        </div>
      </TransitionsModal>
    </div>
  );
}

export default Header;
