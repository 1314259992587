import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Icon } from "@iconify/react";
import formatDate from "../utils/formatDate";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

export default function TxTable({ orders, handleDelete, type }) {
  const configData = React.useContext(ConfigContext)
  const {t} = useTranslation();

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "transparent",
        color: configData?configData.text_color :"",
        border: `1px solid ${configData?configData.border_color  :""}`,
      }}
    >
      <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{background: configData?configData.primary_color:"", fontWeight:"bold"}}>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0", color: configData?configData.text_color :"" }}
              className=""
              align="center"
            >
              {t("S_No")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0", color: configData?configData.text_color :"" }}
              className=""
              align="center"
            >
              {t("Type&NO")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0", color: configData?configData.text_color :"" }}
              className=""
              align="center"
            >
              {t("Amount")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0", color: configData?configData.text_color :"" }}
              className=""
              align="center"
            >
              {t("Time")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0", color: configData?configData.text_color :"" }}
              className=""
              align="center"
            >
              {t("Status")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => {
            const dateTime = formatDate(row.created_at);
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{ fontSize: "12px", padding: "13px 0", borderColor: configData?configData.border_color :"", color: configData?configData.text_color :"" }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{ fontSize: "12px", padding: "13px 0", borderColor: configData?configData.border_color :"", color: configData?configData.text_color :"" }}
                  align="center"
                  component="th"
                  scope="row"
                >{row.ttype_display} <br />
                  {type === "chip" ? row.cid : row.txid}
                </TableCell>
                <TableCell
                  sx={{ fontSize: "12px", padding: "13px 0", borderColor: configData?configData.border_color :"", color: configData?configData.text_color :""  }}
                  align="right"
                >
                 <span className="d-flex justify-content-between"><span style={{fontSize:"11px", color:"gray", textAlign:"left"}}>{t("Requested")} :</span> {row.req_amount}</span>
                  <span className="d-flex justify-content-between"><span style={{fontSize:"11px", color:"gray", textAlign:"left"}}>{t("Actual")} :</span> {row.amount}</span>
                  {row.forfeit > 0 ?<span className="d-flex justify-content-between"><span style={{fontSize:"11px", color:"gray", textAlign:"left"}}>{t("Forfeited")} :</span> {row.forfeit}</span> : null}
                </TableCell>
                {/* <TableCell sx={{ fontSize: "12px", padding: "13px 0"}} align='center'>{row.amount}</TableCell> */}
                <TableCell
                  sx={{ fontSize: "12px", padding: "13px 0", borderColor: configData?configData.border_color :"", color: configData?configData.text_color :"" }}
                  align="center"
                >
                  {dateTime.formattedDate}
                  <br></br>
                  {dateTime.time}
                </TableCell>
                <TableCell
                  sx={{ fontSize: "12px", padding: "13px 0", borderColor: configData?configData.border_color :"", color: configData?configData.text_color :""}}
                  align="center"
                >
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span
                      className={`${
                        row.state === "approved"
                          ? "status-approve"
                          : row.state === "error"
                          ? "status-error"
                          : row.state === "rejected"
                          ? "status-reject"
                          : row.state === "deleted"
                          ? "status-delete"
                          : "status-pending"
                      } status-p ${configData?configData.text_color :""}`}
                    >
                      {/* {String(row.state).toUpperCase()} */}
                      {t(String(row.state)[0].toUpperCase() + String(row.state).slice(1))}
                    </span>
                    {row.state === "pending" && ["BT", "BM", "BC"].indexOf(row.ttype) === -1 ? (
                      <span
                        className="pending-delete-btn"
                        onClick={() => handleDelete(row.id, type)}
                      >
                        <Icon icon="ic:outline-delete" fontSize={18} />
                      </span>
                    ) : null}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
