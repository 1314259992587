import React from 'react'
import { useContext } from 'react'
import { ConfigContext } from '../../../ConfigContext'

function FooterContent() {
    const configData = useContext(ConfigContext)
    return (
        <div style={{padding: "10px 20px 100px"}}
            dangerouslySetInnerHTML={{
                __html: configData.footer_content,
            }}
        ></div>
    )
}

export default FooterContent