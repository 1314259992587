import React, { useEffect, useState } from "react";
import "./listItems.css";
import mergeDateTime from "../../utils/mergeDateTime";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PromoItem = ({ item }) => {

    const navigate = useNavigate();
    const {t} = useTranslation()

  const [day, setDay] = useState("2023-10-24");
  const [time, setTime] = useState("09:39:00");
  const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  
  const startDay = item.start_date
  const startTime = item.start_time
  const endDay = item.end_date
  const endTime = item.end_time

//   useEffect(() => {
//     const _date = mergeDateTime(day, time);
//      // Calculate the time remaining and update the countdown every second
//      const intervalId = setInterval(() => {
//         const now = new Date().getTime();
//         const distance = new Date(_date).getTime() - now;
  
//         if (distance <= 0) {
//           // The countdown has expired
//           clearInterval(intervalId);
//           setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
//         } else {
//           // Calculate days, hours, minutes, and seconds remaining
//           const days = Math.floor(distance / (1000 * 60 * 60 * 24));
//           const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//           const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//           const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
//           setCountdown({ days, hours, minutes, seconds });
//         }
//       }, 1000);
  
//       return () => {
//         // Clean up the interval when the component unmounts
//         clearInterval(intervalId);
//       };
//   }, [day, time]);

useEffect(() => {
    // Check if any of the fields is null
    if (!startDay || !startTime || !endDay || !endTime) {
      // Any of the fields is null, set countdown to 0:0:0:0
      setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      // Calculate the start date and time
      const startDate = new Date(mergeDateTime(startDay, startTime)).getTime();

      // Calculate the end date and time
      const endDate = new Date(mergeDateTime(endDay, endTime)).getTime();

      // Calculate the current date and time
      const currentDate = new Date().getTime();

      // Check if the current date is greater than or equal to the end date
      if (currentDate >= endDate) {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        // Calculate the time remaining and update the countdown every second
        const intervalId = setInterval(() => {
          const now = new Date().getTime();
          const distance = endDate - startDate - (now - currentDate);

          if (distance <= 0) {
            // The countdown has expired
            clearInterval(intervalId);
            setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          } else {
            // Calculate days, hours, minutes, and seconds remaining
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            setCountdown({ days, hours, minutes, seconds });
          }
        }, 1000);

        return () => {
          // Clean up the interval when the component unmounts
          clearInterval(intervalId);
        };
      }
    }
  }, [startDay, startTime, endDay, endTime]);

  return (
    <div className="promo-item">
      <img src={item.image} alt="" />
      <div className="promo-content">
        <p style={{color: "white"}}>{item.title}</p>
        <div className="promo-context">
          <div className="date-counter-box">
            <div>
              <p className="date" style={{color: "white"}}>{countdown.days}</p>
              <p className="date" style={{color: "white"}}>{t("Day")}</p>
            </div>
            <div>
              <p className="date" style={{color: "white"}}>{countdown.hours}</p>
              <p className="date" style={{color: "white"}}>{t("Hour")}</p>
            </div>
            <p className="date" style={{color: "white"}}>:</p>
            <div>
              <p className="date" style={{color: "white"}}>{countdown.minutes}</p>
              <p className="date" style={{color: "white"}}>{t("Min")}</p>
            </div>
            <p className="date" style={{color: "white"}}>:</p>
            <div>
              <p className="date" style={{color: "white"}}>{countdown.seconds}</p>
              <p className="date" style={{color: "white"}}>{t("Sec")}</p>
            </div>
          </div>
          <Button onClick={() => navigate('/promotions', {state: item.id})} type="primary" style={{fontWeight: "bold"}}>{t("More_Info")}</Button>
        </div>
      </div>
    </div>
  );
};

export default PromoItem;
