import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BalanceSection from "../components/BalanceSection";
import { ConfigContext } from "../ConfigContext";
import userService from "../service/user.service";

const BalanceWrapper = ({
  currentUser,
  getWalletInfo,
  walletInfo,
  StopProduct,
}) => {
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  {/* Site Name for demo purpose, will have to remove after demo */}
  const SITE_NAME = process.env.REACT_APP_SITE_NAME;
  const [bank, setBank] = useState(null);

  const fetchBanks = async () => {
    try {
      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        setBank({
          ...bank,
          metaData: bankData.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  return (
    <div className="balance_wrapper">
      {/* Site Name for demo purpose, will have to remove after demo */}
      {(!currentUser && SITE_NAME !== "NNNN8888") && (
        <div>
          <div className="login-container w-full">
            <div className="left-column">
              <div className="login-btn" onClick={() => navigate("/signin")}>
                <img
                  src={configData?configData.home_login_btn :""}
                  className="login-image"
                  alt=""
                />
              </div>
            </div>
            <div className="right-column">
              <div className="register-btn" onClick={() => navigate("/signup")}>
                <img
                  src={configData?configData.home_register_btn :""}
                  className="register-image"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {currentUser && (
        <div className="pbalance">
          <BalanceSection
            getWalletInfo={getWalletInfo}
            walletInfo={walletInfo}
            StopProduct={StopProduct}
            bank={bank}
          />
        </div>
      )}
    </div>
  );
};

export default BalanceWrapper;
