import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import authService from "../../service/auth.service";
import tokenService from "../../service/token.service";
import { useContext } from "react";
import { ConfigContext } from '../../ConfigContext';
import { useTranslation } from "react-i18next";


const initialValues = {
  username: "",
  password: "",
  password2: "",
  cc: "60",
  mobile: "",
};

function ClassicSignUp({ setUserId }) {
  const navigate = useNavigate();
  const location = useLocation();
  // const [state, setState] = useContext(AppContext)
  const [values, setValues] = useState(initialValues);
  const [readOnly, setReadOnly] = useState(false);
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();


  const [validations, setValidations] = useState({
    username: "",
    password: "",
    password2: "",
    cc: "",
    mobile: "",
    non_field_errors: "",
    referrer_code: "",
  });

  const handleChange = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const {
    username: nameVal,
    password: passVal,
    password2: pass2Val,
    cc: ccVal,
    mobile: mobileVal,
    // terms: termsVal,
    non_field_errors: nonVal,
    referrer_code,
  } = validations;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!terms) return;

    let response;
    try {
      response = await authService.register(values);
      if (response && response.data && response.data.id) {
        setUserId(response.data.id);
        tokenService.setRegisterUser({...values, id: response.data.id});
      }
      navigate("/signup2");
    } catch (err) {
      console.log("err => ", err, values);
      let username_msg;
      let cc_message;
      let mobile_message;
      let password_message;
      let password2_message;
      let referrer_code_message;
      let non_field_errors_message;

      if (err.response && err.response.data && err.response.data.username) {
        username_msg = err.response.data.username;
      }
      if (err.response && err.response.data && err.response.data.cc) {
        cc_message = err.response.data.cc;
      }
      if (err.response && err.response.data && err.response.data.mobile) {
        mobile_message = err.response.data.mobile;
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
      }
      if (err.response && err.response.data && err.response.data.password2) {
        password2_message = err.response.data.password2;
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.referrer_code
      ) {
        referrer_code_message = err.response.data.referrer_code;
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.non_field_errors
      ) {
        non_field_errors_message = err.response.data.non_field_errors;
      }
      setValidations({
        ...validations,
        username: username_msg,
        password: password_message,
        password2: password2_message,
        mobile: mobile_message,
        cc: cc_message,
        referrer_code: referrer_code_message,
        non_field_errors: non_field_errors_message,
      });
    }
  };

  // useEffect(() => {
  //     if (!terms) {
  //         const message = 'Please read Terms and Conditions';
  //         setValidations({ ...validations, terms: message });
  //     } else {
  //         const message = '';
  //         setValidations({ ...validations, terms: message });
  //     }
  // }, [terms])

  const getReferralCode = () => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("referrer");
    const localStorageCode = localStorage.getItem("referralCode");
    if (myParam || localStorageCode) {
      setValues((values) => {
        return { ...values, referrer_code: myParam || localStorageCode };
      });
      localStorage.removeItem("referralCode");
      sessionStorage.setItem("from_refer", myParam || localStorageCode);
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };
  
  useEffect(() => {
    if(sessionStorage.getItem("from_refer")){
      const ref_code = sessionStorage.getItem("from_refer");
      localStorage.setItem("referralCode", ref_code);
      sessionStorage.removeItem("from_refer")
      navigate(`/signup/?referrer=${ref_code}&openExternalBrowser=1`)
      
    }
    getReferralCode();
  }, []);
  return (
    <div className="t3">
      <div id="page">
        <div className="page-content pb-0">
          <div
            data-card-height="cover-full"
            className="background_class"
            style={{ height: "680px" }}
          >
            <div className="card-center">
              {/* <div className="text-left" style={{paddingLeft:"15px"}}>
                                <h1 className="font-40 color-white">Sign Up</h1>
                            </div> */}
              <div className="content">
                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                >
                  {t("Username")} :
                </p>
                <div
                  className={`input-style has-borders no-icon validate-field mb-4  ${
                    values.user ? "input-style-active" : ""
                  }`}
                >
                  <input
                    name="username"
                    onChange={handleChange}
                    type="text"
                    className={`form-control validate-name text-white`}
                    id="form1a"
                    placeholder={t("Username")}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${
                      nameVal === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                  >
                    {nameVal}
                  </div>
                </div>
                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                >
                  {t("Mobile_No")} :
                </p>
                <div>
                  {/* <input onChange={e => setValues({ ...data, phone: e.target.value })} type="number" className={`form-control validate-name`} id="form1a" placeholder="Phone number" />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em> */}
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      borderRadius: "0px",
                      height: "40px",
                    }}
                    containerStyle={{ marginTop: "0px", marginBottom: "10px" }}
                    buttonStyle={{ borderRadius: "0px" }}
                    country={configData ? (configData.phone_format) : "my"}
                    onlyCountries={configData ? [(configData.phone_format)] : ["my"]}
                    onChange={(phone, country) => {
                      const reducedPhone = phone.replace(country.dialCode, "");
                      setValues({
                        ...values,
                        cc: country.dialCode,
                        mobile: reducedPhone,
                      });
                    }}
                    className="has-borders text-white"
                  />
                  <div
                    className={`${
                      ccVal === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                  >
                    {ccVal}
                  </div>
                  <div
                    className={`${
                      mobileVal === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                  >
                    {mobileVal}
                  </div>
                </div>

                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                >
                  {t("Password")} :
                </p>
                <div className="input-style has-borders no-icon validate-field mb-4">
                  <input
                    type="password"
                    name="password"
                    className="form-control validate-text text-white"
                    id="form3"
                    placeholder={t("Password")}
                    onChange={handleChange}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${
                      passVal === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                  >
                    {passVal}
                  </div>
                </div>
                {/* <div className={`input-style has-borders no-icon validate-field mb-4  ${data.password ? "input-style-active" : ""}`}>
                                    <i className="ps-1 fa fa-lock"></i>
                                    <input onChange={e => setValues({ ...data, password: e.target.value })} type="password" className={`form-control validate-password`} id="form1a" placeholder="Password" />
                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                    <em>(required)</em>
                                </div> */}
                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                >
                  {t("Confirm")} {t("Password")} :
                </p>

                <div
                  className={`input-style has-borders no-icon validate-field mb-4  ${
                    values.password2 ? "input-style-active" : ""
                  }`}
                >
                  <input
                    type="password"
                    name="password2"
                    className={`form-control validate-password text-white`}
                    id="form1a"
                    placeholder={t("Confirm") + " " + t("Password")}
                    onChange={handleChange}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${
                      pass2Val === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                  >
                    {pass2Val}
                  </div>
                  <div
                    className={`${
                      nonVal === "" ? "valid-feedback" : "invalid-feedback"
                    } `}
                  >
                    {nonVal}
                  </div>
                </div>

                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                >
                  {t("Referral_Code")}
                </p>
                <div
                  className={`input-style has-borders no-icon validate-field mb-4`}
                >
                  <input
                    type="text"
                    name="referrer-code"
                    className={`form-control text-white`}
                    id="form1b"
                    placeholder={t("Referral_Code") + " (" + t("optional") + ")"}
                    onChange={handleChange}
                    value={values.referrer_code}
                    readOnly={readOnly}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${
                      referrer_code === ""
                        ? "valid-feedback"
                        : "invalid-feedback"
                    } `}
                  >
                    {referrer_code}
                  </div>
                </div>

                <div
                  data-back-button
                  className="btn mx-auto w-100 btn-full btn_green register_btn mt-4"
                  style={{backgroundColor : configData?configData.secondary_color:""}}
                  onClick={handleSubmit}
                >
                  {t("REGISTER")}
                </div>

                <p
                  className="input-title text-center my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                >
                  {t("Have_An_Account")}
                </p>
                <p
                  className="input-title text-center my-1 font-14"
                  style={{ color: configData?configData.text_color :""}}
                  onClick={(e) => {
                    e.preventDefault();
                    if(values && values.referrer_code){
                      localStorage.setItem('from_refer', true)
                    }
                    navigate("/signin");
                  }}
                >
                  {t("Login_Here")}
                </p>
              </div>
            </div>
            {/* <div className="t1">
                            <Footer />
                        </div> */}
            <div className="card-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassicSignUp;
