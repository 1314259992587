import { useTranslation } from 'react-i18next';

export const mapTType = (ttype) => {
    const { t } = useTranslation();
    const typeMap = {
      RB: `${t("Single_level")} ${t("Rebate")}`,
      RF: `${t("Single_level")} ${t("Referral")}`,
      MRB: `${t("Multilevel")} ${t("Rebate")}`,
      MRF: `${t("Multilevel")} ${t("Referral")}`,
      // Add more mappings here if needed in the future
    };
  
    return typeMap[ttype] || "#NA";
}

export const mapIsMultilevel = (is_multilevel) => {
    const typeMap = {
      0: `Single_level`,
      1: `Multilevel`,
      // Add more mappings here if needed in the future
    };
  
    return typeMap[is_multilevel] || "#NA";
}