import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import tokenService from '../../../service/token.service';
import authService from '../../../service/auth.service';
import { ConfigContext } from '../../../ConfigContext';
import Loading from '../../Loading';

const initialValues = {
    username: "",
    password: "",
};

function SignIn2({ setAuth }) {
    const [values, setValues] = useState(initialValues);
    const configData = useContext(ConfigContext);
    const { t } = useTranslation();

    const [validations, setValidations] = useState({
        username: "",
        password: "",
        refresh: "",
    });
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token_id = searchParams.get("token_id");
    const signupVersion = configData && configData.signup_version ? configData.signup_version : process.env.REACT_APP_SIGNUP_VERSION;

    useEffect(() => {
        if (token_id) {
            tokenService.removeUser();
            handleStealthLogin(token_id);
        }
    }, [token_id]);

    const handleStealthLogin = async (tokenId) => {
        setLoading(true);
        try {
            const { data } = await authService.stealthLogin(tokenId);
            if (data && data.access) {
                setTimeout(() => {
                    tokenService.setUser(data);
                    setLoading(false);
                    navigate("/");
                    setAuth(true);
                }, 500);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const {
        username: nameVal,
        password: passVal,
        refresh: refreshVal,
    } = validations;


    const handleChange = (e) => {
        setValues((values) => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await authService.login(values);
            setTimeout(() => {
                setLoading(false);
            }, 500);
            navigate("/");
                        setAuth(true);
        } catch (err) {
            console.log("err ==> ", err);
            let username_msg;
            let password_message;
            let refresh_msg;
            if (err.response && err.response.data && err.response.data.username) {
                username_msg = err.response.data.username;
            }
            if (err.response && err.response.data && err.response.data.refresh) {
                refresh_msg = err.response.data.refresh;
            }
            if (err.response && err.response.data && err.response.data.detail) {
                refresh_msg = err.response.data.detail;
            }
            if (err.response && err.response.data && err.response.data.password) {
                password_message = err.response.data.password;
            }
            setValidations({
                ...validations,
                username: username_msg,
                password: password_message,
                refresh: refresh_msg,
            });
            setLoading(false);
        }
    };
    return (
        <div className='layout2'>
            <div className="login-panel standard-form-container">
                <div className="container">

                    <div className="form-group mb-2">
                        <label style={{ color: configData ? configData.text_color : "#fff" }}>
                        {t("Mobile_No")} / {t("Username")}
                        </label>
                        <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="text" name="username" className="form-control" placeholder="Username" onChange={handleChange} />
                        {
                            validations &&
                            <span class="standard-required-message">{validations.username}</span>
                        }
                    </div>
                    <div className="form-group mb-2 standard-password-field">
                        <label style={{ color: configData ? configData.text_color : "#fff" }}>
                        {t("Password")}
                        </label>
                        <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="password" name="password" className="form-control" placeholder={t("Password")} id="password_input" onChange={handleChange} />
                        <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                        {
                            validations &&
                            <span class="standard-required-message">{validations.password}</span>
                        }
                    </div>

                    <div className="standard-button-group">
                        <input style={{ backgroundColor: configData ? configData.secondary_color : "#fff" }} type="button" className="standard-button" value={t("LOGIN")} onClick={handleSubmit} />
                    </div>
                    <div className="standard-button-group">
                        <input style={{ backgroundColor: configData ? configData.secondary_color : "#fff" }}  value={t("REGISTER")} type="button" className="standard-button" />
                            <a href="#" className="forgot-password-link" onClick={(e) => {
                                e.preventDefault();
                                signupVersion && signupVersion === "V3" ? (
                                    navigate('/contactus')
                                ) : navigate("/forget-password");
                            }}>
                                {t("Forget_Password")}
                            </a>

                    </div>
                    <div className="standard-button-group register-field">
                    {t("Do_Not_Have")}
                        <a href="#" className="register-button" onClick={(e) => {
                            e.preventDefault();
                            if (localStorage.getItem("from_refer")) {
                                localStorage.removeItem("from_refer");
                                navigate(-1);
                            } else {
                                navigate("/signup");
                            }
                        }}>
                            {t("REGISTER")}
                        </a>
                    </div>
                </div>
                {isLoading && <Loading />}
            </div>
        </div>
    )
}

export default SignIn2