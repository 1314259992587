import api, { OpenAxios } from "./api";
import TokenService from "./token.service";

class AuthService {
    login(values) {
        return api
            .post("/token/", values)
            .then((response) => {
                if (response.data && response.data.access) {
                    TokenService.setUser(response.data);
                    return response.data;
                }

            });
    }

    logout() {
        return api
        .post("/token/logout/")
        .then((response) => {
            TokenService.removeUser();
            if (response.data) {
                return response.data;
            }

        });
    }

    register(values) {
        return api.post("/signup/", values);
    }

    getCurrentUser() {
        return TokenService.getUser();
    }

    getTac(userId) {
        return api.post(`/signup/${userId}/get-tac/`);
    }

    getLegacyTac(values) {
        return api.post(`/new-signup/get-tac/`, values);
    }
    signupWithBank(values) {
        return api.post(`/signup/with_bank_account/`, values);
    }

    getForgetTac(values) {
        return api.post(`/reset-password/get-tac/`, values);
    }

    getForgetTacV2(username) {
        return api.post(`/reset-password/v2/get-tac/`, {username});
    }

    verifyTac({ userId, code }) {
        return api.post(`/signup/${userId}/verify-tac/`, { "tac": code });
    }

    verifyLegacyTac({userId, code}) {
        return api.post(`/new-signup/${userId}/verify-tac/`, { "tac": code });
    }

    verifyTacV2({username,code}) {
        return api.post(`/reset-password/v2/verify-tac/`, { username,"tac": code });
    }

    activate({ userId, values }) {
        return api.post(`/signup/${userId}/activate/`, values);
    }

    activateLegacy({ userId, values }) {
        return api.post(`/new-signup/${userId}/activate/`, values);
    }

    getBanklist() {
        return OpenAxios.get(`/banks/`);
    }

    changePassword({ values, userId }) {
        return api.put(`/change-password/${userId}/`, values)
    }

    resetPassword(values) {
        return api.post(`/reset-password/reset/`, values);
    }

    resetPasswordV2(values) {
        return api.post(`/reset-password/v2/reset/`, values);
    }

    stealthLogin(tokenId) {
        return api.get(`/stealthtoken/${tokenId}`)
    }

}

export default new AuthService();
