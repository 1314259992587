export default function mergeDateTime(dateString, timeString) {
    // Parse the date string and time string separately
    const dateComponents = dateString.split('-').map(Number);
    const timeComponents = timeString.split(':').map(Number);
  
    if (dateComponents.length !== 3 || timeComponents.length !== 3) {
      throw new Error('Invalid date or time format');
    }
  
    const year = dateComponents[0];
    const month = dateComponents[1] - 1; // Months are zero-based (0-11)
    const day = dateComponents[2];
    const hour = timeComponents[0];
    const minute = timeComponents[1];
    const second = timeComponents[2];
  
    // Create a new Date object with the combined components
    const mergedDate = new Date(year, month, day, hour, minute, second);
  
    return mergedDate;
  }