// import imagePath from "./imagePath";

export const configurableData = {
    "manual_deposit_style": "2",
    "luckywheel_style": "",
    "luckywheel_information_images": require("../images/wheel/information_luckywheel.jpg"),
    "reward_total_rebates": 6,
    "reward_close_box": require("../images/close_box.png"),
    "reward_open_box": require("../images/open_box.webp"),
    "reward_rebates_bg": require("../images/rebates_bg.webp"),
    "available_rewards": "",
    "level_reward": require("../images/transparent.jpg"),
    "checkin_reward": require("../images/transparent.jpg"),
    "spin_reward": require("../images/transparent.jpg"),
    "redeem_reward": require("../images/transparent.jpg"),
    "message_reward": require("../images/transparent.jpg"),
    
    "available_paymentgateway_providers": "",
    "available_ewallets": "",
    "live_table_style": "2",
    "top_x_amount": 1,
    "top_y_amount": 1,
    "top_z_amount": 1,
    "change_game_id": false,
    "default_lang": "en",
    "available_languages": "",
    "footer_content_enabled": false,
    "footer_content": ``,
    "language_icon": require("../images/language.png"),
    "language_toggle_position": "header",
    "enable_sidebar": true,
    "sidebar_toggle_position": "",
    "sidebar_position": "",
    "sidebar_menu_item_bg": "",
    "layout_card_bg": "",
    "tertiary_color": "",
    "inputs_bg": "",
    "promo_style": "",
    "deposit_style": "",
    "withdraw_style": "",
    "history_tabs_style": "",
    "profile_style": "",
    "change_password_style": "",
    "bank_accounts_style": "",
    "login_style": "",
    "footer_bg_active_color": "",
    "signup_version": "",
    "general_buttons_rows": "2",
    "member_auth_style": "",
    "login_image": require("../images/transparent.jpg"),
    "forget_password_image":  require("../images/transparent.jpg"),
    
    "wallet_deposit": require("../images/ECI88_Deposit.png"),
    "wallet_withdraw": require("../images/ECI88_Withdraw.png"),
    "wallet_profile": "",
    "wallet_refresh": require("../images/ECI88_Refresh.png"),

    "wallet_container_bg": "",
    "wallet_container_bg_type": "wallet_container_bg_color",
    "wallet_container_bg_color": "transparent",
    
    "wallet_welcome_bg": "" ,
    "wallet_welcome_bg_type": "wallet_welcome_bg_color",
    "wallet_welcome_bg_color": "rgba(0,0,0,.7)",

    "live_deposit_bg":  "red",
    "live_withdraw_bg":  "green",
    "live_transaction_bg":  "rgb(0, 0, 0)",
    "deposit_withdraw_title_bg": "#000",

    "payment_banner": "",
    "banner": require("../images/ECI88_Comssion.png"),

    "whatsapp": require("../images/whatapps.png"),
    "telegram": require("../images/telegram.png"),
    "qrcode": require("../images/qr-code.png"),
    "more_info_modal": require("../images/transparent.jpg"),

    "general_downline": require("../images/ECI88_Downline.png"),
    "general_share": require("../images/ECI88_Share.png"),
    "general_more_info": require("../images/ECI88_Info.png"),
    "general_copy_link": require("../images/ECI88_Copy.png"),

    "vip_ranking": require("../images/vip-ranking.png"),
    "loading_image": require("../images/loading-image.gif"),
    // Tabs
    "category_tab_1_icon": require("../images/ECI88_Casino.png"),
    "category_tab_2_icon": require("../images/ECI88_Sportbook.png"),
    "category_tab_3_icon": require("../images/ECI88_Slot.png"),
    "category_tab_4_icon": require("../images/ECI88_eztech.png"),
    "category_tab_5_icon": require("../images/ECI88_Casino.png"),
    "category_tab_6_icon": require("../images/ECI88_Casino.png"),
    "category_tab_7_icon": require("../images/ECI88_Casino.png"),
    "category_tab_8_icon": require("../images/ECI88_Fishing.png"),
    "category_tab_9_icon": require("../images/ECI88_Casino.png"),
    "category_tab_10_icon": require("../images/ECI88_Casino.png"),

    "default_product_load": "slots",

    "category_tab_1_type": "casino",
    "category_tab_2_type": "sportsbook",
    "category_tab_3_type": "slots",
    "category_tab_4_type": "easytech",
    "category_tab_5_type": "card",
    "category_tab_6_type": "cockfight",
    "category_tab_7_type": "esports",
    "category_tab_8_type": "fishing",
    "category_tab_9_type": "lotto",
    "category_tab_10_type": "poker",

    "category_tab_1_active": false,
    "category_tab_2_active": false,
    "category_tab_3_active": false,
    "category_tab_4_active": false,
    "category_tab_5_active": false,
    "category_tab_6_active": false,
    "category_tab_7_active": false,
    "category_tab_8_active": false,
    "category_tab_9_active": false,
    "category_tab_10_active": false,

    "category_image_height": 60,
    "category_image_width": 60,
    "category_tab_height": 60,
    "category_tab_width": 60,  
    "checkin_appearance": 'home',

    // Footer

    "menu_promo_text": "PROMO",
    "menu_promo_text_type": "menu_promo_text_color",
    "menu_promo_text_color": "PROMO",

    "menu_home_text":require("../images/transparent.jpg"),
    "menu_home_text_type": "menu_home_text_color",
    "menu_home_text_color": "HOME" ,

    "menu_deposit_text": "Deposit",
    "menu_deposit_text_type": "menu_deposit_text_color",
    "menu_deposit_text_color": "Deposit",

    "menu_history_text": "HISTORY",
    "menu_history_text_type": "menu_history_text_color",
    "menu_history_text_color": "History",

    "menu_contact_us_text": "CONTACT US",
    "menu_contact_us_text_type": "menu_contact_us_text_color",
    "menu_contact_us_text_color": "Contact_Us",


    "menu_home": require("../images/ECI88_Home.png"),
    "menu_promo": require("../images/ECI88_Promo.png"),
    "menu_deposit": require("../images/ECI88_HDeposit.png"),
    "menu_history": require("../images/ECI88_History.png"),
    "menu_contract_us": require("../images/ECI88_ContactUs.png"),

    "footer_bg": require("../images/ECI88_Header.jpg"),
    "footer_bg_type": "footer_bg",
    "footer_bg_color": require("../images/ECI88_Header.jpg"),
    "marquee_bg": require("../images/ECI88_Newsfeed.jpg"),
    "marquee_height": "",
    "marquee_text_color": "white",
    "root_bg": require("../images/ECI88_Background.png"),
    "root_bg_type": "root_bg",
    "root_bg_color": require("../images/ECI88_Background.png"),
    "header_bg": require("../images/ECI88_Header.jpg"),
    "header_bg_color": "red",
    "header_bg_type": "header_bg",
    "subline_icon": require("../images/whatapps.png"),


    
    "withdraw_chip_bg": "rgb(150, 112, 49)",
    "menusidebar_bg": "#1b1b1b",
    "menusidebar_bg_type": "menusidebar_bg_color",
    "menusidebar_bg_color": "#1b1b1b",

    "progress_bar": "#FFCA28",

    "home_register_btn": require("../images/ECI88_Register.png"),
    "home_login_btn": require("../images/ECI88_Login.png"),
    "Card_Frame": require("../images/frame.png"),

    "deposit_title": "Choose_a_Payment_Method",
    "withdraw_title": require("../images/withdraw.webp"),

    "deposit_easypay": require("../images/transparent.jpg"),
    "deposit_surepay": require("../images/transparent.jpg"),
    "deposit_sun2pay": require("../images/transparent.jpg"),
    "deposit_telcopay": require("../images/transparent.jpg"),
    "deposit_gspay": require("../images/transparent.jpg"),
    "deposit_manual_deposit": require("../images/transparent.jpg"),
    "deposit_ewallet": require("../images/transparent.jpg"),
    "deposit_doitnow": require("../images/transparent.jpg"),
    "deposit_tng": require("../images/transparent.jpg"),
    "deposit_dgpay": require("../images/transparent.jpg"),
    "deposit_dgpay_ewallet": require("../images/transparent.jpg"),
    "deposit_dana": require("../images/transparent.jpg"),
    "deposit_ovo": require("../images/transparent.jpg"),
    "deposit_shopeepay": require("../images/transparent.jpg"),
    "deposit_qris": require("../images/transparent.jpg"),
    

    "livechat": require("../images/ECI88_BtnLivechat.png"),
    "promo_moredetails": require("../images/promotion-btn.jpg"),
    "promo_joinnow": require("../images/promotion-join-btn.jpg"),
    // BackGround color

    "primary_color": "#ffcb61",
    "secondary_color": "#CD9F15",//"#CD9F15"

    // Border color

    "border_color": "#ffcb61",
    "border_color_secondary": "#E6960C",

    "border_shadow_primary_color": "#e6960c",
    "border_shadow_secondary_color": "rgba(230, 150, 12, 0.8)",

    //Text Color

    "text_color": "#fff",
    "text_color_secondary": "#ffcb61",

    "logo": require("../images/transparent.jpg"),
    "country": "my",
    "livechat_image": require("../images/ECI88_Background.png"),

    "jackpot": false,
    "jackpot_image":  require("../images/jackpot.gif"),
    "jackpot_style": "1",
    "jackpot_number": 100,
    "jackpot_number_min_increment": 1,
    "jackpot_number_max_increment": 10,
    "max_rtp": 100,
    "min_rtp": 0,
    "rtp_1_color": "#ff0000", 
    "rtp_2_color": "#ff4000",
    "rtp_3_color": "#ff8000",
    "rtp_4_color": "#ffbf00",
    "rtp_5_color": "#ffff00",
    "rtp_6_color": "#eeff00",
    "rtp_7_color": "#aaff00",
    "rtp_8_color": "#70ff00",
    "rtp_9_color": "#00ff00",
    "rtp_10_color": "#00ff00",

    // ClickToClaim
    "enable_ctc": "",
    "ctc_bg": require("../images/ctc_bg.gif"),
    "ctc_title_color": "rgb(255,0,251)",
    "ctc_title_font_style": "Roboto, San-Serif",
    "ctc_reward_color": "rgb(255,255,255)",
    "ctc_reward_font_style": "Roboto, San-Serif",

    // Chips
    "enable_chips": "",
}