import React, { useContext } from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { ConfigContext } from '../ConfigContext';

const TopSlider = ({images}) => {
  const options = {
    type         : 'loop',
    gap          : '1rem',
    autoplay     : true,
    pauseOnHover : true,
    resetProgress: false,
    height       : '12rem',
    arrows: false,
    pagination: false, 
    flickPower: 2500
  };
  const configData = useContext(ConfigContext)


  return (
    <div className="splide_wrapper"style={{height:"9.8rem"}}>
      {images.length > 0 && (
      <Splide
        options={{ ...options, start: false }}
        aria-labelledby="autoplay-example-heading"
        hasTrack={ false }
      > 
        <div className="splide_image" style={ { position: 'relative' } }>
          <SplideTrack>
            {images.map((image, idx) => (
              <SplideSlide key={ image.imgSrc + idx }>
                <img
                    src={image.imgSrc}
                    alt={image.alt}
                    className="responsive-image"
                  />
              </SplideSlide>
            ))}
          </SplideTrack>
        </div>

        <div className="splide__progress" >
          <div className="splide__progress__bar" style={{background: configData?configData.progress_bar :""}} />
        </div>
      </Splide>
      )}
    </div>
  );
};


export default TopSlider;