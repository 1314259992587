// ** react imports
import React, { useState, useEffect } from 'react'

// ** npm module imports
import QRCode from 'qrcode'
import { Icon } from '@iconify/react';

// ** api imports
import UserService from '../service/user.service'

// ** components imports
import Loading from "../components/Loading";
import Footer from '../components/Footer';

export default function QRCodeSection() {
    const [referralLink, setReferralLink] = useState('');
    const [QRCodeLink, setQRCodeLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [copyIcon, setCopyIcon] = useState('solar:copy-linear');
    // const [username, setUsername] = useState('');

    const getUserInfo = async () => {
        try {
            const userInfo = await UserService.getBalance();
            const referralLink = userInfo.data.referral_link;
            // setUsername(userInfo.data.username)
            return referralLink
        } catch (err) {
            console.log(err)
        }
    }

    const generateQRCode = async (referral) => {
        try {
            const qrCode = await QRCode.toDataURL(referral)
            setQRCodeLink(qrCode)
        } catch (err) {
            console.error(err)
        }
    }

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link)
        setCopyIcon('akar-icons:double-check')
        setTimeout(() => {
            setCopyIcon('solar:copy-linear')
        }, 1000)
    }

    useEffect(() => {
        const initialSetting = async () => {
            setIsLoading(true)
            const referral = await getUserInfo()
            setReferralLink(referral)
            await generateQRCode(referral)
            setIsLoading(false)
        }
        initialSetting()
    }, [])

    return (
        <div className='px-2'>
            {isLoading && <Loading />}
            {QRCodeLink &&
                <div className='position-relative w-100 justify-center d-flex'>
                    <img src={QRCodeLink} alt='qrcode' className='qrcode-img rounded-15' draggable={false} />
                    {/* <div className='position-absolute qrcode-username'>{username}</div> */}
                </div>
            }
            {referralLink && <div className='w-100 bg-white p-10 rounded-15 mt-30 d-flex items-center justify-between'>
                <div className='light-bg p-10 rounded-15 d-flex items-center justify-between w-100'>
                    <div className='referral-link'>
                        {referralLink}
                    </div>
                    <Icon icon={copyIcon} fontSize={30} className='referralLink-copy' onClick={() => handleCopyLink(referralLink)} />
                </div>
            </div>}
            {/* <div className="t1">
                <Footer />
            </div> */}
        </div>
    )
}
