import React, { useContext, useEffect, useState } from 'react';
import './component.css';
import { ConfigContext } from '../ConfigContext';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import userService from '../service/user.service';
import { useTranslation } from 'react-i18next';

function BetHistory2() {
    const configData = useContext(ConfigContext);
    const navigate = useNavigate();
    const [betHistory, setBetHistory] = useState([]);
    const { t } = useTranslation();


    const getBetHistory = async () => {
        try {
            const wallet = await userService.getBalance();
            const userId = wallet.data.user.id;

            const betHistory = await userService.getBetHistory(userId);
            setBetHistory(betHistory.data)
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        getBetHistory();
    }, [])



    return (
        <div className='t1'>
            <div className='page mt-3 mx-2' >
                <div className="page-content footer-clear">
                    <div id="history2" style={{ color: configData ? configData.text_color : "#fff" }}>
                        <div className="tabs">
                            <div className="tab" onClick={(e) => { e.preventDefault(); navigate("/history") }}>{t("Transactions")}</div>
                            <div className="tab"
                                style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "#546E7A" }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/bet-history2")
                                }}>{t("Bet_History")}</div>
                        </div>
                        <div id="history-bet">
                            <div className="note" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>{t("Only_Show_Records")}</div>
                            <table className='w-100'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%", borderColor: configData ? configData.border_color : "" }}>{t("Bet_History")} & {t("Time")}</td>
                                        <td style={{ width: "26%", borderColor: configData ? configData.border_color : "" }}>{t("Game")}</td>
                                        <td style={{ width: "24%", borderColor: configData ? configData.border_color : "" }}>{t("Bet_Amount")}</td>
                                        <td style={{ width: "24%", borderColor: configData ? configData.border_color : "" }}>{t("Win")} / {t("Loss")}</td>
                                    </tr>

                                    {betHistory && betHistory.length > 0 && betHistory.map(item => {
                                        return (
                                            <tr>
                                                <td style={{ borderColor: configData ? configData.border_color : "" }}>{new Date(item.matchtime).toLocaleString()}</td>
                                                <td style={{ borderColor: configData ? configData.border_color : "" }}>{item && `${item.product_name ? item.product_name : ''} ${item && item.detail && item.detail.GameName ? item.detail.GameName : ''}`}</td>
                                                <td style={{ borderColor: configData ? configData.border_color : "" }}>{item.bet}</td>
                                                <td style={{ borderColor: configData ? configData.border_color : "" }} className={`${Number(item.winlose) < 0 ? 'text-red' : 'text-green'} text-sm`}>
                                                    {(Number(item.winlose) < 0) ? 'Lose' : 'Win'} {item.winlose}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    )
}

export default BetHistory2