import React, { useContext, useState, useEffect } from "react";
import { ConfigContext } from "../ConfigContext";
import userService from "../service/user.service";

function SublineFix() {
  const configData = useContext(ConfigContext);
  const [socials, setSocials] = useState([]);

  const socialContact = async () => {
    try {
      const data = await userService.getSocials();
      if (data && data.data && data.data.length) {
        setSocials(data.data);
      }
    } catch (error) {
      setSocials([]);
    }
  };
  useEffect(() => {
    socialContact();
  }, []);

  return (
    <div style={{ position: "fixed", bottom: "60px", left: "1px", zIndex: "10", opacity: "0.98" }}>
      {socials && socials.length
        ? socials.map((social) => (
          <div key={social.id} style={{ marginBottom: "10px" }}>
            <a
              target={social.action_link ? "_blank" : ""}
              href={social.action_link ? social.action_link : "#"}
            >
              <img
                src={social.image}
                alt={social.provider}
                width="65" height="65"
              />
            </a>
          </div>
        ))
        : null}
    </div>

  );
}

export default SublineFix;
