import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import { toast } from 'react-toastify';
import authService from "../service/auth.service";
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import useToast from '../utils/useToast';

const initialValues = {
  username: "",
  password: "",
  password2: "",
  cc: "60",
  mobile: "",
};

function Forget() {
  const [values, setValues] = useState(initialValues);
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();
  const toast = useToast();


  const handleSendTac = async () => {
    try {
      const result = await authService.getForgetTac(values);

      const mobile = result.data.recipient;
      toast.success(`TAC sent to your mobile +${mobile}`);
      navigate('/verify-tac', { state: values });
    } catch (err) {
      console.log(err);
      const response = err.response;
      if(response) {
        const data = response.data;
        if(data.message) {
          return toast.error(`${data.message}`);
        }if(data.not_found) {
          return toast.error(`${data.not_found[0]}`);
        }else {
          return toast.error(`Failed to send TAC! Please try again`);
        }
      }
    }
  };

  return (
    <div
      className="t1">
        {toast.ToastContainer}
      <div id="page">
        <div className="page-content pb-0">
          <div data-card-height="cover-full" className="background_class d-flex flex-column">
          <div className='d-flex align-items-center justify-content-center'>
              <img src={configData?configData.forget_password_image :""} alt="" style={{width:"100%", height:"auto", maxHeight: "450px"}} />
            </div>
            <div style={{zIndex:"2", marginTop: "20px"}}>
              <div className="content">
                <div>
                  <h1 className="font-23 font-700" style={{ color: configData?configData.text_color_secondary :"" }}>{t("Forget_Password")}?</h1>
                </div>
                {/* <p className="input-title my-3 font-14" style={{ color: configData?configData.text_color :"" }}>{t("Don't_worry_it_happens")}</p> */}
                <div className='mt-3'>
                  <PhoneInput
                    inputStyle={{ width: "100%", borderRadius: '0px', height: "40px" }}
                    containerStyle={{ marginTop: "0px", marginBottom: "10px" }}
                    buttonStyle={{ borderRadius: '0px' }}
                    country={configData ? (configData.phone_format) : "my"}
                    onlyCountries={configData ? [(configData.phone_format)] : "my"}
                    onChange={(phone, country) => {
                      const reducedPhone = phone.replace(country.dialCode, '');
                      setValues({ ...values, cc: country.dialCode, mobile: reducedPhone });
                    }}
                    className="has-borders text-white"
                  />
                </div>
                <div
                  data-back-button
                  className="btn mx-auto w-100 btn-full btn_green register_btn mt-4 text-uppercase"
                  onClick={handleSendTac}
                  style={{backgroundColor : configData?configData.secondary_color:""}}
                >
                  {t("Next")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <MenuNotification />
    </div>
  );
}

export default Forget;
