import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import authService from "../service/auth.service";
import tokenService from "../service/token.service";
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import SignUp3_2 from '../components/layout/SignUp3/SignUp3_2';

const initialValues = {
    bank: 1,
    account_number: "",
    account_name: "",
};

function SignUp3({ userId, setAuth, account_name_read }) {
    const [values, setValues] = useState(initialValues);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false)
    const configData = useContext(ConfigContext)
    const {t} = useTranslation();

    const [validations, setValidations] = useState({
        bank: "",
        account_number: "",
        account_name: "",
    })

    const {
        bank: bankVal,
        account_name: nameVal,
        account_number: phoneVal,
    } = validations

    const navigate = useNavigate();

    const getBanks = async () => {
        const banklist = await authService.getBanklist();
        if (banklist.data) {
            setBanks(banklist.data);
        }
    }
    const handleSave = async e => {
        e.preventDefault();
        setLoading(true)

        let _userId = userId
        if (!userId) {
            const local = localStorage.getItem("registerUser")
            const registeredUser = JSON.parse(local)
            if(!registeredUser.id) {
                navigate("../../signup")
            }
            _userId = registeredUser.id
        };

        try {
            await authService.activate({ userId: _userId, values:{...values, account_name:account_name_read ? account_name_read : values.account_name} });
            const uservalue = tokenService.getRegisterUser();
            await authService.login({ username: uservalue.username, password: uservalue.password });
            navigate('/');
            setAuth(true)
        } catch (err) {
            console.log("err => ", err);
            let bank_msg;
            let account_name_msg;
            let account_number_msg;
            if (err.response && err.response.data && err.response.data.bank) {
                bank_msg = err.response.data.bank;
            }
            if (err.response && err.response.data && err.response.data.account_name) {
                account_name_msg = err.response.data.account_name;
            }
            if (err.response && err.response.data && err.response.data.account_number) {
                account_number_msg = err.response.data.account_number;
            }
            if (err.response && err.response.data && err.response.data.non_field_errors) {
                account_number_msg = err.response.data.non_field_errors;
            }

            setValidations({ ...validations, bank: bank_msg, account_number: account_number_msg, account_name: account_name_msg });
        }
        setLoading(false)
    }

    useEffect(() => {
        getBanks();
    }, [])

    const handleChange = (e) => {
        setValues(values => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleBankChange = (e) => {
        const RE = /[0-9\b]$/;
        if (RE.test(e.currentTarget.value) || e.currentTarget.value === '') {
            setValues({ ...values, account_number: e.currentTarget.value });
        }
    }

    return (
        <>
            {
                (configData && (configData.login_style != "2")) || (configData && !configData.login_style) ?
                    <div className="register-bank-details t3" >
                        <div id="page">
                            <div className="page-content pb-0">
                                <div data-card-height="cover-full" className="card mb-0">
                                    <div className="mt-5">
                                        <div className="text-center mb-3">
                                            <h1 className="font-40" style={{ color: configData?configData.text_color:""}}>{t("Bank_Details")}</h1>
                                        </div>
                                        <div className="text-center mb-1">
                                            <p className="font-15" style={{ color: configData?configData.text_color:"", fontWeight: "bold" }}> 
                                                {t("Bank_Account_Withdrawal")}
                                            </p>
                                        </div>
                                        <div className="px-4">
                                            <div className={`input-style no-borders no-icon mb-4  ${values.bank ? "input-style-active" : ""}`} style={{ backgroundColor: "transparent", color: configData?configData.text_color:""  }}>
                                                <label htmlFor="form1a" style={{ backgroundColor: "transparent",color: configData?configData.text_color:"" }}>{t("Select_Bank")}</label>
                                                <select
                                                    id="form1a-select"
                                                    style={{ backgroundColor: "transparent", borderBottomWidth: "1px", appearance: "none" }}
                                                    className={`form-control validate-name color-dark-light`}
                                                    value={values.bank}
                                                    onChange={handleChange}
                                                    name="bank"
                                                >
                                                    {banks.map((bank, idx) => (
                                                        <option
                                                            value={bank.id}
                                                            key={idx}
                                                            style={{ color: "black" }}
                                                        >
                                                            {bank.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span><i className="fa fa-chevron-down"></i></span>
                                                <i className="fa fa-check disabled valid color-green-dark"></i>
                                                <i className="fa fa-check disabled invalid color-red-dark"></i>
                                                <em style={{ backgroundColor: "transparent", color: "#fff" }}></em>
                                                <div className={`${bankVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{bankVal}</div>
                                            </div>
                                            {
                                                !account_name_read ? 
                                                <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${values.account_name ? "input-style-active" : ""}`}>
                                                    <label htmlFor="form1a" style={{color: configData?configData.text_color :""}}>{t("Full_Name")}</label>
                                                    <input
                                                        name="account_name"
                                                        type="text"
                                                        className={`form-control validate-name`}
                                                        id="form1a"
                                                        placeholder="Full Name"
                                                        onChange={handleChange}
                                                    />
                                                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                                                    <i className="fa fa-check disabled valid color-green-dark"></i>
                                                    {/* <em>(required)</em> */}
                                                    <div className={`${nameVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nameVal}</div>
                                                </div>
                                                :
                                                <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${values.account_name ? "input-style-active" : ""}`}>
                                                    <label htmlFor="form1a" style={{color: configData?configData.text_color :""}}>{t("Full_Name")}</label>
                                                    <input
                                                        name="account_name"
                                                        type="text"
                                                        className={`form-control validate-name`}
                                                        id="form1a"
                                                        placeholder="Full Name"
                                                        readOnly={account_name_read ? true : false}
                                                        value={account_name_read}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            }
                                            <div className={`input-style input-transparent no-borders has-icon validate-field mb-4  ${values.account_number ? "input-style-active" : ""}`}>
                                                <label htmlFor="form1a" style={{color: configData?configData.text_color :""}}>{t("Account_Number")}</label>
                                                <input
                                                    name="account_number"
                                                    type="text"
                                                    className={`form-control validate-name`}
                                                    id="form1a"
                                                    placeholder="Acc Number"
                                                    onChange={handleBankChange}
                                                    value={values.account_number}
                                                />
                                                <i className="fa fa-times disabled invalid color-red-dark"></i>
                                                <i className="fa fa-check disabled valid color-green-dark"></i>
                                                {/* <em>(required)</em> */}
                                                <div className={`${phoneVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{phoneVal}</div>
                                            </div>
                                            <div className="px-4 mb-1 alert alert-warning">
                                                <p className="font-15" style={{ fontWeight: "bold" }}> 
                                                ⚠️ {t("Bank_Account_Ensure_Warning")}
                                                </p>
                                            </div>
                                            <Button
                                                loading={loading}
                                                disabled={loading}
                                                style={{width: "100%", height: "50px", background : configData?configData.secondary_color:""}}
                                                data-back-button className="btn btn-full btn-l font-600 font-15 mt-4 rounded-s"
                                                onClick={handleSave}
                                            >
                                                {t("Save_And_Continue")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {/* {
                (configData && (configData.login_style == "2")) ?
                    <div className='layout2'>
                        <div className="login-panel standard-form-container">
                            <div className="container">
                                <SignUp3_2 userId={userId} setAuth={setAuth} />
                            </div>
                        </div>
                    </div>
                    : null
            } */}
        </>
    )
}

export default SignUp3