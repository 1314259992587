import React from 'react'
import { useContext } from 'react'
import { ConfigContext } from '../../../ConfigContext'

function Button4({children, ...props}) {
    const configData = useContext(ConfigContext)
  return (
    <button style={{width: "100%", height: "52px", borderRadius: "12px", textAlign: "center", lineHeight: "52px", backgroundColor: configData
    ? configData.secondary_color
    : "",
  color: configData
    ? configData.text_color
    : "", border: "none", outline: "none", color: "#fff", fontSize: "12px"}} {...props}>{children}</button>
  )
}

export default Button4