import moment from "moment";

export default function formatDate(dateString) {
  // Your date string in ISO 8601 format
  const dateStr = dateString;

  // Parse the date string using Moment.js
  const date = moment(dateStr);

  // Format the date as "HH:mm" (24-hour time format)
  const time = date.format("HH:mm");

  // Format the date as "D MMM, YYYY" (e.g., 6 Sept, 2022)
  const formattedDate = date.format("D/M/YY");

  // Combine the formatted time and date
  const formattedDateTime = {
    time,
    formattedDate
  }
  // const formattedDateTime = `${time} - ${formattedDate}`;

  return formattedDateTime;
}
