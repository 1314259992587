import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import userService from "../../../service/user.service";
import { ConfigContext } from "../../../ConfigContext";

const LiveTable2 = () => {
  const [topDeposits, setTopDeposits] = useState([]);
  const configData = useContext(ConfigContext);
  function ensureArrayLength(arr, desiredLength) {
    const currentLength = arr.length;
    
    if (currentLength < desiredLength) {
      const objectsToAdd = desiredLength - currentLength;
      
      for (let i = 0; i < objectsToAdd; i++) {
        arr.push({ amount: "No Data" });
      }
    }
    
    return arr;
  }
  const getDeposits = async () => {
    const wallet = await userService.getBalance();
    const orderData = await userService.getOrders(wallet.data.user.id);
    if (orderData.data && orderData.data.length) {
        let depositData = orderData.data.filter(dep=>dep.ttype == "IB")
        if (depositData && depositData.length) {
            depositData = depositData.map(deposit=> ({...deposit, amount: Number(deposit.amount)})).sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
            depositData = depositData.filter((deposit, index)=>(index<8))
            let updatedDepositData=[]
            const top_x_amount = !isNaN(configData.top_x_amount)?Number(configData.top_x_amount):0
            const top_y_amount = !isNaN(configData.top_y_amount)?Number(configData.top_y_amount):0
            const top_z_amount = !isNaN(configData.top_z_amount)?Number(configData.top_z_amount):0
            const amount1 = ((depositData[0]?depositData[0].amount:0) + (top_x_amount + top_y_amount + top_z_amount))
            const amount2 = ((depositData[0]?depositData[0].amount:0) + (top_y_amount + top_z_amount))
            const amount3 = ((depositData[0]?depositData[0].amount:0) + top_z_amount)
            updatedDepositData[0]={...depositData[0], amount: amount1}
            updatedDepositData[1]={...depositData[1], amount: amount2}
            updatedDepositData[2]={...depositData[2], amount: amount3}
            updatedDepositData=[...updatedDepositData,...depositData]
            depositData = ensureArrayLength(updatedDepositData, 10)
            setTopDeposits(depositData)
        }
    }
  };
  useEffect(() => {
    if (configData) {
        getDeposits()
    }
  }, [configData]);

  return (
    <div
      className="pyramid"
      style={{ background: configData ? configData.live_transaction_bg : "" }}
    >
      <p
        className="pyramid-title"
        style={{ color: configData ? configData.text_color_secondary : "" }}
      >
        Top 10 Deposits
      </p>
      <div className="triangle">
        {topDeposits.map((deposit, index) => {
          let top_color = "";
          let top_text_color = "";
          if (index == 0) {
            top_color = "red";
            top_text_color = "#fff";
          }
          if (index == 1) {
            top_color = "green";
            top_text_color = "#ddd";
          }
          if (index == 2) {
            top_color = "yellow";
            top_text_color = "#333";
          }
          return (
            <div
              key={index}
              className="layer"
              style={{
                fontSize: index > 2 ? "0.8rem" : "1.6rem",
                height: index > 2 ? "35px" : "55px",
                lineHeight: index > 2 ? "35px" : "55px",
                background: index > 2 ? "#ddd" : top_color,
                color:
                  index > 2
                    ? configData
                      ? configData.primary_color
                      : ""
                    : top_text_color,
              }}
            >
                {
                    deposit.amount !== "No Data" &&
                    <span style={{fontSize: "32px",}}>
                        {
                            index == 0?
                            "🥇":
                            index == 1?
                            "🥈":
                            index == 2?
                            "🥉":
                            null
                        }
                    </span>
                }
              {deposit.amount}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LiveTable2;
