import "./component.css";
import React, { useEffect, useState } from "react";
import userService from "../service/user.service";
import PromoItem from "./listsItems/PromoItem";
import { useTranslation } from "react-i18next";

const Footerlicense = () => {
    const [promos, setPromos] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { t } = useTranslation();
    const SITE_NAME = process.env.REACT_APP_SITE_NAME;
    if(SITE_NAME && SITE_NAME === "DCBET88"){
        return (
            <div class="game-license">
                <div class="container footer-box-wrapper">
                    <div class="row footer-box mx-0 form-row">
                        <div class="col-12 footer-box-gaming-wrapper">
                            <div class="title">Gaming License:</div>
                            <div class="footer-box-gaming">
                                <i class="icon-curacao"></i>
                                <i class="icon-pagcor"></i>
                            </div>
                            <p class="licenseImg-desc">DC88 is a gaming company that has been verified by both Gaming Curacao and Pagcor, which provides players with a safe and secure online gaming experience.
                            </p>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="title">Certification:</div>
                            <div class="footer-box-certification">
                                <i class="icon-bmm"></i>
                                <i class="icon-itechlab"></i>
                                <i class="icon-global"></i>
                                <i class="icon-payouts"></i>
                                <i class="icon-verified"></i>
                            </div>
                        </div>
                        <div class="col-12 mb-3">
                            <div class="title mb-1">Payment Method:</div>
                            <div class="footer-box-payment">
                                <i class="icon-mbb"></i>
                                <i class="icon-cimb"></i>
                                <i class="icon-hlb"></i>
                                <i class="icon-rhb"></i>
                                <i class="icon-bsn"></i>
                                <i class="icon-pbb"></i>
                                <i class="icon-easypay"></i>
                                <i class="icon-surepay"></i>
                                <i class="icon-duitnow"></i>
                                <i class="icon-tng"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="title">Security:</div>
                            <div class="footer-box-security">
                                <i class="icon-iovation"></i>
                                <i class="icon-metrix"></i>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="title">Responsible Gaming:</div>
                            <div class="footer-box-responsible">
                                <i class="icon-rp1"></i>
                                <i class="icon-rp2"></i>
                                <i class="icon-rp3"></i>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="copy-right row form-row" style={{marginRight:"5px"}}>
                        <div class="col-8 px-0">Copyright DC88 @ 2019. All rights reserved.  </div>
                        <div class="col-4">
                            <div class="footer-box-follow">
                                <a href="javascript:void(0)" class="d-inline-block">
                                    <i class="icon-youtube"></i>
                                </a>
                                <a href="javascript:void(0)" class="d-inline-block">
                                    <i class="icon-facebook"></i>
                                </a>
                                <a href="javascript:void(0) " class="d-inline-block">
                                    <i class="icon-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
            </div>

        )
    }
}

export default Footerlicense;
