import "./component.css";
import React, { useState, useEffect, useContext } from "react";
import { Upload, message, Button } from "antd";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import UserService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import TransitionsModal from "./common/TransitionsModal";
import TransitionsModalPromotion from "./common/TransitionsModalPromotion";
import PromotionDescription, { formatPromotionMessage } from "./PromotionDescription"
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import { CountryAmountList } from "./common/AmountListbyCountry";

const initialValues = {
  ttype: "IB",
  amount: "",
  proof: undefined,
  merchant_bank_account: "",
};

const init_validation = {
  amount: "",
  merchant_bank_account: "",
  proof: "",
  non_field_errors: "",
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  return isJpgOrPng;
};

const Manual = () => {
  const [values, setValues] = useState(initialValues);
  const [banks, setBanks] = useState([]);
  // const [radioValue, setRadioValue] = useState(null);
  const [merchantBanks, setMerchantBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [validations, setValidations] = useState(init_validation);
  const [isLoading, setAPILoading] = useState(false);
  const [promotionList, setPromotionList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isTxnModal, setIsTxnModal] = useState(false);
  const [txnId] = useState("")
  const [selectedTnC, setSelectedTnC] = useState({
    title: "",
    description: "",
  });
  const [depSuccessful, setDepSuccessful] = useState('');
  const navigate = useNavigate()
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();


  const {
    amount: amountVal,
    merchant_bank_account: bank_Val,
    proof: proof_Val,
    non_field_errors: nonVal,
  } = validations;

  const onChangeRadio = (e) => {
    // setRadioValue(e.target.value);
    if (!e.target.checked) {
      setValues((preValues) => {
        const newVals = JSON.parse(JSON.stringify(preValues))
        delete newVals["promotion"]
        return { ...newVals };
      });
    } else {
      setValues((values) => {
        return { ...values, promotion: parseInt(e.target.value) };
      });
    }
  };


  const getBankList = async () => {
    setAPILoading(true);
    const getBanks = await UserService.getBanks();
    setBanks(getBanks.data);
    setAPILoading(false);
  }

  const getMerchantBanklist = async () => {
    setAPILoading(true);
    const WalletInfo = await UserService.getBalance();
    const { rank_id } = WalletInfo.data;
    const getMerchantlist = rank_id ? await UserService.getMerchantBanksWithRank(rank_id) : await UserService.getMerchantBanks();
    setMerchantBanks(getMerchantlist.data);
    setAPILoading(false);
  };

  const getPromotions = async () => {
    setAPILoading(true);
    const promotionListing = await UserService.getPromotionsDepositList();

    // const nonObj = {
    //   id: 0,
    //   title: "No promotion",
    //   active: true
    // }

    // promotionListing.data.push(nonObj);
    promotionListing.data.sort((a, b) => a.id - b.id);

    setPromotionList(promotionListing.data);
    // setSelectedTnC(promotionListing.data[0]);
    // setRadioValue(promotionListing.data[0].id);
    setAPILoading(false);
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Bank Account Number Copied',
    });
  };

  const handleNumChange = (e) => {
    const RE = /^\d*\.?\d{0,18}$/;
    if (RE.test(e.target.value) || e.target.value === "") {
      setValues((values) => {
        return { ...values, [e.target.name]: e.target.value };
      });
    }
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        const proofData = {};
        proofData.title = info.file.name;
        proofData.base64 = url;
        setValues({ ...values, proof: proofData });
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("Upload")}
      </div>
    </div>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleDeposit = async e => {
    e.preventDefault();
    setAPILoading(true);
    let response;
    try {
      response = await UserService.createDeposit(values);
      setDepSuccessful(response.data.id)
      // Modal.success({
      //   content: <p>Deposit is successful. <br />
      //     Transaction ID: {response.data.id}</p>,
      // });
      setImageUrl();
      setValues(initialValues);
      setValidations(init_validation);
      // navigate("/history")
    } catch (err) {
      console.log("err => ", err);
      let non_msg;
      let amount_msg;
      let bank_msg;
      let proof_msg;

      if (err.response.data.amount) {
        amount_msg = err.response.data.amount;
      }
      if (err.response.data.merchant_bank_account) {
        bank_msg = err.response.data.merchant_bank_account;
      }
      if (err.response.data.proof) {
        proof_msg = err.response.data.proof;
      }
      if (err.response.data.non_field_errors) {
        non_msg = err.response.data.non_field_errors;
      }
      setValidations({
        ...validations,
        amount: amount_msg,
        merchant_bank_account: bank_msg,
        proof: proof_msg,
        non_field_errors: non_msg
      });
    }
    setAPILoading(false);
  }

  useEffect(() => {
    getBankList();
    getPromotions();
    getMerchantBanklist();
  }, [])

  return (
    <div className="online-wrap" style={{minHeight: '80vh'}}>
      {contextHolder}
      <h2 className="my-2" style={{color: configData?configData.text_color :""}}>{t("Manual_Transfer")}</h2>
      <div className="banks-container">
        <div className="banks-wrap">
          {merchantBanks.map((bank, index) => (
            <div
              className={`col-4 bank-item ${
                selectedBank && selectedBank.id === bank.id
                ? "selected-bank"
                : ""
                }`}
              key={index}
              onClick={() => {
                setValues({ ...values, merchant_bank_account: bank.id });
                setSelectedBank(bank);
              }}
            >
              <img
                src={
                  banks.length > 0 &&
                    banks.find((bk) => bk.id === bank.bank).icon
                    ? banks.find((bk) => bk.id === bank.bank).icon
                    : "/images/game01.png"
                }
                width={50}
                alt=""
              />
              {/* <img src={bank.icon ? bank.icon : '/images/game01.png'} width={50} alt='' /> */}
              <div style={{ fontSize: "12px" }}>{bank.name}</div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${
          bank_Val === "" ? "valid-feedback" : "invalid-feedback"
          } `}
      >
        {bank_Val}
      </div>

      {selectedBank && (
        <div className="banks-info">
          <table>
           <tbody>
           <tr>
              <td>{t("Bank")} {t("Account")} {t("Name")}</td>
              <td>{selectedBank.name}</td>
            </tr>
            <tr>
              <td>{t("Bank")} {t("Account_Number")}</td>
              <td>{selectedBank.number}</td>
              <td
                className="copy-btn text-uppercase"
                onClick={() => {
                  navigator.clipboard.writeText(selectedBank.number)
                  success(selectedBank.number)
                }}
              >
                {t("Copy")}
              </td>
            </tr>
           </tbody>
          </table>
        </div>
      )}

      <div className="form-custom form-label my-4">
        <input
          name="amount"
          type="number"
          className="form-control rounded-xs"
          id="c3"
          placeholder={t("Amount")}
          onChange={handleNumChange}
          value={values.amount}
        />
        <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
      </div>

      <div className="d-flex text-center justify-content-between tab-scroll-wrap flex-wrap" style={{gap: "8px"}}>
        {configData && CountryAmountList(configData.country, "MD").map((item) => (
          <div className="pr-10 zIndex0 mb-2" key={item.id} style={{width: "calc(25% - 6px)"}}>
            <div
              className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${
                values.amount === item.value
                ? "border color-highlight"
                : "bg-highlight"
                }`}
              style={{width: "100%"}}
              onClick={() => setValues({ ...values, amount: item.value })}
            >
              {item.value}
            </div>
          </div>
        ))}
      </div>

      <div className="w-full mt-4">
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        <div
          className={`${
            proof_Val === "" ? "valid-feedback" : "invalid-feedback"
            } `}
        >
          {proof_Val}
        </div>
      </div>

      {/* <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
        <Space direction="vertical">
          {promotionList &&
            promotionList.length > 0 &&
            promotionList
              .filter((item) => item.active)
              .map((item) => {
                return (
                  <Radio
                    borderRadius={10}
                    value={item.id}
                    key={item.id}
                    onChange={() => setSelectedTnC(item)}
                    onClick={() => {
                      setModalOpen(true);
                      if (item.id !== 0) {
                        setValues((values) => {
                          return { ...values, promotion: item.id };
                        })
                      } else {
                        delete values.promotion;
                      }

                    }}
                  >
                    {item.title}
                  </Radio>
                );
              })}
        </Space>
      </Radio.Group> */}
      {configData&&!configData.enable_ctc && 
      <>          
        <h4 className="mt-4" style={{color: configData?configData.text_color :""}}>{t("Bonus")} {t("Selection")} <span style={{color: configData?configData.text_color_secondary:""}}>({t("optional")}):</span></h4>
        <div className="px-2 border border-white">
          {promotionList &&
            promotionList.length > 0 &&
            promotionList
              .filter((item) => item.active)
              .map((item) => {
                return (
                  <div className="bonus-radio_container d-flex align-items-center my-2" key={item.id}>
                    <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
                      <input className="bonus-radio" name="bonus-radio" type="checkbox" id="" value={item.id}
                      onChange={e=>{
                        // if(item.id === 0){
                        //   return
                        // }
                        if (e.target.checked) {
                          setModalOpen(true);
                          setSelectedTnC(item)
                        } else {
                          setSelectedTnC({
                            title: "",
                            description: "",
                          })
                        }
                        onChangeRadio(e)
                      }}
                      checked={item.id == values.promotion}
                      onClick={() => {
                        // setModalOpen(true);
                        if (item.id !== 0) {
                          setValues((values) => {
                            return { ...values, promotion: item.id };
                          })
                        } else {
                          delete values.promotion;
                        }

                      }}
                      />
                      <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
                        <span className="bonus-check">&#10004;</span>
                      </div>
                    </div>
                    <label className="ml-1 text-uppercase font-bold" style={{ color: configData?configData.text_color :"" }} htmlFor="">{item.title}</label>
                  </div>
                );
              })}
        </div>
        <p className="text-md d-flex mt-2 align-items-center" style={{color: configData?configData.text_color_secondary:"", gap: "5px", lineHeight: 1.5}}><InfoIcon /> {promotionList && promotionList.length ? t("PromotionMessage") : t("PromotionMessage2")}</p>
      </>
      }
      {/* <div className="bonus-radio_container d-flex align-items-center">
        <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
          <input className="bonus-radio" name="bonus-radio" type="radio" id="" />
          <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
            <span className="bonus-check">&#10004;</span>
          </div>
        </div>
        <label className="ml-1 text-uppercase font-bold" style={{ color: "#fff" }} htmlFor="">Text</label>
      </div> */}
      {/* <div className="bonus-radio_container d-flex align-items-center">
        <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
          <input className="bonus-radio" type="radio" name="bonus-radio" id="" />
          <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
            <span className="bonus-check">&#10004;</span>
          </div>
        </div>
        <label className="ml-1 text-uppercase font-bold" style={{ color: "#fff" }} htmlFor="">Text</label>
      </div> */}
      <div>
        {
        nonVal && nonVal.map((item, index) => (
          <div key={index}>
              {formatPromotionMessage(item, t)}
          </div>
        ))
        }
      </div>

      <Button
        loading={loading}
        disabled={loading}
        className="btn btn-full bg-blue-dark rounded-xs
        text-uppercase font-700 w-100 btn-s mt-5 h-50"
        onClick={handleDeposit}
      >
        {t("Submit")}
      </Button>
      <TransitionsModal
        title="Congratulations!"
        style={{ top: "50px"}}
        bodyStyle={{height: "250px"}}
        open={isTxnModal}
        onClose={() => setIsTxnModal(false)}
        onOk={() => navigate("/account")}
      // footer={[]}
      >
        <h3 className="text-lg font-semibold">{t("Deposit_is_successful")}.</h3>
        <p className="font-semibold mt-1">
          {t("Transaction_Id")}: <span className="text-lime-700">{txnId}</span>
        </p>
      </TransitionsModal>

      {/* <TransitionsModal
        title={selectedTnC.title}
        centered
        open={modalOpen}
        footer={[]}
        onClose={() => setModalOpen(false)}
      >
        <p className="text-lg font-semibold">{t("Terms&Conditions")}</p>

        <div
          className="mt-2 promo_modal"
          dangerouslySetInnerHTML={{ __html: selectedTnC.description }}
        />
      </TransitionsModal> */}
      <TransitionsModalPromotion
          title={selectedTnC.title}
          className="antd-promotion-modal"
          footer={[]}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          style={{ textAlign: "center" }}
      >
          <PromotionDescription
              field={selectedTnC.description}
              data={""}
          />
        </TransitionsModalPromotion>

      <TransitionsModal
        title={''}
        centered
        open={depSuccessful ? true : false}
        footer={[]}
        // onClose={() => setDepSuccessful(false)}
      >
        <div className="d-flex align-items-center">
        <Icon icon="ep:success-filled" color="#52c41a" fontSize={22} />
        <p style={{fontSize:"14px", paddingLeft:"10px"}}>{t("Deposit_is_successful")}. <br />
          {t("Transaction_Id")}: {depSuccessful}</p>
        </div>

        <button style={{marginLeft:"auto", background: "#1677ff", fontWeight:"100", fontSize:"15px"}}
                                onClick={() => {
                                  setDepSuccessful('')
                                  navigate("/history")

                                }}
                                className={`btn btn-sm d-flex py-2 ml-auto rounded-xs text-uppercase
                                            mt-3`}>{t("OK")}
                            </button>
      </TransitionsModal>
      
      {isLoading && <Loading />}
    </div>
  );
};

export default Manual;
