import React from "react";
import { useContext } from "react";
import { ConfigContext } from "../../../ConfigContext";
import { Icon } from "@iconify/react";

function Input4({ leftIcon, rightIcon, ...props }) {
  const configData = useContext(ConfigContext);
  return (
    <div
      className="login-input_container d-flex align-items-center px-2 pr-1 mb-2"
      style={{
        borderRadius: "12px",
        background: "rgba(255,255,255,.2)",
        height: "50px",
      }}
    >
      {leftIcon}
      <input
        type="text"
        style={{
          width: "100%",
          background: "none",
          border: "none",
          outline: "none",
          padding: "0 10px",
          color: "#fff",
          height: "100%",
          fontSize: "16px",
        }}
        {...props}
      />
      {rightIcon}
    </div>
  );
}

export default Input4;
