import React from 'react'
import "../layout.css"
import { useContext } from 'react'
import { ConfigContext } from '../../../ConfigContext'

function LayoutCard({children, style, className}) {
  const configData = useContext(ConfigContext)
  return (
    <div className={`layout-card ${className?className:""}`} style={{backgroundColor: configData?configData.layout_card_bg:"#242424", ...style}}>
        {children}
    </div>
  )
}

export default LayoutCard