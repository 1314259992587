import React, { useRef, useEffect, useState } from "react";
import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";
import "../suneditor.min.css"
import { useTranslation } from "react-i18next";

export const promotionMessageMappings = {
  limit_per_user: "Limit_Per_User",
  is_product_launch: "Is_Product_Launch",
  group_applied_can_claim_promo_group: "Group_Promotion_Can_Claim_Together",
  group_applied: "Group_Promotion_Applied",
  is_bankaccount_required: "Is_Bank_Account_Required",
  new_member: "New_Member",
  first_deposit: "First_Deposit",
  amount_in_x_days: "Amount_in_X_Days",
  min_x_deposit_min_y_amount: "Min_X_Deposit_Min_Y_Amount",
  is_daily_x_deposit: "Is_Daily_Deposit",
  start_date: "Start_Date",
  start_time: "Start_Time",
  end_date: "End_Date",
  end_time: "End_Time",
  not_deposit_yet: "Not_Deposited_Yet",
  num_deposits_required: "Num_Deposits_Required",
  limit: "Limit",
  active: "Active",
  min_deposit: "Min_Deposit",
  total_deposit_required: "Total_Deposit_Required",
  deposit_not_needed: "Deposit_Not_Needed",
  bonus_amount: "Bonus_Amount",
  promotion_day: "Promotion_Day",
  referral_check: "Referral_Check",
  referral_deposit_check: "Referral_Deposit_Check",
  min_x_deposit_min_y_amount_z_downline: "Min_X_Deposit_Min_Y_Amount_Z_Downline",
  sites: "Sites",
  first_deposit_CA: "First_Deposit_CA",
  first_deposit_CH: "First_Deposit_CH",
  recurrence_frequency: "Recurrence_Frequency",
  reward_type_deposit: "Reward_Type_Deposit",
  reward_type: "Reward_Type",
};

export function formatPromotionMessage(item, t) {
  if (typeof item === "string") {
    return (
      <span style={{ color: "red" }}>{item}</span>
    );
  } else if (typeof item === "object") {
    const key = Object.keys(item)[0];
    const value = Object.values(item)[0];

    if (value !== "no_checking") {
      if (promotionMessageMappings[key]) {
        if (value === "Pass") {
          return (
            <>
              <span style={{ color: "white" }}>* {t(promotionMessageMappings[key]) + " ✅ " }</span>
              {/* {promotionMessageMappings[key] + ": "}
              * <span style={{ color: "lightgreen" }}>{value}</span> */}
            </>
          );
        } else {
          return (
            <>
              <span style={{ color: "white" }}>* {t(promotionMessageMappings[key]) + " ❌ " }</span>
              {/* {promotionMessageMappings[key] + ": "}
              * <span style={{ color: "red" }}>{value}</span> */}
            </>
          );
        }
      } else {
        return (
          <>
            * {key + ": " + value}
          </>
        );
      }
    }
  }
}

const PromotionDescription = ({field,data,conditionEnabled=true}) => {      
    // style="text-align: center;"
    const [modifiedHtml, setModifiedHtml] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
      // Modify the HTML string as needed
      // const modifiedField = field.replaceAll('<table>', '<table style="border: 1px solid #ddd; width: 100%;">')
      // .replaceAll('<th>', '<th style="background-color: rgb(74,134,232); border: 1px solid #ddd;">')
      // .replaceAll('<th colspan="2" rowspan="1">', '<th colspan="2" rowspan="1" style="background-color: rgb(74,134,232); border: 1px solid #ddd;">')
      // .replaceAll('<td>', '<td style="border: 1px solid #ddd; padding: 8px;">')
      // .replaceAll('<td colspan="2">', '<td colspan="2" style="border: 1px solid #ddd; padding: 8px;">')
      // .replaceAll('<td colspan="3">', '<td colspan="3" style="border: 1px solid #ddd; padding: 8px;">')
      // .replaceAll('<td colspan="4">', '<td colspan="4" style="border: 1px solid #ddd; padding: 8px;">')
      // .replaceAll('<td colspan="5">', '<td colspan="5" style="border: 1px solid #ddd; padding: 8px;">');

      setModifiedHtml(field);
    }, [field]);
    return (
        <>
        {data && data.applicable &&<p>Amount : {data.bonus_amount}</p>}
        <div
            className="sun-editor-editable"
            style={{
              padding: "10px"
            }}
            dangerouslySetInnerHTML={{
            __html: `${modifiedHtml}`,
            }}
        ></div>
        {data && data.message ?
        conditionEnabled &&
            <div>
                <p style={{ color: "yellow" }}>Condition Meet: {data && data.applicable && data.applicable ? 
                <span style={{ color: "lightgreen"}}>Pass</span> : <span style={{ color: "red"}}>Not Meet</span>}</p>
                {data && data.message.map((item, index) => (
                <div key={index}>
                    {formatPromotionMessage(item, t)}
                </div>
                ))}
            </div>
        :
        <div>
            <p>Please Login to claim</p>
        </div>
        }
        </>
    );
};

export default PromotionDescription;