import React from "react";
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import SignUp2 from "./pages/SignUp2";
import SignUp3 from "./pages/SignUp3";
import Forget from "./pages/Forget";
import VerifyTac from "./pages/VerifyTac";
import { useState } from "react";
import Home from "./pages/Home";
import Header from "./components/Header";
import Contactus from "./pages/Contactus";
import Promotions from "./pages/Promotions";
import ClickToClaim from "./components/ClickToClaim";
import ForgetPassword from "./pages/forgetpassword";
import { ConfigContext } from "./ConfigContext";
import { useContext } from "react";
import Subline from "./components/Subline";

const AuthLayout = ({ setAuth }) => {
  const [userId, setUserId] = useState(null);
  const configData = useContext(ConfigContext);

  const footer_content_visible = configData && configData.footer_content_enabled && ((window.location.pathname == "/contactus") || (window.location.pathname == "/promotions") || (window.location.pathname == "/"))
  return (
    <div>
      <div className="t1">
        <Header setAuth={setAuth} />
      </div>
      <Routes>
        <Route path="/signin" element={<SignIn setAuth={setAuth} />} />
        <Route path="/signup" element={<SignUp setUserId={setUserId} userId={userId} setAuth={setAuth} />} />
        <Route path="/signup2" element={<SignUp2 userId={userId} />} />
        <Route
          path="/signup3"
          element={<SignUp3 userId={userId} setAuth={setAuth} />}
        />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/subline" element={configData && configData.sub_line ? <Subline /> : ""} />
        <Route path="/verify-tac" element={<VerifyTac />} />
        <Route path="/" element={<Home setAuth={setAuth} />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/promotions" element={configData && configData.enable_ctc ?<ClickToClaim />:<Promotions />} />
        <Route path="/*" element={<SignIn setAuth={setAuth} />} />
      </Routes>
    </div>
  );
};

export default AuthLayout;
