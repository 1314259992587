import React, { useEffect, useRef, useState,useContext } from "react";
import { toast } from "react-toastify";
import "./LuckyWheel.css"; // Make sure to create this CSS file to style your wheel
import { Wheel } from "react-custom-roulette";
import { Icon } from "@iconify/react";
import "../../suneditor.min.css"
import userService from "../../service/user.service";
import TransitionsModal from "../common/TransitionsModal";
import useToast from "../../utils/useToast";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../ConfigContext";

const LuckyWheel = ({ selectedIndex, getWalletInfo, fetchTokens }) => {
  const canvasRef = useRef(null);
  const [angle, setAngle] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [segments, setSegments] = useState([]);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeCongraz, setPrizeCongraz] = useState(false);
  const [prizeCongrazOpen, setPrizeCongrazOpen] = useState(false);
  const [error, setError] = useState(null);
  const [prizeWon, setPrizeWon] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const configData = useContext(ConfigContext)

  const fetchSegments = async () => {
    try {
      const data = await userService.getLuckyWheelSlots();
      const API_URL = process.env.REACT_APP_APIURL;
      setLoading(true);
      if (data.data && data.data.length) {
        setSegments(
          data.data.map((v) => {
            return v.image?{
              option: `${v.amount}`,
              style: { backgroundColor: v.color, color: "#fff" },
              image: { uri: v.image?`${API_URL}${v.image}`:'', offsetY: 200},
              id: v.id,
            }:{
              option: `${v.amount}`,
              style: { backgroundColor: v.color, color: "#fff" },
              id: v.id,
            }
          })
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSegments();
    fetchTokens();
  }, []);

  const handleSpinClick = async () => {
    setLoading(true);
    setPrizeWon(false);
    try {
      const data = await userService.spinLuckyWheel();
      getWalletInfo();
      fetchTokens();
      if (data.data.id) {
        const selectedSlot = segments.findIndex((v) => v.id == data.data.id);
        setPrizeNumber(selectedSlot);
        setMustSpin(true);
        // setPrizeCongraz(data.data.terms_condition)
        setPrizeCongraz(`${t('Congratulations_reward_get')} ${configData && configData.currency_symbol} ${data.data.amount}`)
        setLoading(false);
        setError(null);
      } else {
        setError({ msg: "Something went wrong" });
      }
    } catch (error) {
      // console.log(error?.response)
      if (error && error.response && error.response.data && error.response.data.error) {
        setError({ msg: error.response.data.error });
      } else {
        setError({ msg: error.message });
      }
      setLoading(false);
    }
  };

  return (
    <>
    <TransitionsModal
        title={"Error"}
        open={error ? true : false}
        onClose={() => setError(false)}
        style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
        modalStyle={{zIndex:10000}}
        container={() => document.getElementById("root")}
      >
        <div>
          <div className="flex flex-row justify-center">
            <Icon icon="codicon:error" color="#F27474" width={70} />
          </div>
          <p className="text-md font-medium my-3 text-white">
            {error && error.msg ? error.msg : null}
          </p>
          <button
            className={`btn btn-sm bg-red-dark rounded-xs text-uppercase
                                  font-700 btn-l mt-3 me-2 h-12`}
            onClick={() => setError(false)}
          >
            {"Ok"}
          </button>
        </div>
      </TransitionsModal>
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: "100%",
        margin: "auto",
      }}
    >
      <div
        _ngcontent-bdv-c19=""
        class="wespin-spin-wheel-div"
        style={{ position: "absolute" }}
      >
        {
          prizeWon &&
          <>
            <div _ngcontent-bdv-c19="" class="wespin-spin-win absolute top-0 left-0" style={{width: "50%", height: "50%"}}>
              <img className="w-full h-full" src={require("../../images/wheel/firework.gif")} alt="" style={{filter: "saturate(100%)" }} />
            </div>
            <div _ngcontent-bdv-c19="" class="wespin-spin-win absolute top-0 right-0" style={{width: "50%", height: "50%"}}>
              <img className="w-full h-full" src={require("../../images/wheel/firework.gif")} alt="" style={{filter: "saturate(100%)" }} />
            </div>
            <div _ngcontent-bdv-c19="" class="wespin-spin-win absolute bottom-0 left-0" style={{width: "50%", height: "50%"}}>
              <img className="w-full h-full" src={require("../../images/wheel/firework.gif")} alt="" style={{filter: "saturate(100%)" }} />
            </div>
            <div _ngcontent-bdv-c19="" class="wespin-spin-win absolute bottom-0 right-0" style={{width: "50%", height: "50%"}}>
              <img className="w-full h-full" src={require("../../images/wheel/firework.gif")} alt="" style={{filter: "saturate(100%)" }} />
            </div>
          </>
        }
        <div _ngcontent-bdv-c19="" class="wespin-spin-float-tr"></div>
        <div _ngcontent-bdv-c19="" class="wespin-spin-float-bl"></div>
        <div _ngcontent-bdv-c19="" class="wespin-spin-wheel-bg"></div>
        <div _ngcontent-bdv-c19="" class="wespin-spin-wheel"></div>
        <div
          _ngcontent-bdv-c19=""
          class="wespin-spin-btn"
          onClick={() => !loading && handleSpinClick()}
        >
          {" "}
          {!loading ? (mustSpin ? "Spinning" : "Spin") : "Loading"}{" "}
        </div>
        <div
          className="lucky-wheel_container"
          style={{
            position: "absolute",
            width: "75%",
            height: "75%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) rotate(-45deg)",
          }}
        >
          {segments.length && (
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={segments}
              backgroundColors={["#3e3e3e", "#df3428"]}
              textColors={["#ffffff"]}
              onStopSpinning={() => {
                setMustSpin(false);
                setPrizeCongrazOpen(true)
                // toast.success(prizeCongraz);
                setPrizeWon(true);
              }}
              disableInitialAnimation={true}
            />
          )}
        </div>
      </div>
    </div>
      <TransitionsModal
        title={""}
        open={prizeCongrazOpen}
        style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
      >
        <div>
          <div className="flex flex-row justify-center">
            <Icon icon="mdi:success-circle-outline" color="#4CAF50" width={70} />
          </div>
          <p className="text-md font-medium mt-3">
            {prizeCongraz}
          </p>
          <button
            onClick={() => setPrizeCongrazOpen(false)}
            className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
          font-700 w-100 btn-l mt-3 h-12`}
          >
            {t("OK")}
          </button>
        </div>
      </TransitionsModal>

    {/* <TransitionsModal
        // title="Congratulation !"
        open={prizeCongrazOpen}
        onClose={() => setPrizeCongrazOpen(false)}
        containerStyle={{
          width: "60%", 
          overflowY: "auto", 
          maxWidth: "960px"
        }}
        style={{
          textAlign: "center",
          borderRadius: "15px",
        }}
      >
        <div
            className="sun-editor-editable"
            style={{
              padding: "10px"
            }}
            dangerouslySetInnerHTML={{
            __html: `${prizeCongraz}`,
            }}
        ></div>
      </TransitionsModal> */}
    </>
  );
};

export default LuckyWheel;
