import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import backgroundImg1 from '../../src/images/ECI88_Header.jpg';
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';

function Footer({isHome}) {
  const [active, setActive] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const configData = useContext(ConfigContext)
  const { t } = useTranslation();

  const handleTabClick = (tabIndex, path, e) => {
    e.preventDefault()
    if(isHome && path === '/'){
      window.location.reload()
    }
    setActive(tabIndex);
    navigate(path);
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      setActive(`${configData && configData.menu_home_route}`);
    } else if (path === `/${configData && configData.menu_promo_route}`) {
      setActive(`${configData && configData.menu_promo_route}`);
    } else if (path === `/${configData && configData.menu_deposit_route}`) {
      setActive(`${configData && configData.menu_deposit_route}`);
    } else if (path === `/${configData && configData.menu_history_route}`) {
      setActive(`${configData && configData.menu_history_route}`);
    } else if (path === `/${configData && configData.menu_contact_us_route}`) {
      setActive(`${configData && configData.menu_contact_us_route}`);
    } else {
      setActive(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (configData) {
      if (configData.menu_promo_default) {
        setActive(`${configData && configData.menu_promo_route}`)
      } else if (configData.menu_deposit_default) {
        setActive(`${configData && configData.menu_deposit_route}`)
      } else if (configData.menu_history_default) {
        setActive(`${configData && configData.menu_history_route}`)
      } else if (configData.menu_contact_us_default) {
        setActive(`${configData && configData.menu_contact_us_route}`)
      }
      // else{
      //   setActive(1)
      // }
    }
  }, [configData])

  // const checkBg = (val) =>{
  //   if(val[0] === "#" || val.includes("rgb") || val.includes("hsl")){
  //     return val
  //   }
  //   return `url(${val})`

  //   // if(val.includes("http") || val.includes(".png") || val.includes(".jpeg") || val.includes("jpg") || val.includes("svg")){
  //   //   return `url(${val})`
  //   // }
  //   // return val;
  // }
  const checkBg = (configData, type) => {
    if (!configData) {
      return {}
    }
    if (configData[configData[type]][0] === "#" || configData[configData[type]].includes("rgb") || configData[configData[type]].includes("hsl")) {
      return { backgroundColor: configData[configData[type]] }
    }
    return { backgroundImage: `url(${configData[configData[type]]})` }
  }

  const menuText = (text) => {
    if (text.includes("http") || text.includes(".png") || text.includes(".jpeg") || text.includes("jpg") || text.includes("svg") || text.includes('data:')) {
      return <img src={text} alt='' width="auto" height="25px" />
    }
    return text;
  }

  return (
    <>
      <div id="footer-bar" className="footer-bar-1 text-uppercase" style={{
        // background: checkBg(configData?configData.footer_bg :""),
        ...checkBg(configData, "footer_bg_type"),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
        <a
          href='/#'
          data-active={active === `${configData && configData.menu_home_route}`}
          className={active === `${configData && configData.menu_home_route}` ? "active-nav" : ""}
          style={active === `${configData && configData.menu_home_route}` ? { background: configData ? configData.footer_bg_active_color : "" } : {}}
          onClick={(e) => handleTabClick(`${configData && configData.menu_home_route}`, configData.menu_home_route ? `${configData.menu_home_route}` : "/", e)}
        >
          <img src={configData ? configData.menu_home : ""} alt="" width="auto" height="30px" />
          <p>{menuText((configData ? (configData[configData.menu_home_text_type] ? t(configData[configData.menu_home_text_type]) : t(configData.static_menu_home_text_color)) : ""))}</p>
          {/* <p>{menuText(configData?configData[configData.menu_home_text_type  ]:"")}</p> */}
        </a>

        <a
          href='/#'
          data-active={active === `${configData && configData.menu_promo_route}`}
          className={active === `${configData && configData.menu_promo_route}` ? "active-nav" : ""}
          style={active === `${configData && configData.menu_promo_route}` ? { background: configData ? configData.footer_bg_active_color : "" } : {}}
          onClick={(e) => handleTabClick(`${configData && configData.menu_promo_route}`, configData.menu_promo_route ? `/${configData.menu_promo_route}` : "/promotions", e)}
        >
          <img src={configData ? configData.menu_promo : ""} alt="" width="auto" height="30px" />
          {/* <p>{configData?configData.menu_Promo_Text:""}</p> */}
          <p>{menuText((configData ? (configData[configData.menu_promo_text_type] ? t(configData[configData.menu_promo_text_type]) : t(configData.static_menu_promo_text_color)) : ""))}</p>
          {/* <p>{menuText(configData?configData[configData.menu_promo_text_type  ]:"")}</p> */}
        </a>

        <a
          href='/#'
          data-active={active === `${configData && configData.menu_deposit_route}`}
          className={active === `${configData && configData.menu_deposit_route}` ? "active-nav" : ""}
          style={active === `${configData && configData.menu_deposit_route}` ? { background: configData ? configData.footer_bg_active_color : "" } : {}}
          onClick={(e) => handleTabClick(`${configData && configData.menu_deposit_route}`, configData.menu_deposit_route ? `/${configData.menu_deposit_route}` : "/deposit", e)}
        >
          <img src={configData ? configData.menu_deposit : ""} alt="" width="auto" height="30px" />
          <p>{menuText((configData ? (configData[configData.menu_deposit_text_type] ? t(configData[configData.menu_deposit_text_type]) : t(configData.static_menu_deposit_text_color)) : ""))}</p>
          {/* <p>{menuText(configData?configData[configData.menu_deposit_text_type ]:"")}</p> */}
        </a>

        <a
          href='/#'
          data-active={active === `${configData && configData.menu_history_route}`}
          className={active === `${configData && configData.menu_history_route}` ? "active-nav" : ""}
          style={active === `${configData && configData.menu_history_route}` ? { background: configData ? configData.footer_bg_active_color : "" } : {}}
          onClick={(e) => handleTabClick(`${configData && configData.menu_history_route}`, configData.menu_history_route ? `/${configData.menu_history_route}` : "/history", e)}
        >
          <img src={configData ? configData.menu_history : ""} alt="" width="auto" height="30px" />
          <p>{menuText((configData ? (configData[configData.menu_history_text_type] ? t(configData[configData.menu_history_text_type]) : t(configData.static_menu_history_text_color)) : ""))}</p>
          {/* <p>{menuText(configData?configData[configData.menu_history_text_type ]:"")}</p> */}
        </a>

        <a
          href='/#'
          data-active={active === `${configData && configData.menu_contact_us_route}`}
          className={active === `${configData && configData.menu_contact_us_route}` ? "active-nav" : ""}
          style={active === `${configData && configData.menu_contact_us_route}` ? { background: configData ? configData.footer_bg_active_color : "" } : {}}
          onClick={(e) => handleTabClick(`${configData && configData.menu_contact_us_route}`, configData.menu_contact_us_route ? `/${configData.menu_contact_us_route}` : "/contactus", e)}
        >
          <img src={configData ? configData.menu_contract_us
            : ""} alt="" width="auto" height="30px" />
          <p>{menuText((configData ? (configData[configData.menu_contact_us_text_type] ? t(configData[configData.menu_contact_us_text_type]) : t(configData.static_menu_contact_us_text_color)) : ""))}</p>
          {/* <p>{menuText(configData?configData[configData.menu_contact_us_text_type ]:"")}</p> */}
          {/* <span id='chat_count' className='badge_anima main_pulse'>1</span> */}
        </a>


      </div>
    </>
  );
}

export default Footer;
