import React, { useContext } from 'react';
import './component.css';
import Footer from './Footer';
import { ConfigContext } from '../ConfigContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import formatDate from '../utils/formatDate';
// import { Icon } from '@iconify/react';

function History2({ activeKey, items, onChange, orders, type, handleDelete }) {
    const configData = useContext(ConfigContext);
    const navigate = useNavigate();
    const { t } = useTranslation();



    return (
        <div className='t1'>
            <div className='page' >
                <div className="page-content footer-clear">
                    <div id="history2" style={{ color: configData ? configData.text_color : "#fff" }}>
                        <div className="tabs">
                            <div className="tab"
                                style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "#546E7A" }}
                                onClick={(e) => {
                                    navigate("/history")
                                }}>Transactions</div>
                            <div className="tab" onClick={(e) => { navigate("/bet-history2") }}>Bet History</div>
                        </div>
                        <div id='history-transaction'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ border: `1px solid ${configData ? configData.border_color : ""}`, color: configData ? configData.text_color : "" }}>Transactions</td>
                                        <td style={{ border: `1px solid ${configData ? configData.border_color : ""}`, color: configData ? configData.text_color : "" }}>Status</td>
                                    </tr>
                                    {
                                        orders.map((row, index) => {
                                            const dateTime = formatDate(row.created_at);
                                            return (
                                                <tr data-id="1452989155">
                                                    <td style={{ border: `1px solid ${configData ? configData.border_color : ""}` }}>
                                                        <b style={{ color: configData ? configData.text_color_secondary : "", textTransform: "uppercase" }}>{row.ttype_display}</b><br />
                                                        <span>{row.txid}</span><br />
                                                        {/* <span className="d-flex justify-content-between"><span style={{ fontSize: "11px", color: "gray", textAlign: "left" }}>{t("Requested")} :</span> {row.req_amount}</span> */}
                                                        <span className="d-flex justify-content-between"><span style={{ fontSize: "11px", color: "gray", textAlign: "left" }}>{t("Actual")} :</span> {row.amount}</span>
                                                        {row.forfeit > 0 ? <span className="d-flex justify-content-between"><span style={{ fontSize: "11px", color: "gray", textAlign: "left" }}>{t("Forfeited")} :</span> {row.forfeit}</span> : null}
                                                        <span>{dateTime.formattedDate} {dateTime.time}</span>
                                                    </td>
                                                    <td style={{ border: `1px solid ${configData ? configData.border_color : ""}` }}>
                                                        <i className={`fa-solid fa-circle me-2 ${row.state === "approved"
                                                            ? "status-approve"
                                                            : row.state === "error"
                                                                ? "status-error"
                                                                : row.state === "rejected"
                                                                    ? "status-reject"
                                                                    : row.state === "deleted"
                                                                        ? "status-delete"
                                                                        : "status-pending"
                                                            }`} style={{ color: "transparent", borderRadius: "50%" }}></i>
                                                            <span>{row.state}</span>
                                                        {/* <span>{row.state === "pending" && ["BT", "BM", "BC"].indexOf(row.ttype) === -1 ? (
                                                            <span
                                                                className="pending-delete-btn"
                                                                onClick={() => handleDelete(row.id, type)}
                                                            >
                                                                <Icon icon="ic:outline-delete" fontSize={18} />
                                                            </span>
                                                             ) : null}
                                                        </span> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>

        </div>
    )
}

export default History2;