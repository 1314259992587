import React, { useContext } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { ConfigContext } from "../../ConfigContext";

const SkeletonWrapper = ({ children }) => {
  const configData = useContext(ConfigContext);

  return (
    <SkeletonTheme
      baseColor={configData && configData.skeleton_base_color ? configData.skeleton_base_color : "#1d1d1d"}
      highlightColor={configData && configData.skeleton_highlight_color ? configData.skeleton_highlight_color : "#333333"}
    >
      {children}
    </SkeletonTheme>
  );
};

export default SkeletonWrapper;
