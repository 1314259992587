import React, { useEffect } from 'react';
import BankItem from "./listsItems/BankItem";
import { useState } from 'react';
import userService from '../service/user.service';

function BankList({ fetchWallet, myBanks }) {
    const [banks, setBanks] = useState([])
    const getBankList = async () => {
        const getBanks = await userService.getBanks();
        setBanks(getBanks.data);
    }
    useEffect(() => {
        fetchWallet();
        getBankList();
    }, [localStorage.getItem("user")]);

    return (
        <div>
            {myBanks.map((item, index) => {
                const newBank = banks.filter(bank => bank.id == item.bank)
                let bankName = "";
                if (newBank && newBank.length) {
                    bankName = newBank[0].name
                }
                return (
                <BankItem key={index} item={item} bankName={bankName} />
            )})}
        </div>
    );
}

export default BankList;
