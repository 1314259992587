import React, { useEffect, useState, useContext, useRef } from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import UserService from "../service/user.service";
import Loading from "../components/Loading";
import { ConfigContext } from "../ConfigContext";
import { useLocation } from "react-router-dom";
import PromotionDescription from "../components/PromotionDescription";
import Promo2 from "../components/layout/Promo/Promo2";

const { TabPane } = Tabs;

function Promotions() {
  // const iframeRef = useRef(null)

  const { state } = useLocation();

  const [promos, setPromos] = useState([]);
  const [categoryTabs, setCategoryTabs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const [promoModal, setPromoModal] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [scroll, setScroll] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const navigate = useNavigate();
  const tabRef = useRef();
  const configData = useContext(ConfigContext);

  const getPromos = async () => {
    setLoading(true);
    const games = await UserService.getPromotions();
    try{
      const tabs = await UserService.getPromotionsTabs();
      if(tabs.data&&tabs.data.length){
        setCategoryTabs(tabs.data.map((v,i)=>({key:v[0],label:<p {...(i==0?{ref:tabRef}:{})} style={{color:configData?configData.text_color:undefined}}>{v[1]}</p>})))
        // handleTab(tabs.data[1][0])
      }
    }catch(e){

    }
    if (games.data) {
      setPromos(games.data);
    }
    setLoading(false);
  };

  const scrollToPromoItem = (promoId, offset = 100) => {
    // Find the promo item with the matching ID in your promos array
    const promoToScrollTo = promos.find((item) => item.id === promoId);
    // Check if the promo item exists
    if (promoToScrollTo && promoToScrollTo.ref) {
      // Scroll to the promo item

      // Calculate the distance to scroll
      const start = window.scrollY;
      const end =
        promoToScrollTo.ref.getBoundingClientRect().top +
        window.scrollY -
        offset;
      const distance = end - start;
      const duration = 1000; // 1 second duration for smooth scroll

      let startTime;

      const animateScroll = (timestamp) => {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;

        window.scrollTo(
          0,
          start + easeInOutQuad(elapsed, 0, distance, duration)
        );

        if (elapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      // Start the smooth scroll animation for the initial scroll
      requestAnimationFrame(animateScroll);
    }
  };

  // Easing function for smooth scroll
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position here and reset selectedId if needed
      if (window.scrollY > 100) {
        setScroll(true);
      }
    };

    setTimeout(() => {
      // Attach the scroll event listener when the component mounts
      window.addEventListener("scroll", handleScroll);
    }, 3000);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getPromos();
  }, []);

  useEffect(() => {
    if (state && promos.length !== 0) {
      scrollToPromoItem(state);
      setSelectedId(state);
    }
  }, [state, promos]);

  const [promoDetailVisibility, setPromoDetailVisibility] = useState({});
  const togglePromoDetail = (id) => {
    setPromoDetailVisibility(prevVisibility => ({
        ...prevVisibility,
        [id]: !prevVisibility[id]
    }));
  };

  const handleTab = (active)=>{
    setActiveTab(active)
    setTimeout(() => {
      Array.from(
        document.querySelectorAll(".ant-tabs-tab .ant-tabs-tab-btn")
      ).forEach((ele) => {
        ele.style.background = "transparent";
      });

      Array.from(
        document.querySelectorAll(
          ".ant-tabs-tab-active .ant-tabs-tab-btn"
        )
      ).forEach((ele) => {
        ele.style.background = configData
          ? configData.secondary_color
          : "";
        ele.style.color = configData ? configData.text_color : "";
      });
    }, 100);
  }

  return (
    <div className="t1">
      <div className="page">
        <div className="page-content">
          <div className="card-promotion">
            <br />
            <div className="row me-0 ms-0 mb-0">
            <Tabs 
              items={[{key:'all',label:<p style={{color:configData?configData.text_color:undefined}}>All</p>},...categoryTabs.filter(tab=>promos.findIndex(promo=>promo.category===tab.key)>=0)]} 
              onChange={handleTab} 
              activeKey={activeTab}
            />
              {promos.filter(p=>(activeTab=='all')?true:(p.category===activeTab)).map((item, index) => (
                <motion.div
                  className="col-12 ps-0 pe-0 mb-4"
                  key={item.id}
                  ref={(el) => (item.ref = el)}
                  animate={{
                    scale: state
                      ? scroll
                        ? 1
                        : selectedId === item.id
                        ? 1
                        : 0.8
                      : 1,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                >
                  {
                    (configData && (configData.promo_style == "1") || (configData && !configData.promo_style))?
                    <div
                    className="card card-style mb-0"
                    style={{
                      backgroundColor: "transparent",
                      border: "2px solid #f5ed95",
                    }}
                  >
                    <div style={{ height: "140px" }}>
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid"
                        style={{
                          borderBottom: "2px solid #f5ed95",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    <div className="promo_title" style={{fontFamily: `'${item.title_font_style}'`, color: configData ? configData.text_color_secondary : ""}}>{item.title}</div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <div style={{ flex: "1 1 48%" }}>
                        <img
                          src={configData ? configData.promo_moredetails : ""}
                          alt=""
                          style={{
                            width: "100%",
                            background: "transparent",
                            marginTop: "10px",
                            marginBottom: "10px",
                            cursor: "pointer", // Add a pointer cursor to indicate it's clickable
                          }}
                          onClick={() => {
                            togglePromoDetail(item.id);
                          }}
                        />
                      </div>
                      <div style={{ flex: "1 1 2%" }}></div>
                      <div style={{ flex: "1 1 48%" }}>
                        <img
                          src={configData ? configData.promo_joinnow : ""}
                          alt=""
                          style={{
                            width: "100%",
                            background: "transparent",
                            marginTop: "10px",
                            marginBottom: "10px",
                            cursor: "pointer", // Add a pointer cursor to indicate it's clickable
                          }}
                          onClick={() => {
                            navigate("/deposit");
                          }}
                        />
                      </div>
                    </div>
                    {promoDetailVisibility && promoDetailVisibility[item.id] && (
                      <div
                        className="promo_dropdown"
                        style={{ maxHeight: "80vh", overflowY: "auto"}}
                      >
                          {item.description ? (
                            <PromotionDescription field={item.description}/>
                          ) : null}
                      </div>
                      )}
                  </div>
                  :null
                  }
                  {
                    configData && (configData.promo_style == "2")?
                    <div className="px-2">
                      <Promo2 
                        image={item.image} 
                        title={item.title} 
                        description={item.description} 
                        descriptionVisible={promoDetailVisibility && promoDetailVisibility[item.id]}
                        end_date={item.end_date}
                        onMoreInfo={() => {
                          togglePromoDetail(item.id);
                        }}
                        onClose={() => {
                          togglePromoDetail(item.id);
                        }}
                      />
                    </div>
                    :null
                  }
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
        <MenuNotification />
      </div>

      {isLoading && <Loading />}
    </div>
  );
}

export default Promotions;
