import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import authService from "../service/auth.service";
import { ConfigContext } from '../ConfigContext';
import { useTranslation } from 'react-i18next';

function SignUp2({ userId }) {
    const [code, setCode] = useState('');
    const [verifyVal, setVerifyVal] = useState('');
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();


    const navigate = useNavigate()

    const getTacCode = async (userId) => {
        const TacCode = await authService.getTac(userId);
        if (TacCode.data.tac) {
            return TacCode.data.tac;
        }
    }

    const VerifyCode = async () => {
        if (code) {
            try {
                await authService.verifyTac({ userId, code });
                navigate('/signup3');
            } catch (err) {
                console.log(err)
                if (err.response && err.response.data && err.response.data.tac) {
                    const username_msg = err.response.data.tac;
                    if (Number(String(username_msg).slice(-2)) < 0) {
                        navigate('/signup');
                    }
                    setVerifyVal(username_msg);
                }
                if (err.response && err.response.data && err.response.data.detail) {
                    const username_msg = err.response.data.detail;
                    setVerifyVal(username_msg);
                }
            }
        }
    }


    const reSendCode = async () => {
        if (userId) {
            await getTacCode(userId);
        }
    }

    useEffect(() => {
        if (userId) {
            getTacCode(userId);
        }
    }, [userId])

    return (
        <div className='t3'>
            <div id="page">
                <div className="page-content pb-0">
                    <div data-card-height="cover-full" className="card mb-0">
                        <div className="card-center">
                            <div className="text-center">
                                <p className="font-600 mb-1 font-16" style={{ color: configData?configData.text_color_secondary :""}}>{t("Verify")} {t("Your_Identity")}</p>
                                <h1 className="font-26" style={{ color: configData?configData.text_color :""}}>{t("One_Time_Passcode")}</h1>
                            </div>
                            <div className="content px-4">
                                <div className="text-center mx-n3">
                                    <OtpInput
                                        containerStyle={{ marginTop: "1.5rem", columnGap: "10px", justifyContent: "space-between" }}
                                        inputStyle={{ width: "50px", height: "50px", borderRadius: "8px", color: "black", fontSize: "2rem", fontWeight: "bold" }}
                                        value={code}
                                        onChange={setCode}
                                        numInputs={5}
                                        separator={<span>-</span>}
                                        inputType="number"
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <div className={`text-center mt-2 ${(verifyVal === '') ? 'valid-feedback' : 'invalid-feedback'} `}>{verifyVal}</div>
                                </div>
                                <p
                                    className="text-center my-4 font-13" style={{ color: configData?configData.text_color :""}}>
                                    {t("Did_Not_get_code")} <span style={{ color: configData?configData.text_color_secondary :"", cursor:"pointer" }} onClick={reSendCode}>{t("Resend_Code")}</span>
                                </p>
                                <div
                                    data-back-button className="btn btn-full font-600 font-15 mt-4 rounded-xs text-uppercase"
                                    style={{backgroundColor : configData?configData.secondary_color:""}}
                                    onClick={VerifyCode}
                                >
                                    {t("Verify")} {t("Account")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp2