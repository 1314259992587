import React, { useContext } from 'react'
import Footer from '../components/Footer'
import { ConfigContext } from '../ConfigContext'

function Notifications() {
    const configData = useContext(ConfigContext)

    return (
        <div className='t1'>
            <div className='page'>
                <div className="page-content footer-clear">
                    <div id="menu-notifications"
                        className="rounded-m d-block mx-2"
                        // style={{ backgroundColor: "#fff" }}
                    >
                        <div className="menu-size" style={{ height: "75vh" }}>
                            <div className="content pb-2">
                                <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1 color_yellow">Deposit $100 Approved</h5>
                                        <p className="mb-0 font-11 opacity-70" style={{color : configData?configData.text_color :""}}>14th March 03:14 AM</p>
                                    </div>
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                            <i className="fa fa-check color-green-dark fa-3x"></i>
                                        </span>
                                    </div>
                                </a>
                                <div className="divider my-2 opacity-50"></div>
                                <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1 color_yellow">Withdraw $100 Rejected</h5>
                                        <p className="mb-0 font-11 opacity-70" style={{color : configData?configData.text_color :""}}>14th March 03:14 AM</p>
                                    </div>
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                            <i className="fa-solid fa-circle-minus color-red-dark fa-3x"></i>
                                            {/* <i className="fa-solid fa-ban color-red-dark fa-3x"></i> */}
                                        </span>
                                    </div>
                                </a>
                                <div className="divider my-2 opacity-50"></div>
                                <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1 color_yellow">Rebate $10 Approved</h5>
                                        <p className="mb-0 font-11 opacity-70" style={{color : configData?configData.text_color :""}}>14th March 03:14 AM</p>
                                    </div>
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                            <i className="fa fa-check color-green-dark fa-3x"></i>
                                        </span>
                                    </div>
                                </a>
                                <div className="divider my-2 opacity-50"></div>
                                <a href="page-activity.html" className="d-flex py-1 justify-content-between">
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1 color_yellow">Deposit $100 Approved</h5>
                                        <p className="mb-0 font-11 opacity-70" style={{color : configData?configData.text_color :""}}>14th March 03:14 AM</p>
                                    </div>
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 shadow-bg shadow-bg-xs">
                                            <i className="fa fa-check color-green-dark fa-3x"></i>
                                        </span>
                                    </div>
                                </a>
                                <div className="divider my-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Notifications