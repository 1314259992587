import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import userService from "../service/user.service";
import Footer from '../components/Footer';
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

const Deposit = () => {
  // const [typelist, setTypelist] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // const [ewalletsList, setEWalletsList] = useState([]);

  const configData = useContext(ConfigContext)

  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect(() => {
    getMerchantEWalletslist();
  }, [])


  const getMerchantEWalletslist = async () => {
    setLoading(true);
    const ewallets = await userService.getMerchantEWallets('surepay-ewallet');
    setLoading(false);
  }

  return (
    <div className="deposit-container">
      <div className="deposit-title" style={{background: configData?configData.deposit_withdraw_title_bg :""}}>
        <h6 className="deposit-texttitle" style={{ color: configData?configData.primary_color:"" }}>{(configData && configData.deposit_title)?configData.deposit_title:((configData && configData.static_deposit_title) ? t(configData.static_deposit_title) : "")}</h6>
      </div>
      <div className="deposit-wrap d-flex flex-wrap justify-center align-center">
        <div className="deposit_payment" onClick={() => navigate('/manual-transfer')}>
          <div className="transfer-background" style={{ backgroundImage: `url(${configData?configData.deposit_manual_deposit :""})` }}>
            <div className="d-flex flex-col w-full justify-center">
            </div>
          </div>
        </div>

        {
          configData && configData.available_paymentgateway_providers?
          configData.available_paymentgateway_providers.split(",").map(provider=>(
            <div className="deposit_payment" onClick={() => navigate(`/online-transfer/${provider}`)}>
              <div className="easypay-background"  style={{ backgroundImage: `url(${configData?configData[`deposit_${provider}`]:""})` }}>
                <div className="d-flex flex-col w-full justify-center">

                </div>
              </div>
            </div>
          )):null
        }
        {
          configData && configData.available_ewallets?
          configData.available_ewallets.split(",").map(provider=>(
            <div className="deposit_payment" onClick={() => navigate(`/${provider}-transfer`)}>
          <div className="ewallet-background" style={{ backgroundImage: `url(${configData?configData.deposit_ewallet :""})` }} >
            <div className="d-flex flex-col justify-center" style={{ width: "calc(100% - 45px)" }}>
            </div>
          </div>
        </div>
          )):null
        }
      </div >
      <div className="t1">
        <Footer />
      </div>
      {isLoading && <Loading />}
    </div >
  );
};

export default Deposit;
