import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import authService from "../../service/auth.service";
import tokenService from "../../service/token.service";
import { useContext } from "react";
import { ConfigContext } from "../../ConfigContext";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import Countdown from "react-countdown";
import Lottie from "lottie-react";
import OTPSuccess from "../../images/animation_success_otp.json";
import OTPFailed from "../../images/animation_failed_otp.json";
// import { toast } from "react-toastify";
import SignUp3 from "../SignUp3";
import useToast from "../../utils/useToast";

const initialValues = {
  username: "",
  password: "",
  password2: "",
  cc: "60",
  mobile: "",
  tac: "",
};

function LegacySignUp({ setUserId, userId, setAuth }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [values, setValues] = useState(initialValues);
  const [readOnly, setReadOnly] = useState(false);
  const [mobileValid, setMobileValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [password2Valid, setPassword2Valid] = useState(false);
  const [otpValid, setOtpValid] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [timer, setTimer] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(false);
  const [regDone, setRegDone] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [otpTime, setOtpTime] = useState();

  const configData = useContext(ConfigContext);
  const { t } = useTranslation();
  const toast = useToast();

  const [validations, setValidations] = useState({
    username: "",
    password: "",
    password2: "",
    cc: "",
    mobile: "",
    non_field_errors: "",
    referrer_code: "",
    tac: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "tac" && e.target.value.length < 5) {
      setValidations({
        ...validations,
        tac: "",
      });
    }
    if (e.target.name === "username" && e.target.value.length <= 5) {
      setUsernameValid(false)
    } else {
      setUsernameValid(true)
    }
    if (e.target.name === "tac" && e.target.value.length === 5) {
      verifyCode(e.target.value, userId);
      setOtpValid(true);
    }
    if (e.target.name === "tac" && e.target.value.length !== 5) {
      setOtpValid(false);
    }
    if (e.target.name === "password" && e.target.value.length >= 6) {
      setPasswordValid(true);
    }
    if (e.target.name === "password2" && e.target.value.length >= 6) {
      setPassword2Valid(true);
    }
    if (e.target.name === "tac" && e.target.value.length > 5) {
      return;
    }
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const {
    username: nameVal,
    password: passVal,
    password2: pass2Val,
    cc: ccVal,
    mobile: mobileVal,
    non_field_errors: nonVal,
    referrer_code,
    tac: tacVal,
  } = validations;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOtpLoading(true);

    let response;
    try {
      const updatedValues = {
        ...values,
        password2: values.password,
      };
      response = await authService.getLegacyTac(updatedValues);
      if (
        response &&
        response.data &&
        response.data.id &&
        response.data.recipient
      ) {
        setUserId(response.data.id);
        tokenService.setRegisterUser({
          ...updatedValues,
          id: response.data.id,
        });
        setTimer(true);
        setOtpDisabled(true);
        setOtpTime(Date.now() + 60000);
        toast.success("TAC sent successfully!");
      }
      // navigate("/signup3");
    } catch (err) {
      console.log("err => ", err, values);
      setTimer(false);
      setOtpDisabled(false);
      let username_message;
      let cc_message;
      let mobile_message;
      let password_message;
      let password2_message;
      let referrer_code_message;
      let non_field_errors_message;

      if (err.response && err.response.data && err.response.data.username) {
        username_message = err.response.data.username[0]
      }
      if (err.response && err.response.data && err.response.data.cc) {
        cc_message = err.response.data.cc;
      }
      if (err.response && err.response.data && err.response.data.mobile) {
        mobile_message = err.response.data.mobile;
      }
      if (err.response && err.response.data && err.response.data.password) {
        password_message = err.response.data.password;
      }
      if (err.response && err.response.data && err.response.data.password2) {
        password2_message = err.response.data.password2;
      }
      if (err.response && err.response.data && err.response.data.wait) {
        const wait = err.response.data.wait * 1000;
        setOtpTime(Date.now() + wait);
        setTimer(true);
        setOtpDisabled(true);
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.referrer_code
      ) {
        referrer_code_message = err.response.data.referrer_code;
      }
      if (
        err.response &&
        err.response.data &&
        err.response.data.non_field_errors
      ) {
        non_field_errors_message = err.response.data.non_field_errors[0];
        toast.error(non_field_errors_message);
      }
      setValidations({
        ...validations,
        username: username_message,
        password: password_message,
        password2: password2_message,
        mobile: mobile_message,
        cc: cc_message,
        referrer_code: referrer_code_message,
        non_field_errors: non_field_errors_message,
      });
    }
    setOtpLoading(false);
  };

  const verifyCode = async (code, userId) => {
    try {
      await authService.verifyLegacyTac({ userId, code });
      setRegDone(true);
      // navigate('/signup3');
    } catch (err) {
      console.log(err);
      let tac_message;
      setRegDone(false);
      if (err.response && err.response.data && err.response.data.tac) {
        const username_msg = err.response.data.tac;
        if (username_msg.length > 0) {
          tac_message = username_msg[0];
        }
      }
      if (err.response && err.response.data && err.response.data.detail) {
        const username_msg = err.response.data.detail;
        tac_message = username_msg;
      }
      setValidations({
        ...validations,
        tac: tac_message,
      });
    }
  };

  const getReferralCode = () => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("referrer");
    const localStorageCode = localStorage.getItem("referralCode");
    if (myParam || localStorageCode) {
      setValues((values) => {
        return { ...values, referrer_code: myParam || localStorageCode };
      });
      localStorage.removeItem("referralCode");
      sessionStorage.setItem("from_refer", myParam || localStorageCode);
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("from_refer")) {
      const ref_code = sessionStorage.getItem("from_refer");
      localStorage.setItem("referralCode", ref_code);
      sessionStorage.removeItem("from_refer");
      navigate(`/signup/?referrer=${ref_code}&openExternalBrowser=1`);
    }
    getReferralCode();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("registerUser");
    if (user) {
      const parsedUser = JSON.parse(user);
      if (parsedUser.id) {
        if (
          String(parsedUser.mobile).length >= 8 &&
          String(parsedUser.mobile).length <= 11
        ) {
          setMobileValid(true);
        }
        setValues({
          ...values,
          mobile: parsedUser.mobile,
          cc: parsedUser.cc,
          username: parsedUser.username
        });
        setUserId(parsedUser.id);
      }
    }
  }, [localStorage.getItem("registerUser")]);

  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setTimer(false);
      setOtpDisabled(false);
    } else {
      // Render a countdown
      setOtpDisabled(true);
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div className="t3">
      {toast.ToastContainer}
      <div id="page">
        <div className="page-content pb-0 mt-4">
          <div
            data-card-height="cover-full"
            className="background_class"
            style={{ height: "90vh" }}
          >
            <div>
              <ul className="progressbar mx-auto d-flex justify-content-center">
                <li className={`step d-flex flex-column ${!registered ? "active" : ""}`}><span className="step-number"  style={!registered?{
                    backgroundColor: configData
                      ? configData.secondary_color
                      : "",
                      color: configData ? configData.text_color : ""
                  }:{}}>1</span>
                  <span style={{color: configData ? configData.text_color : ""}}>REGISTER</span>
                  <div className="hr-line"  style={{
                    backgroundColor: configData
                      ? configData.secondary_color
                      : "",
                  }} /></li>
                <li className={`step d-flex flex-column ${registered ? "active" : ""}`}><span className="step-number" style={registered?{
                    backgroundColor: configData
                      ? configData.secondary_color
                      : "",
                      color: configData ? configData.text_color : ""
                  }:{}}>2</span>
                  <span style={{color: configData ? configData.text_color : ""}}>PERSONAL DETAILS</span>
                  <div className="hr-line" style={{
                    backgroundColor: configData
                      ? configData.secondary_color
                      : "",
                  }}/></li>
              </ul>

              {
              !registered ?
              <div className="content">
                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Username")} :
                </p>
                <div
                  className={`input-style has-borders no-icon validate-field mb-4  ${values.user ? "input-style-active" : ""
                    }`}
                >
                  <input
                    name="username"
                    onChange={handleChange}
                    type="text"
                    className={`form-control validate-name text-white`}
                    id="form1a"
                    value={values.username}
                    placeholder={t("Username")}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${nameVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                  >
                    {nameVal}
                  </div>
                </div>
                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Mobile_No")} :
                </p>
                <div>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      borderRadius: "0px",
                      height: "40px",
                    }}
                    containerStyle={{ marginTop: "0px", marginBottom: "10px" }}
                    buttonStyle={{ borderRadius: "0px" }}
                    country={configData ? (configData.phone_format) : "my"}
                    onlyCountries={configData ? [(configData.phone_format)] : ["my"]}
                    onChange={(phone, country) => {
                      if (
                        String(phone).length >= 8 &&
                        String(phone).length <= 11
                      ) {
                        setMobileValid(true);
                      }
                      const reducedPhone = phone.replace(country.dialCode, "");
                      setValues({
                        ...values,
                        cc: country.dialCode,
                        mobile: reducedPhone,
                      });
                    }}
                    className="has-borders text-white"
                  />
                  <div
                    className={`${ccVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                  >
                    {ccVal}
                  </div>
                  <div
                    className={`${mobileVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                  >
                    {mobileVal}
                  </div>
                </div>

                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Password")} :
                </p>
                <div className="input-style has-borders no-icon validate-field mb-4">
                  <input
                    type="password"
                    name="password"
                    className="form-control validate-text text-white"
                    id="form3"
                    placeholder={t("Password")}
                    onChange={handleChange}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${passVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                  >
                    {passVal}
                  </div>
                </div>

                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Confirm")} {t("Password")} :
                </p>

                <div
                  className={`input-style has-borders no-icon validate-field mb-4  ${values.password2 ? "input-style-active" : ""
                    }`}
                >
                  <input
                    type="password"
                    name="password2"
                    className={`form-control validate-password text-white`}
                    id="form1a"
                    placeholder={t("Confirm") + " " + t("Password")}
                    onChange={handleChange}
                  />
                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  {/* <em>(required)</em> */}
                  <div
                    className={`${pass2Val === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                  >
                    {pass2Val}
                  </div>
                  <div
                    className={`${nonVal === "" ? "valid-feedback" : "invalid-feedback"
                      } `}
                  >
                    {nonVal}
                  </div>
                </div>

                <p>{t("OTP_Note")}</p>
                <div className="request-otp-box">
                  <div
                    className={`input-style has-borders no-icon validate-field mb-4`}
                  >
                    <div className="otp-input-box d-flex">
                      <input
                        type="text"
                        name="tac"
                        className={`form-control text-white`}
                        id="form1b"
                        placeholder={t("SMS_OTP")}
                        onChange={handleChange}
                        value={values.tac}
                      />
                      {otpValid && (
                        <>
                          {regDone && (
                            <Lottie
                              className="success-otp-json"
                              animationData={OTPSuccess}
                              loop={false}
                              autoplay={true}
                              style={{ width: "40px" }}
                            />
                          )}
                          {!regDone && (
                            <Lottie
                              className="success-otp-json"
                              animationData={OTPFailed}
                              loop={false}
                              autoplay={true}
                              style={{ width: "40px" }}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className={`${tacVal === "" ? "valid-feedback" : "invalid-feedback"
                        } `}
                    >
                      {tacVal}
                    </div>
                  </div>

                  <div>
                    <Button
                      loading={otpLoading}
                      disabled={!usernameValid || !mobileValid || !passwordValid || !password2Valid || otpDisabled}
                      data-back-button
                      className="btn mx-auto btn_green register_btn"
                      style={{
                        backgroundColor: configData
                          ? configData.secondary_color
                          : "",
                        height: "40px",
                        fontWeight: "bold",
                      }}
                      onClick={handleSubmit}
                    >
                      {t("REQUEST_OTP")}
                    </Button>
                    {timer && (
                      <div className="otp-timer-box">
                        <p className="next-otp">Next OTP: </p>
                        <Countdown date={otpTime} renderer={renderer} />
                        <p className="timer-unit">Sec</p>
                      </div>
                    )}
                  </div>
                </div>

                <p
                  className="input-title my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Referral_Code")}
                </p>
                <div
                  className={`input-style has-borders no-icon validate-field mb-4`}
                >
                  <input
                    type="text"
                    name="referrer-code"
                    className={`form-control text-white`}
                    id="form1b"
                    placeholder={
                      t("Referral_Code") + " (" + t("optional") + ")"
                    }
                    onChange={handleChange}
                    value={values.referrer_code}
                    readOnly={readOnly}
                  />

                  <i className="fa fa-times disabled invalid color-red-dark"></i>
                  <i className="fa fa-check disabled valid color-green-dark"></i>
                  <div
                    className={`${referrer_code === ""
                        ? "valid-feedback"
                        : "invalid-feedback"
                      } `}
                  >
                    {referrer_code}
                  </div>
                </div>

                <Button
                  disabled={!regDone}
                  data-back-button
                  className="btn mx-auto w-100 btn-full btn_green register_btn mt-4"
                  style={{
                    backgroundColor: configData
                      ? configData.secondary_color
                      : "",
                    height: "40px",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                  // onClick={() => navigate("/signup3")}
                  onClick={() => setRegistered(true)}
                >
                  {t("REGISTER")}
                </Button>

                <p
                  className="input-title text-center my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                >
                  {t("Have_An_Account")}
                </p>
                <p
                  className="input-title text-center my-1 font-14"
                  style={{ color: configData ? configData.text_color : "" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (values && values.referrer_code) {
                      localStorage.setItem("from_refer", true);
                    }
                    navigate("/signin");
                  }}
                >
                  {t("Login_Here")}
                </p>
              </div>
              :
              <SignUp3 userId={userId} setAuth={setAuth} />
              }
            </div>
            {/* <div className="t1">
                            <Footer />
                        </div> */}
            {/* <div className="card-overlay"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegacySignUp;
