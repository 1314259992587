import React, { useState, useEffect, useContext } from "react";
import "./common.css";
import "@pqina/flip/dist/flip.min.css";
import { ConfigContext } from "../../ConfigContext";
import { useTranslation } from "react-i18next";

const CountdownTicker = () => {
  const configData = useContext(ConfigContext);
  const { t } = useTranslation();
  const [number, setNumber] = useState(configData && configData.jackpot_number);
  const minIncrement = configData && configData.jackpot_number_min_increment; 
  const maxIncrement = configData && configData.jackpot_number_max_increment;
  const currency = configData && configData.currency;

  useEffect(() => {
    const intervalId = setInterval(() => {
      const increment = (Math.random() * (maxIncrement - minIncrement)) + minIncrement;
      setNumber((prevNumber) => prevNumber + increment)
    }, 1000); // Increment every 1000ms (1 second)

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [minIncrement, maxIncrement, number]);

  const formattedNumber = number && number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const containerWidth = window.innerWidth;

  function calculateFontSize(containerWidth) {
    if (containerWidth <= 320) { // Very small phone
      return "10px";
    } else if (containerWidth <= 375) { // Small phone (e.g., iPhone SE)
      return "12px";
    } else if (containerWidth <= 414) { // Medium phone (e.g., iPhone 8 Plus)
      return "14px";
    } else if (containerWidth <= 768) { // Large phone and small tablets (e.g., iPad)
      return "16px";
    } else if (containerWidth <= 1024) { // Larger tablets (e.g., iPad Pro)
      return "18px";
    } else { // PCs and larger screens
      return "20px";
    }
  }

  return (
    <div className="p-2 eci88_banner position-relative">
      <div
        className="image-content-container"
        style={{
          backgroundImage: `url(${configData && configData.jackpot_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 0,
        }}
      >
        <div className="jackpot-ticker-container-image-left">
          <p className="jackpot-ticker-container-image-subtitle">{t("Jackpot").toUpperCase()}</p>
          <h5 className="jackpot-ticker-container-image-title">{t("Progressive").toUpperCase()}</h5>
        </div>
        <div
          style={{
            fontFamily: "advanced_dot_digital7",
            fontSize: calculateFontSize(containerWidth),
            textAlign: "center",
            color: "rgb(217, 125, 3)",
            fontWeight: "bold",
          }}
        >
          <span style={{ marginRight: "10px" }}>
            {currency}
          </span>
        </div>
        <div
          style={{
            fontFamily: "advanced_dot_digital7",
            fontSize: calculateFontSize(containerWidth),
            textAlign: "center",
            color: "rgb(217, 125, 3)",
            fontWeight: "bold",
          }}
        >
          <span style={{ marginRight: "30px" }}>
            {formattedNumber}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTicker;
