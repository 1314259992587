import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import Loading from "../components/Loading";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";
import TransitionsModal from '../components/common/TransitionsModal';
import QRCodeSection from '../pages/QRCodeSection';
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";

function ReferralSystem() {
  const [ewalletsList, setEWalletsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const [copyOpen, setCopyOpen] = useState(false);
  const [wallet, setWallet] = useState("");
  const [qrModalOpen, setQRModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const getWalletInfo = async () => {
    // setLoading(true);
    try {
        const wallet = await UserService.getBalance();
        setWallet(wallet.data);
    } catch (err) {
        console.log(err);
    }
  };

  useEffect(() => {
    const fetchWalletInfo = async () => {
        const user = AuthService.getCurrentUser();
        if (user) {
            await getWalletInfo();
            setCurrentUser(user);
        }
    };
    fetchWalletInfo();
}, [localStorage.getItem("user")]);

  const handleCopyLink = (link) => {
    if (link && link !== "") {
        navigator.clipboard.writeText(link);
        setCopyStatus(t("Successfully_Copied") + "!");
        setCopyOpen(true);

        setTimeout(() => {
            setCopyOpen(false);
        }, 1000);
    }
  };

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true);
  };

  const handleWhatsAppShare = (referralLink) => {
    const encodedReferralLink = encodeURIComponent(referralLink);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedReferralLink}`;
    window.open(whatsappURL, "_blank");
  };

  const handleTelegramShare = (referralLink) => {
    const encodedReferralLink = encodeURIComponent(referralLink);
    const telegramURL = `https://t.me/share/url?url=${encodedReferralLink}`;
    window.open(telegramURL, "_blank");
  };

  return (
    <div className="t1">
      <div className="deposit-title" style={{background: configData?configData.deposit_withdraw_title_bg :""}}>
        <h1 className="deposit-texttitle" style={{ color: configData?configData.primary_color:"" }}>
          {t("Referral_system")}
        </h1>
      </div>
      <div className='page'>
        <div className="banner-container w-full h-100 d-flex align-items-end p-2 flex-wrap" >
          <div className="d-flex flex-wrap">
              <img
                  src={configData ? configData.general_downline : ""}
                  style={{ width: configData && (configData.general_buttons_rows == "1") ? "50%" : "98%"}}
                  alt="Downline"
                  onClick={(e) => {
                      e.preventDefault();
                      navigate("/downline");
                  }}
              />
              <img
                  src={configData ? configData.general_share : ""}
                  style={{ width: configData && (configData.general_buttons_rows == "1") ? "50%" : "98%"}}
                  alt="Share"
                  onClick={() => {
                      setModalOpen(true);
                  }}
              />
          </div>

          <div className="d-flex flex-wrap">
              <img
                  src={configData ? configData.general_more_info : ""}
                  style={{ width: configData && (configData.general_buttons_rows == "1") ? "50%" : "98%", marginTop: "5px" }}
                  alt="Info"
                  onClick={handleInfoModalOpen}
              />
              <img
                  src={configData ? configData.general_copy_link : ""}
                  style={{ width: configData && (configData.general_buttons_rows == "1") ? "50%" : "98%", marginTop: "5px" }}
                  alt="Referral"
                  onClick={() => handleCopyLink(wallet.referral_link)}
              />
          </div>
        </div>
        <Footer />
        <MenuNotification />
        {
          isLoading && <Loading />
        }
        <div>
          <TransitionsModal
              title={t("Select_Share_Method")}
              open={modalOpen}
              onClose={() => setModalOpen(false)}
          >
              <div className="share_referral">
                  <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }} onClick={() => handleWhatsAppShare(wallet.referral_link)}>
                      <img src={configData ? configData.whatsapp : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
                      WhatsApp
                  </div>
                  <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }} onClick={() => handleTelegramShare(wallet.referral_link)}>
                      <img src={configData ? configData.telegram : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
                      telegram
                  </div>
                  <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }} onClick={() => {
                      setModalOpen(false);
                      setQRModalOpen(true);
                  }}>
                      <img src={configData ? configData.qrcode : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
                      QRCODE
                  </div>
              </div>
          </TransitionsModal>
          <TransitionsModal
                title={t("More_Info")}
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
            >
                <img
                    src={configData ? configData.more_info_modal : ""}
                    alt="Info"
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "1px",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                />
            </TransitionsModal>
            <TransitionsModal
                title="QR Code"
                open={qrModalOpen}
                onClose={() => setQRModalOpen(false)}
            >
                <QRCodeSection />
            </TransitionsModal>
            {copyOpen && (
                <TransitionsModal
                    title={t("Successfully_Copied") + "!!"}
                    open={copyOpen}
                    style={{ padding: "1rem" }}
                >
                    <p>{copyStatus}</p>
                </TransitionsModal>
            )}
        </div>
      </div>
      </div>
    )
  }

export default ReferralSystem;
