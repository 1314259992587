import React from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import TransitionsModal from "./TransitionsModal";

function Toast({ type, message, setToast }) {
    const {t} = useTranslation();
  return (
    <TransitionsModal
          title={""}
          open={true}
        //   onClose={() => setGameError(false)}
          style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
        >
          <div>
            <div className="flex flex-row justify-center">
            {
                type === "success"?
                <Icon icon="mdi:success-circle-outline" color="#4CAF50" width={70} />
                :
                <Icon icon="codicon:error" color="#f27474" width={70} />
            }
              {/* <img
                src={selectedGame.image_mobile}
                alt={selectedGame.name}
                style={{ width: "150px", borderRadius: "1rem" }}
              /> */}
            </div>
            <p className="text-md font-medium mt-3">
              {message}
            </p>
            <button
              onClick={() => setToast(null)}
              className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-l mt-3 h-12`}
            >
              {t("OK")}
            </button>
          </div>
        </TransitionsModal>
  );
}

export default Toast;
