import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

function VIPRanking() {
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();

  return (
    <div className="t1">
      <div className="deposit-title" style={{background: configData?configData.deposit_withdraw_title_bg :""}}>
        <h1 className="deposit-texttitle" style={{ color: configData?configData.primary_color:"" }}>
          {t("VIP_Ranking")}
        </h1>
      </div>
      <div className='page'>
        <div>
            <img src={configData ? configData.vip_ranking : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} />
        </div>
        <Footer />
        <MenuNotification />
      </div>
    </div>
    )
  }

export default VIPRanking;
