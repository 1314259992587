import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom';
import UserService from "../service/user.service";
import OnlineTransfer from '../components/OnlineTransfer'
import ManualTransfer from '../components/ManualTransfer'
import ManualTransfer2 from '../components/ManualTransfer2'
import ReloadCard from '../components/ReloadCard'
import Footer from '../components/Footer'
import MenuNotification from '../components/MenuNotification'
import DgpayTransfer from '../components/DgpayTransfer';
import { ConfigContext } from '../ConfigContext';

function Deposit({ type, vendor, title }) {
    const [typelist, setTypelist] = useState([]);
    const configData = useContext(ConfigContext)

    const id = useParams();
    const index = Object.values(id)[0];

    const getCouponTypes = async () => {
        const typelists = await UserService.getCouponTypelist();
        if (typelists) {
            const typeArr = typelists.data.map((item) => {
                return {
                    id: item[0],
                    name: item[1]
                }
            })
            setTypelist(typeArr);
        }
    }

    return (
        <div className='t1'>
        <div className='page'  style={{height: 'calc(100vh - 114px)', overflow: 'scroll'}}>
            <div className="page-content">
                <div className="mx-2 rounded-m">
                    <div className="mx-2 p-2 py-4">
                        {type === "/online-transfer/surepay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/online-transfer/sun2pay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/online-transfer/telcopay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/online-transfer/dgpay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/online-transfer/easypay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/online-transfer/gspay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/manual-transfer" && 
                        (
                        configData && configData.manual_deposit_style == "1" ? <ManualTransfer /> 
                        : 
                        configData && configData.manual_deposit_style == "2" ? <ManualTransfer2 /> 
                        : 
                        <ManualTransfer /> 
                        )
                        }
                        {type === "/dgpay-transfer" && <DgpayTransfer />}
                        {type === "/reload-card" && <ReloadCard tyid={index} typelist={typelist} getCouponTypes={getCouponTypes} />}
                    </div>
                    {/* {mode === "light-mode" && <div className={`card-overlay rounded-0 m-0 bg-black-light opacity-70`}></div>} */}
                </div>
            </div>

                <Footer />
            </div>
            <MenuNotification />
        </div>
    )
}

export function DGPayPaymentOptions (country) {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);
    const isWindowsPhone = /Windows Phone/.test(navigator.userAgent);
    const isBlackBerry = /BlackBerry/.test(navigator.userAgent);
    let paymentOptions;
    if(country === 'ID'){
        if (isIOS || isAndroid || isWindowsPhone || isBlackBerry) {
            paymentOptions = [
            { id: 10, name: "Dana" },
            { id: 11, name: "OVO" },
            { id: 12, name: "ShopeePay" },
            { id: 30, name: "Qris (Barcode sekali pakai saja)" },
            ];
        } else {
            paymentOptions = [
            { id: 10, name: "Dana" },
            { id: 30, name: "Qris (Barcode sekali pakai saja)" },
            ];
        }
    }else if (country === 'MY'){
        paymentOptions = [
            {id:"MAYB",name:"MayBank"},
            {id:"CIMB",name:"CIMB Bank"},
            {id:"PBBB",name:"Public Bank"},
            {id:"RHBB",name:"RHB Bank"},
            {id:"HOLB",name:"HONG LEONG Bank"},
            {id:"AFIN",name:"AFFIN ISALAMIC Bank"},
            {id:"SINA",name:"Bank SIMPANAN NASIONAL"}
        ]
    }else{
        paymentOptions = [
            {id:"none",name:"Not Avaliable"}
        ]
    }

    return paymentOptions;
}

export default Deposit