import React, { useContext } from "react";
import ClassicSignUp from "./signup/ClassicSignUp";
import LegacySignUp from "./signup/LegacySignUp";
import ClassicSignUp2 from "../components/layout/SignUp/ClassicSignUp2";
import LegacySignUp2 from "../components/layout/SignUp/LegacySignup2";
import { ConfigContext } from "../ConfigContext";
import LegacySignUp3 from "../components/layout/SignUp/LegacySignup3";
import LegacySignUp4 from "../components/layout/SignUp/LegacySignup4";

function SignUp({ setUserId, userId, setAuth }) {
  const configData = useContext(ConfigContext);
  const signupVersion =
    configData && configData.signup_version ? configData.signup_version : "V1";
  return (
    <>
      {signupVersion === "V1" ? (
        <ClassicSignUp setUserId={setUserId} />
      ) : signupVersion === "V2" ? (
        configData && configData.login_style == "4" ? (
          <LegacySignUp4
            setUserId={setUserId}
            userId={userId}
            setAuth={setAuth}
          />
        ) : (
          <LegacySignUp
            setUserId={setUserId}
            userId={userId}
            setAuth={setAuth}
          />
        )
      ) : signupVersion === "V3" ? (
        <LegacySignUp2
          setUserId={setUserId}
          userId={userId}
          setAuth={setAuth}
        />
      ) : signupVersion === "V4" ? (
        <LegacySignUp3
          setUserId={setUserId}
          userId={userId}
          setAuth={setAuth}
        />
      ) : (
        <ClassicSignUp setUserId={setUserId} />
      )}
    </>

    // <>
    //   {signupVersion=== "V1" ? (
    //     <>
    //       {
    //       (configData && (configData.login_style == "1")) || (configData && !configData.login_style)?
    //       <ClassicSignUp setUserId={setUserId} />
    //       :null
    //       }
    //       {
    //       (configData && (configData.login_style == "2"))?
    //       <ClassicSignUp2 setUserId={setUserId} />
    //       :null
    //       }
    //     </>
    //   ) : (
    //     <>
    //       {
    //       (configData && (configData.login_style == "1")) || (configData && !configData.login_style)?
    //       <LegacySignUp setUserId={setUserId} userId={userId} setAuth={setAuth} />
    //       :null
    //       }
    //       {
    //       (configData && (configData.login_style == "2"))?
    //       <LegacySignUp2 setUserId={setUserId} userId={userId} setAuth={setAuth} />
    //       :null
    //       }
    //     </>
    //   )}
    // </>
  );
}

export default SignUp;
