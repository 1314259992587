import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Icon } from "@iconify/react";
import formatDate from "../../utils/formatDate";
import { ConfigContext } from "../../ConfigContext";
import { useTranslation } from "react-i18next";
import { mapTType } from "../../data/typeMapping";

export default function RewardTable({ orders }) {
  const configData = React.useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "transparent",
        color: configData ? configData.text_color : "",
        border: `1px solid ${configData ? configData.border_color : ""}`,
      }}
    >
      <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{
              background: configData ? configData.primary_color : "",
              fontWeight: "bold",
            }}
          >
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0" }}
              className=""
              align="center"
            >
              {t("S_No")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0" }}
              className=""
              align="center"
            >
              {t("Type")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0" }}
              className=""
              align="center"
            >
              {t("Amount")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0" }}
              className=""
              align="center"
            >
              {t("Date")}
            </TableCell>
            <TableCell
              sx={{ fontSize: "12px", padding: "13px 0" }}
              className=""
              align="center"
            >
              {t("Status")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => {
            const dateTime = formatDate(row.to_date);
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "13px 0",
                    borderColor: configData ? configData.border_color : "",
                    color: configData ? configData.text_color : "",
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "13px 0",
                    borderColor: configData ? configData.border_color : "",
                    color: configData ? configData.text_color : "",
                  }}
                  align="center"
                  component="th"
                  scope="row"
                >
                {
                  (row.ttype === "RB" || row.ttype === "RF") && (
                    row.is_multilevel === 0 ? <><div>{mapTType(row.ttype)}</div><div>{row.referred_player && `(Referred: ${row.referred_player})`}</div></> : (
                      row.is_multilevel === 1 ? <><div>{mapTType(`M${row.ttype}`)}</div><div>{row.referred_player && `(Referred: ${row.referred_player})`}</div></> : 'NA'
                    )
                  )
                  ||
                  'NA' 
                }
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "13px 0",
                    borderColor: configData ? configData.border_color : "",
                    color: configData ? configData.text_color : "",
                  }}
                  align="left"
                >
                <div style={{marginLeft: '8px'}}>
                  <div>
                    {row.reward_amount}
                  </div>
                </div>
                </TableCell>
                {/* <TableCell sx={{ fontSize: "12px", padding: "13px 0"}} align='center'>{row.amount}</TableCell> */}
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "13px 0",
                    borderColor: configData ? configData.border_color : "",
                    color: configData ? configData.text_color : "",
                  }}
                  align="center"
                >
                  {dateTime.formattedDate}
                  {/* <br></br>
                  {dateTime.time} */}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "13px 0",
                    borderColor: configData ? configData.border_color : "",
                    color: configData ? configData.text_color : "",
                  }}
                  align="center"
                >
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span
                      className={`${
                        row.state === "approved"
                          ? "status-approve"
                          : "status-pending"
                      } status-p ${configData ? configData.text_color : ""}`}
                    >
                      {t(String(row.state)[0].toUpperCase() + String(row.state).slice(1))}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
