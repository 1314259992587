const MYAmountlist = [
    { id: 1, value: "10" },
    { id: 2, value: "30" },
    { id: 3, value: "50" },
    { id: 4, value: "100" },
    { id: 5, value: "200" },
    { id: 6, value: "250" },
    { id: 7, value: "300" },
    { id: 8, value: "500" },
    { id: 9, value: "1000" },
    { id: 10, value: "3000" },
    { id: 11, value: "5000" },
    { id: 12, value: "10000" },
];
const ThaiAmountlist = [
    { id: 1, value: "100" },
    { id: 2, value: "200" },
    { id: 3, value: "250" },
    { id: 4, value: "300" },
    { id: 5, value: "500" },
    { id: 6, value: "1000" },
    { id: 7, value: "3000" },
    { id: 8, value: "5000" },
    { id: 9, value: "10000" },
    { id: 10, value: "30000" },
    { id: 11, value: "50000" },
    { id: 12, value: "100000" },
];
const IndoAmountlist = [
    { id: 1, value: "20000" },
    { id: 2, value: "40000" },
    { id: 3, value: "80000" },
    { id: 4, value: "100000" },
    { id: 5, value: "150000" },
    { id: 6, value: "200000" },
    { id: 7, value: "250000" },
    { id: 8, value: "300000" },
    { id: 9, value: "350000" },
    { id: 10, value: "400000" },
    { id: 11, value: "500000" },
    { id: 12, value: "1000000" },
];
const MYEWAmountlist = [
    { id: 1, value: "30" },
    { id: 2, value: "50" },
    { id: 3, value: "100" },
    { id: 4, value: "200" },
    { id: 5, value: "250" },
    { id: 6, value: "300" },
    { id: 7, value: "500" },
    { id: 8, value: "1000" },
    { id: 9, value: "3000" },
    { id: 10, value: "5000" },
    { id: 11, value: "10000" },
    { id: 12, value: "15000" },
];

export const CountryAmountList = (country, type) => {
    switch(country){
        case 'ID': 
            return IndoAmountlist;
        case 'TH': 
            return ThaiAmountlist;
        default:
            if(type == "EW"){
                return MYEWAmountlist;
            } else {
                return MYAmountlist;
            }
    }
}
