import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import userService from '../service/user.service';
import { Icon } from '@iconify/react';
import {ConfigContext} from "../ConfigContext"
const Games = () => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const location = useLocation();
    const gameSrc = location.state ? location.state.gameSrc : null;
    const gameId = location.state ? location.state.gameId : null;
    const [gameRotate, setGameRotate] = useState({});
    const navigate = useNavigate();
    const configData = useContext(ConfigContext)

    useEffect(() => {
        const handleResize = () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const StopProduct = async () => {
        try {
          await userService.stopProduct(gameId);
          navigate("/")
        } catch (err) {
          console.log(err);
        }
      };
    if(gameSrc){
        return (
            <>
                <div
                className="w-full px-1 py-3 d-flex align-items-center"
                style={{
                    maxWidth: "500px",
                    // background: "#fff",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    zIndex: "100",
                }}
                onClick={() => StopProduct()}
                >
                <Icon icon="uil:angle-left" style={{ background: configData?configData.secondary_color:"",
                    color: configData?configData.text_color:"", padding: "10px", fontSize: "40px"  }} />
                </div>
                <div className="t1">
                    <div className="page">
                        <div>
                            <div style={{
                                transform: "rotate(0deg)",  // Reset the rotation for portrait
                                transformOrigin: "top left", // Adjust the transform origin
                                position: "absolute",
                                left: 0,
                                display: "block",
                                width: "100vw",              // Swap width and height
                                height: "100vh",             // Swap width and height
                                border: "none",
                            }}>
                                <iframe
                                frameBorder="0"
                                src={gameSrc}
                                allowtransparency="true" 
                                frameborder="0" 
                                scrolling="no" 
                                class="wistia_embed" 
                                name="wistia_embed" 
                                allowfullscreen 
                                mozallowfullscreen 
                                webkitallowfullscreen 
                                oallowfullscreen 
                                msallowfullscreen 
                                width="100%" 
                                height="100%"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }else{
        navigate("/");
    }
}

export default Games