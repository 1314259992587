import React from "react";
import "./common.css";
import SkeletonWrapper from "./SkeletonWrapper";
import Skeleton from "react-loading-skeleton";

const SkLoading = ({ currentUser }) => {
  return (
    <div className="sk-loading-container">
      <SkeletonWrapper>
        <Skeleton height={160} style={{ borderRadius: "8px" }} />
        <Skeleton height={20} />
        {!currentUser && (
          <div className="sk-auth-grid">
            {Array.from({ length: 2 }).map((_, index) => (
              <div className="grid-item" key={index}>
                <Skeleton height={50} width="100%" />
              </div>
            ))}
          </div>
        )}
        <Skeleton
          height={60}
          width="100%"
          style={{ borderRadius: "1rem", marginTop: "1rem" }}
        />
        <div className="sk-table-grid">
          <div className="grid-item">
            <Skeleton height={20} width="70%" />
          </div>
          <div className="grid-item">
            <Skeleton height={20} width="100%" />
          </div>
        </div>
        <Skeleton height={160} style={{ borderRadius: "8px" }} />
        <div className="sk-table-grid">
          <div className="grid-item">
            <Skeleton height={20} width="70%" />
          </div>
        </div>
        <div className="scrollable-container" style={{ padding: "0px" }}>
          <div className="scrollable-content">
            {Array.from({ length: 4 }).map((_, index) => (
              <Skeleton
                key={index}
                height={150}
                width={300}
                style={{ marginRight: "1rem" }}
              />
            ))}
          </div>
        </div>
        <Skeleton
          height={150}
          style={{ borderRadius: "8px", marginTop: "1rem" }}
        />
        <div className="sk-auth-grid">
          {Array.from({ length: 4 }).map((_, index) => (
            <div className="grid-item" key={index}>
              <Skeleton height={50} width="100%" />
            </div>
          ))}
        </div>
      </SkeletonWrapper>
    </div>
  );
};

export default SkLoading;
