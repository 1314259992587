import React from 'react';
import './common.css'; // Make sure to create the CSS file
import { ConfigContext } from '../../ConfigContext'; 
import { useContext } from 'react';

const ProductLoading = () => {
    const configData = useContext(ConfigContext)

    return (
        <div className={
            configData && configData.vertical_tab
              ? "loader-container-vertical"
              : "loader-container"
        }>
            {configData && configData.loading_image &&
            <img src={configData.loading_image} alt="" width="80px" height="80px" style={{ objectFit: "contain" }} /> 
            }
            <span class="loader"></span>
        </div>
    );
};

export default ProductLoading;
