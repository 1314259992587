import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import MenuNotification from "../components/MenuNotification";
import MenuSidebar from "../components/MenuSidebar";
import "./pages.css";

import AvatarUrl from "../image/avatar.png";
import { Avatar, Empty, Form, Input, Modal } from "antd";
import userService from "../service/user.service";
// import { toast } from "react-toastify";
import SkeletonWrapper from "../components/common/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import QRCode from "react-qr-code";

// import BankLogo from "../image/icon-bank.png";
// import QrLogo from "../image/icon-qrcode.png";
// import PassLogo from "../image/icon-password.png";
// import OrderItem from "../components/listsItems/OrderItem";
import authService from "../service/auth.service";
import useToast from "../utils/useToast";

function Account() {
  // const { mode } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  // const [orders, setOrders] = useState([]);
  // const [transfers, setTransfers] = useState([]);
  // const [bonuses, setBonuses] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [modalOpen, setModal] = useState(false);
  const [referModalOpen, setReferModal] = useState(false);
  const [bankModalOpen, setBankModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [myBank, setBank] = useState({
    name: "",
    number: "",
    metaData: {
      name: "",
    },
  });
  const toast = useToast();
  // const [deleteLoading, setDeleteLoading] = useState(false);

  const handleFormFailed = () => {
    toast.error("No inputs should be empty!");
  };

  const handleFormFinish = async (values) => {
    setModal(false);
    try {
      await authService.changePassword({ values, userId });
      toast.success("Password changed successfully!");
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.old_password) {
          const msg = data.old_password.old_password;
          return toast.error(msg);
        } else if (data.password) {
          const msg = data.password[0];
          return toast.error(msg);
        } else if (data.password2) {
          const msg = data.password2[0];
          return toast.error(msg);
        } else if (data.detail) {
          const msg = data.detail[0];
          return toast.error(msg);
        } else {
          return toast.error("Failed to change the password!");
        }
      }
    }
  };

  const getWalletInfo = async () => {
    setLoading(true);
    try {
      const wallet = await userService.getBalance();
      setUserId(wallet.data.user.id);
      setWallet(wallet.data);
      // getTransactions(wallet.data.user.id);
    } catch (err) {
      console.log(err);
    }
  };

  // const getTransactions = async (userId) => {
  //   setLoading(true);
  //   try {
  //     const orderData = await userService.getOrders(userId);
  //     const transferData = await userService.getTransfers(userId);
  //     const bonusData = await userService.getBonues(userId);
  //     if (orderData.data) {
  //       // setOrders(orderData.data);
  //     }
  //     if (transferData.data) {
  //       // setTransfers(transferData.data);
  //     }
  //     if (bonusData.data) {
  //       // setBonuses(bonusData.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   setLoading(false);
  // };

  const fetchCustomerBank = async () => {
    setLoading(true);
    try {
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }
      const bank = customerBank[0];
      setBank({
        ...bank,
        metaData: {
          name: "",
        },
      });

      // fetch bank meta data
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        const bankObj = {
          ...bank,
          metaData: bankData.data,
        };
        setBank(bankObj);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!wallet) {
      getWalletInfo();
    }
  }, [wallet]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchCustomerBank();
  }, []);

  // const onChange = (key) => {
  //   console.log(key);
  // };

  // const handleDelete = async (txnId, type) => {
  //   setDeleteLoading(true);
  //   try {
  //     // const result = await userService.deleteActor(userId, txnId);

  //     if (type === "order") {
  //       const newItems = [...orders];
  //       const filterItems = newItems.map((item) => ({
  //         ...item,
  //         state: item.id === txnId ? "deleted" : item.state,
  //       }));
  //       setOrders(filterItems);
  //     }
  //     if (type === "transfer") {
  //       const newItems = [...transfers];
  //       const filterItems = newItems.map((item) => ({
  //         ...item,
  //         state: item.id === txnId ? "deleted" : item.state,
  //       }));
  //       setTransfers(filterItems);
  //     }
  //     if (type === "bonus") {
  //       const newItems = [...bonuses];
  //       const filterItems = newItems.map((item) => ({
  //         ...item,
  //         state: item.id === txnId ? "deleted" : item.state,
  //       }));
  //       setBonuses(filterItems);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(`${err}`);
  //   }
  //   setDeleteLoading(false);
  // };

  // const items = [
  //   {
  //     key: "1",
  //     label: `Orders`,
  //     children: (
  //       <div>
  //         {orders.length > 0 ? (
  //           <>
  //             {orders.map((order) => (
  //               <OrderItem
  //                 item={order}
  //                 key={order.id}
  //                 handleDelete={handleDelete}
  //                 type="order"
  //                 loading={deleteLoading}
  //               />
  //             ))}
  //           </>
  //         ) : (
  //           <>
  //             <Empty description="No items found!" />
  //           </>
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: `Transfers`,
  //     children: (
  //       <div>
  //         {transfers.length > 0 ? (
  //           <>
  //             {transfers.map((order) => (
  //               <OrderItem
  //                 item={order}
  //                 key={order.id}
  //                 handleDelete={handleDelete}
  //                 type="transfer"
  //                 loading={deleteLoading}
  //               />
  //             ))}
  //           </>
  //         ) : (
  //           <>
  //             <Empty description="No items found!" />
  //           </>
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "3",
  //     label: `Bonuses`,
  //     children: (
  //       <div>
  //         {bonuses.length > 0 ? (
  //           <>
  //             {bonuses.map((order) => (
  //               <OrderItem
  //                 item={order}
  //                 key={order.id}
  //                 handleDelete={handleDelete}
  //                 type="bonus"
  //                 loading={deleteLoading}
  //               />
  //             ))}
  //           </>
  //         ) : (
  //           <>
  //             <Empty description="No items found!" />
  //           </>
  //         )}
  //       </div>
  //     ),
  //   },
  // ];

  return (
    <div className="t1">
      {toast.ToastContainer}
      <div className="page">
        <div className="page-content footer-clear">
          {loading ? (
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <SkeletonWrapper>
                <Skeleton circle width={100} height={100} />
                <Skeleton
                  style={{ marginTop: "1rem" }}
                  count={2}
                  width={windowWidth - 32}
                  height={100}
                  borderRadius={16}
                />
                <Skeleton
                  style={{ marginTop: "2rem" }}
                  count={1}
                  width={windowWidth - 32}
                  height={16}
                />
                <Skeleton
                  style={{ marginTop: "1rem" }}
                  count={4}
                  width={windowWidth - 32}
                  height={80}
                />
              </SkeletonWrapper>
            </div>
          ) : (
            <>
              {wallet ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "1rem",
                    padding: "1rem",
                  }}
                >
                  <Avatar
                    src={AvatarUrl}
                    style={{ width: "100px", height: "100px" }}
                  >
                    {wallet.username[0]}
                  </Avatar>
                  <div style={{ marginTop: "8px", width: "100%" }}>
                    <h3 style={{ textAlign: "center" }}>{wallet.username}</h3>
                    {/* <div className="balance-card">
                      <div className="balance-left">
                        <p>Cash (MYR)</p>
                        <h1>{wallet.balance}</h1>
                      </div>
                      <div className="balance-right">
                        <p>Chips (MYR)</p>
                        <h1>0.00</h1>
                      </div>
                    </div>
                    <div className="balance-card tools-card">
                      <div
                        onClick={() => setBankModal(true)}
                        className="tool-middle tool-left"
                      >
                        <div className="logo-box">
                          <img src={BankLogo} />
                        </div>
                        <h4>Bank Account</h4>
                      </div>
                      <div
                        onClick={() => setModal(true)}
                        className="tool-middle tool-center"
                      >
                        <div className="logo-box">
                          <img src={PassLogo} />
                        </div>
                        <h4>Change Password</h4>
                      </div>
                      <div
                        onClick={() => setReferModal(true)}
                        className="tool-middle tool-right"
                      >
                        <div className="logo-box">
                          <img src={QrLogo} />
                        </div>
                        <h4>View QR Code</h4>
                      </div>
                    </div> */}
                    
                    {/* <div style={{ marginTop: "1rem " }}>
                      <Tabs
                        defaultActiveKey="1"
                        tabBarStyle={{ color: "white" }}
                        items={items}
                        onChange={onChange}
                      />
                    </div> */}
                  </div>
                </div>
              ) : (
                <div>
                  <Empty description="No account!" />
                </div>
              )}
            </>
          )}
        </div>
        <Footer />
        <MenuNotification />
        <MenuSidebar />
      </div>
      <Modal
        title="Change Password"
        open={modalOpen}
        footer={[]}
        onCancel={() => setModal(false)}
      >
        <div className="modal-box">
          <Form onFinish={handleFormFinish} onFinishFailed={handleFormFailed}>
            <Form.Item
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "Please input your current password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px", marginTop: "1.5rem" }}
                placeholder="Current password"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "12px" }}
                placeholder="New password"
              />
            </Form.Item>
            <Form.Item
              name="password2"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
            >
              <Input.Password
                style={{
                  marginBottom: "8px",
                  padding: "12px",
                }}
                placeholder="Confirm new password"
              />
            </Form.Item>
            <div className="balance_btns" style={{ width: "100%" }}>
              <button
                type="submit"
                className="btns"
                style={{
                  width: "100%",
                  flex: 1,
                  border: "none",
                  padding: "10px 0",
                }}
              >
                Change Password
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Refer App"
        open={referModalOpen}
        footer={[]}
        onCancel={() => setReferModal(false)}
      >
        <div>
          {wallet ? (
            <div className="center" style={{ paddingTop: "1rem" }}>
              <QRCode value={wallet.referral_link} />
              <Input
                style={{ marginTop: "1rem" }}
                value={wallet.referral_link}
              />
            </div>
          ) : (
            <Empty description="Unable to load wallet!" />
          )}
        </div>
      </Modal>

      <Modal
        title="Bank Account"
        open={bankModalOpen}
        footer={[]}
        bodyStyle={{ height: "200px" }}
        style={{ height: "200px" }}
        onCancel={() => setBankModal(false)}
      >
        <div className="modal-box">
          <Input
            style={{ padding: "12px", marginTop: "1.5rem", height: "60px" }}
            placeholder="Full Name"
            readOnly
            value={myBank.name}
          />
          <Input
            style={{ padding: "12px", height: "60px", marginTop: "1.5rem" }}
            placeholder="Bank"
            readOnly
            value={myBank.metaData.name}
          />
          <Input
            style={{
              marginBottom: "8px",
              padding: "12px",
              height: "60px",
              marginTop: "1.5rem",
            }}
            placeholder="Account Number"
            readOnly
            value={myBank.number}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Account;
