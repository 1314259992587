import React from 'react'
import LayoutCard from '../common/LayoutCard'
import { ConfigContext } from '../../../ConfigContext'
import { useContext } from 'react'
import Loading from '../../Loading'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import userService from '../../../service/user.service'
import { useState } from 'react'
import Footer from "../../Footer";

function BankAccounts2({ myBanks, loading, fetchWallet,addBankHandler }) {
    const configData = useContext(ConfigContext)
    const {t} = useTranslation()
    const [banks, setBanks] = useState([])
    const getBankList = async () => {
        const getBanks = await userService.getBanks();
        setBanks(getBanks.data);
    }
    useEffect(() => {
        fetchWallet();
        getBankList();
    }, [localStorage.getItem("user")]);
    
    return (
    <div className="t1">
        <div className="page">
            <div className="page-content">
                <div className="layout2">
                    <div className="standard-form-container">
                        <input style={{ backgroundColor: configData ? configData.secondary_color : "#fff" }} type="button" className="standard-button mb-4" value={t("Add_Bank") + " " + t("Account")} onClick={addBankHandler} />
                        <LayoutCard>
                            <div className="banking-details-header d-flex justify-content-between align-items-center">
                                <label style={{ color: configData ? configData.text_color : "", textTransform: "uppercase" }}>{t("Bank_Accounts")}</label>
                                {/* <a href="" style={{backgroundColor: configData?configData.primary_color:""}}>
                                                            <img loading="lazy" style={{width:"auto", height:"20px"}} src="//nx-cdn.nexus2wl.com/Images/nexus-alpha/dark-orange/mobile/tabs/edit.svg?v=20231011-1" />
                                                        </a> */}
                            </div>
                            <div id="bank_account_carousel" className="bank-info-container d-flex flex-col" data-interval="false" data-ride="carousel">
                                {myBanks.map((item, index) => {
                                    const newBank = banks.filter(bank => bank.id == item.bank)
                                    let bankName = "";
                                    if (newBank && newBank.length) {
                                        bankName = newBank[0].name
                                    }
                                    return (
                                    <div className="carousel-inner mb-2">
                                        <div className="bank-info-block item active" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.tertiary_color : "" }}>
                                            <div className="account-name d-flex justify-content-between align-items-center" style={{ minHeight: "25px" }}>
                                                {item.name}
                                                <img className="bank-icon" style={{ width: "auto", height: "20px" }} loading="lazy" onerror="this.style.display='none'" src={item.icon} />
                                            </div>
                                            <div className="account-number">
                                                {item.number}
                                            </div>
                                            <hr />
                                            <div className="bank-name">
                                                {bankName}
                                            </div>
                                        </div>
                                    </div>
                                )})}
                            </div>
                        </LayoutCard>
                    </div>
                </div>
            </div>
        <Footer />
      </div>
    {loading && <Loading />}
  </div>
    )
}

export default BankAccounts2