import React from 'react'
import { useParams } from 'react-router-dom';
import MenuNotification from '../components/MenuNotification'
import EWalletTransfer from '../components/EWalletTransfer';
import Footer from '../components/Footer';

function DGPayEWallet({ ewalletsList, getMerchantEWalletslist }) {
    // const { mode } = useContext(UserContext)

    const params = useParams();
    const index = params['id'];

    // const getCouponTypes = async () => {
    //     const typelists = await UserService.getCouponTypelist();
    //     if (typelists) {
    //         const typeArr = typelists.data.map((item) => {
    //             return {
    //                 id: item[0],
    //                 name: item[1]
    //             }
    //         })
    //         setTypelist(typeArr);
    //     }
    // }

    return (
        <div className='page' style={{ height: 'calc(100vh - 114px)', overflow: 'scroll' }}>
            <div className="page-content footer-clear">
                <div className="mx-2 rounded-m">
                    <div className="mx-2 p-2 py-4">
                        <EWalletTransfer ewalletid={index} vendor="dgpay" ewalletsList={ewalletsList} getMerchantEWalletslist={getMerchantEWalletslist}  />
                    </div>
                </div>
            </div>
            <div className="t1">
                <Footer />
            </div>
            <MenuNotification />
        </div>
    )
}

export default DGPayEWallet