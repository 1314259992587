import React, { useContext, useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useRef } from 'react';
import { ConfigContext } from '../../ConfigContext';
import { useTranslation } from 'react-i18next';
import formatDate from "../../utils/formatDate";

const DataGrid = ({columns, data, getMoreItems, isMoreData}) => {
  const configData = useContext(ConfigContext)
  const {t} = useTranslation();

    const bottomEleRef = useRef();
    // const handleScroll = ([entry]) => {
    //     console.log("scrolled",entry)
    //     if (entry.isIntersecting) {
    //         console.log("scrolled 2" , getMoreItems)
    //         if (getMoreItems && isMoreData) {
    //             getMoreItems();
    //         }
    //     }
    // }
    // useEffect(() => {
    //     const debounceScroll = debounce(handleScroll,300)
    //     window.addEventListener("scroll", debounceScroll)
    //    return () => {
    //         window.removeEventListener("scroll", debounceScroll)
    //     }
    // const observer = new IntersectionObserver(handleScroll, {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 1.0
    // })
    // if (bottomEleRef.current && isMoreData) {
    //     observer.observe(bottomEleRef.current)
    // }
    // }, [bottomEleRef.current, isMoreData])
    
    // const handleScroll = (e) => {
    //     const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight

    //     if (!(window.scrollY >= scrollableHeight)) {
    //         return
    //     }
    //     if (getMoreItems && isMoreData) {
    //         getMoreItems();
    //     }
    //   };
      
    //   useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    //   }, [isMoreData, getMoreItems]);
  return (
    <TableContainer id="data-grid_container" sx={{ background: "transparent", color: "white", border: "1px solid #ffcb61" }}>
    <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableHead>
            <TableRow sx={{background: configData?configData.primary_color:"", fontWeight:"bold"}} >
                {columns.map((column => {
                return <TableCell sx={{ fontSize: "12px", padding: "13px 0"}} align='center'>{t(column.name)}</TableCell>
                }))}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((row, index) => {
                return(
                    <>
                        <TableRow
                            id="data-grid_row"
                            ref={(index===data.length - 1)?bottomEleRef:null}
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {
                            Object.entries(row).map(([key,value])=>{
                                const date = formatDate(value);
                                return (
                                    <TableCell sx={{ fontSize: "12px", padding: "13px 0",borderColor: configData?configData.border_color :"", color :configData?configData.text_color :"" }} align='center' component="th" scope="row">
                                        {
                                            key === "state" ? (
                                                <div className="d-flex flex-column align-items-center" style={{ gap: "5px" }}>
                                                <span
                                                    className={`${
                                                    row.state === "approved" ? "status-approve" : "status-pending"
                                                    } status-p ${configData ? configData.text_color : ""}`}
                                                >
                                                     {t(String(row.state)[0].toUpperCase() + String(row.state).slice(1))}
                                                </span>
                                                </div>
                                            ) : key === "date" ? (
                                                date.formattedDate
                                            ) : key === "type" ? (
                                                t(value)
                                            ): (
                                                value
                                            )
                                        }
                                    </TableCell>
                                )
                            }) 
                            }
                        </TableRow>
                    </>
            )})}
        </TableBody>
    </Table>
</TableContainer>
  )
}

export default DataGrid