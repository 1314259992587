import React, { useContext } from "react";
// import { toast } from "react-toastify";
import "./DailyCheckinV2.css";
import userService from "../../service/user.service";
import useToast from "../../utils/useToast";
// import { ConfigContext } from "../ConfigContext";

const DailyCheckinV2 = ({ checkin, fetchDailyCheckins, getWalletInfo }) => {
  // const configData = useContext(ConfigContext);
  const toast = useToast();
  let credits = 0;
  try {
    if(checkin && !checkin.new_implement){
      credits = parseFloat(
        checkin.min_days_checkin_bonus_amount / checkin.min_checkin_days
      ).toFixed(2);
    }
  } catch (error) {
    console.log(error);
  }

  const isToday = (index) => index + 1 === checkin.checked_days + 1;
  const isClaimed = (index) => index + 1 <= checkin.checked_days;

  const claimTodayCheckIn = async () => {
    try {
        const { data } = await userService.claimTodayCheckinV2();
        toast.success(`${data ? data.success : 'Congratulations !'}`);
        getWalletInfo();
        fetchDailyCheckins();
    } catch (err) {
      if(err && err.response && err.response.data && err.response.data.error){
        toast.error(err.response.data.error);
      }else{
        toast.error("Failed to claim");
      }
    }
  };
  
  return (
  <>
      <div className="checkin-container-v2 px-3">
        <div className="checkin-container-top">
        </div>
        {
          checkin && Array.from({ length: (checkin.min_checkin_days>28)?4:3 })
          .map((item, index, arr) => {
            return (
              <div className="checkin-container-bottom" style={{height: `calc(${100/arr.length}% - ${100/arr.length}px)`,top: `0`, transform: `translateY(calc(${100}px + ${(index)*100}%))`, backgroundImage: index==(arr.length - 1)?"none": `url(${require("../../images/checkin_bg.png")})`}}>
                {
                  index==(arr.length - 1) &&
                  <img src={require("../../images/checkin_bg.png")} alt="" />
                }
              </div>
            )
          })
        }
        {toast.ToastContainer}
        <div className="day-container flex-wrap" style={{marginTop: "15%", zIndex: "20"}}>
          {checkin && Array.from({ length: checkin.min_checkin_days }).map((item, index) => (
            <div
              className="day-box-v2 position-relative"
              key={index}
              style={{
                borderColor: "rgb(5, 94, 117)",
                // background: `url(
                //   ${index<=6?require("../../assets/images/DIAMOND.png"):(
                //     (index>6 && index<=13)?require("../../assets/images/TREE.png"):(
                //       (index>13 && index<=20)?require("../../assets/images/HEART.png"):(
                //         (index>20 && index<=30)?require("../../assets/images/SPADE.png"):""
                //       )
                //     )
                //   )}
                // )`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                // height: "140px",
                width: "calc((100% / 7) - 2px)",
                boxSizing: "border-box",
                filter:`grayscale(${isClaimed(index) ? "100%" : "0"})`
              }}
            >
              <div className="day-inner-box flex-col position-absolute" style={{top: "40%", zIndex: "10"}}>
                <p className="day" style={{fontWeight: "700", color: "#000"}}>
                  {index + 1}
                </p>
                <p className="day-done" style={{fontWeight: "700", color: "#000"}}>
                  {isClaimed(index) && "✅"}
                </p>
              </div>
              <img src={
                index<=6?require("../../images/DIAMOND.png"):(
                  (index>6 && index<=13)?require("../../images/TREE.png"):(
                    (index>13 && index<=20)?require("../../images/HEART2.png"):(
                      (index>20 && index<=30)?require("../../images/SPADE.png"):""
                    )
                  )
                )
              } alt="" />
              {/* <button
                style={
                  isToday(index)
                    ? {
                        // backgroundColor: "rgb(6, 214, 250)",
                        cursor: checkin.claimed_today ? "default" : "pointer",
                        padding: "2px 15px",
                        minWidth: "88px"
                      }
                    : {
                        background: "gray",
                        cursor: "default",
                        padding: "2px 15px",
                        minWidth: "88px"
                      }
                }
                className={`claim-btn btn btn-sm bg-red-dark rounded-xs text-uppercase
                                      font-700 btn-l mt-3 me-2 h-12`}
                onClick={() => claimTodayCheckIn(isToday(index))}
              >
                {isClaimed(index) || (isToday(index) && checkin.claimed_today) ? "✅" : "Claim"}
              </button> */}
              {/* <img
                src={require("../images/Diggold_FPromo.png")}
                width="30px"
                height="auto"
                alt=""
              /> */}
              {/* <p>{checkin.new_implement?(parseFloat(checkin.min_days_checkin_bonus_amount)+(index*checkin.checkin_amount_interval)):credits} {checkin.checkin_amount_type !== "CA"?"Credits":configData && configData.currency}</p> */}
            </div>
          ))}
        </div>
        <button
          style={checkin && checkin.claimed_today?{
            background: "#ddd",
            cursor: "pointer",
            padding: "5px 20px",
            minWidth: "88px",
            fontSize: "1rem",
            zIndex: "30"
          }:{
            // backgroundColor: "rgb(6, 214, 250)",
            cursor: "pointer",
            padding: "5px 20px",
            minWidth: "88px",
            fontSize: "1rem",
            zIndex: "30"
          }}
          className={`claim-btn d-flex align-items-center btn btn-sm ${checkin && !checkin.claimed_today?"bg-red-dark":""} rounded-xs text-uppercase
                                font-700 btn-l mt-auto mx-5 mb-2 h-12`}
          disabled={checkin && checkin.claimed_today}
          onClick={() => claimTodayCheckIn()}
        >
          {checkin && !checkin.claimed_today && <img style={{maxWidth: "15px", marginRight: "5px", filter: "invert(100%)"}} src={require("../../images/checkin_icon.png")} alt="" />}{checkin && checkin.claimed_today?"✅ Claimed":"Claim"}
        </button>
      </div>
      {/* <div className="row p-4 checkin_claim_box">     
        <div className="w-100 p-3 profile-winover">
          <h2 className="text-center mb-2">Reward Claim Message</h2>
          <div className="checkin_claim_msg p-1">
            <p>No rewards</p>
          </div>
        </div>
      </div> */}
  </>
  );
};

export default DailyCheckinV2;
