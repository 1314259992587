import React, { useContext, useEffect, useState } from 'react'
import userService from '../service/user.service'
import { ConfigContext } from '../ConfigContext'
import { useTranslation } from "react-i18next";

function TopWinnings() {
    const { t } = useTranslation();
    const [topWinnings, setTopWinnings] = useState([])
    const configData = useContext(ConfigContext);
    const getTopWinnings = async () => {
        const response = await userService.getTopWinnings()
        if (response && response.data) {
            const winnings = response.data.filter(winning=>{
                const startDate = new Date(winning.start_date);
                const endDate = new Date(winning.end_date);
                const dateToCheck = new Date();
                return (dateToCheck >= startDate && dateToCheck <= endDate)
            }).sort((a, b) => parseInt(a.sequence, 10) - parseInt(b.sequence, 10))
            setTopWinnings(winnings);
        }
    }
    useEffect(() => {
        getTopWinnings()
    }, [])
    
  return (
    <>
    <h5 className="top-winnings-title">{t("Top_Winning")}</h5>
    <div className='top-winnings_container'>
        {
            topWinnings.map(topWinning=>{
                return (
                    <div className='top-winning'>
                        <div className="top-winning-image_container">
                            <img src={topWinning.image} alt="" />
                        </div>
                        <h3 className="top-winning-title" style={{color: configData?configData.text_color_secondary:""}}>{topWinning.title}</h3>
                        <button className="top-winning-button" style={{background: configData?configData.primary_color:"", color: configData?configData.text_color:""}}>No.{topWinning.sequence}({topWinning.product_name})</button>
                    </div>
                )
            })
        }
    </div>
    </>
  )
}

export default TopWinnings