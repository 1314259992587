import React, { useState, useEffect, useContext } from "react";
import userService from "../service/user.service";
import { Modal } from "antd";

import { useNavigate } from "react-router-dom";
import QRCodeSection from "../pages/QRCodeSection";
import { ConfigContext } from "..//ConfigContext";
import TransitionsModal from "./common/TransitionsModal";
import { useTranslation } from "react-i18next";

const Banner = ({ currentUser }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [wallet, setWallet] = useState("");
  // const [referralLink, setReferralLink] = useState('');
  const [copyOpen, setCopyOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const [qrModalOpen, setQRModalOpen] = useState(false);
  const navigate = useNavigate();
  // const [referModalOpen, setReferModal] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const configData = useContext(ConfigContext)
  const { t } = useTranslation();


  // const [loading, setLoading] = useState(false);

  const getWalletInfo = async () => {
    // setLoading(true);
    try {
      const wallet = await userService.getBalance();
      setWallet(wallet.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleWhatsAppShare = (referralLink) => {
    const encodedReferralLink = encodeURIComponent(referralLink);
    const whatsappURL = `https://api.whatsapp.com/send?text=${encodedReferralLink}`;
    window.open(whatsappURL, "_blank");
  };

  const handleTelegramShare = (referralLink) => {
    const encodedReferralLink = encodeURIComponent(referralLink);
    const telegramURL = `https://t.me/share/url?url=${encodedReferralLink}`;
    window.open(telegramURL, "_blank");
  };

  // const handleWeChatShare = (referralLink) => {
  //   const encodedReferralLink = encodeURIComponent(referralLink);
  //   const weChatURL = `https://web.wechat.com/send?text=${encodedReferralLink}`;
  //   window.open(weChatURL, "_blank");
  // };

  // const handleLineShare = (referralLink) => {
  //   const encodedReferralLink = encodeURIComponent(referralLink);
  //   const lineURL = `https://social-plugins.line.me/lineit/share?url=${encodedReferralLink}`;
  //   window.open(lineURL, "_blank");
  // };

  // const handleMessengerShare = (referralLink) => {
  //   const encodedReferralLink = encodeURIComponent(referralLink);
  //   const messengerURL = `https://www.facebook.com/dialog/send?link=${encodedReferralLink}&app_id=1234567890`;
  //   window.open(messengerURL, "_blank");
  // };

  const handleCopyLink = (link) => {
    if (!currentUser) {
      return navigate("../../signin")
    }
    if (link && link !== "") {
      navigator.clipboard.writeText(link);
      setCopyStatus(t("Successfully_Copied") + "!");
      setCopyOpen(true);

      setTimeout(() => {
        setCopyOpen(false);
      }, 1000);
    }
  };

  const handleInfoModalOpen = () => {
    setInfoModalOpen(true);
  };

  useEffect(() => {
    if (!wallet) {
      getWalletInfo();
    }
  }, [wallet]);

  return (
    <div className="p-2 eci88_banner position-relative">
      <img src={configData ? configData.banner? configData.banner : "" : ""} alt="" style={{ width: "100%", borderRadius: "10px" }} />

      {configData && configData.general_buttons &&
      <div className="banner-container w-full h-100 d-flex align-items-end p-2 flex-wrap" style={{top: "0", left: "0"}} >
        <div className="d-flex flex-wrap" style={{flex: "50%", maxWidth: "50%", flexShrink: 0}}>
          <img
            src={configData ? configData.general_downline : ""}
            style={{ width: configData && (configData.general_buttons_rows == "1")?"50%":"98%", marginTop: "5px" }}
            alt="Downline"
            onClick={(e) => {
              e.preventDefault();
              navigate("/downline");
            }}
          />
          <img
            src={configData ? configData.general_share : ""}
            style={{ width: configData && (configData.general_buttons_rows == "1")?"50%":"98%", marginTop: "5px" }}
            alt="Share"
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </div>
        {/* <div className="d-flex flex-column" style={{flex: "50%", maxWidth: "50%", flexShrink: 0}}>
        </div> */}
        <div className="d-flex flex-wrap" style={{flex: "50%", maxWidth: "50%", flexShrink: 0}}>
          <img
            src={configData ? configData.general_more_info : ""}
            style={{ width: configData && (configData.general_buttons_rows == "1")?"50%":"98%", marginTop: "5px" }}
            alt="Info"
            onClick={handleInfoModalOpen}
          />
          <img
            src={configData ? configData.general_copy_link : ""}
            style={{ width: configData && (configData.general_buttons_rows == "1")?"50%":"98%", marginTop: "5px" }}
            alt="Referral"
            onClick={() => handleCopyLink(wallet.referral_link)}
          />
        </div>
        {/* <div className="d-flex flex-column" style={{flex: "50%", maxWidth: "50%", flexShrink: 0}}>
        </div> */}
      </div>}

      <TransitionsModal
        title={t("More_Info")}
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
      >
        <img
          src={configData ? configData.more_info_modal : ""}
          alt="Info"
          style={{
            width: "100%",
            height: "100%",
            margin: "1px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      </TransitionsModal>

      <TransitionsModal
        title={t("Select_Share_Method")}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <div className="share_referral">
          <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }} onClick={() => handleWhatsAppShare(wallet.referral_link)}>
            <img src={configData ? configData.whatsapp : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
            WhatsApp
          </div>
          <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }} onClick={() => handleTelegramShare(wallet.referral_link)}>
            <img src={configData ? configData.telegram : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
            telegram
          </div>
          {/* <div className="referral_icon" onClick={() => handleLineShare(wallet.referral_link)}>
            <img src={require("../images/line.png")} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
            Line
          </div>
          <div className="referral_icon" onClick={() => handleMessengerShare(wallet.referral_link)}>
            <img src={require("../images/messenger.png")} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
            Messenger
          </div>
          <div className="referral_icon" onClick={() => handleWeChatShare(wallet.referral_link)}>
            <img src={require("../images/wechat.png")} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
            Wechat
          </div> */}
          <div className="referral_icon" style={{ color: configData ? configData.text_color : "" }} onClick={() => {
            setModalOpen(false);
            setQRModalOpen(true);
          }}>
            <img src={configData ? configData.qrcode : ""} style={{ width: "100%", marginRight: "10px", marginTop: "5px" }} alt="Referral" />
            QRCODE
          </div>
        </div>
      </TransitionsModal>
      <TransitionsModal
        title="QR Code"
        open={qrModalOpen}
        onClose={() => setQRModalOpen(false)}
      >
        <QRCodeSection />
      </TransitionsModal>
      {copyOpen && (
        <TransitionsModal
          title={t("Successfully_Copied") + "!!"}
          open={copyOpen}
          style={{ padding: "1rem" }}
        >
          <p>{copyStatus}</p>
        </TransitionsModal>
      )}
    </div>
  );
};

export default Banner;
