import i18n from "i18next"
import { initReactI18next } from "react-i18next"
// import { languages } from "./languages"
import files from "./files.json"
import countries from "./countries.json"

// const AVAILABLE_LANG = process.env.REACT_APP_AVAILABLE_LANG?JSON.parse(process.env.REACT_APP_AVAILABLE_LANG):files;
const resources = {}
export const languages = files.map(file => {
    return {
        key: file.id,
        label: file.label,
        file: require(`./lang/${file.id}.json`),
    }
});
languages.forEach(lng => {
    resources[lng.key] = {
        translation: lng.file
    }
})
export const initializeI18next = () => {
    i18n.use(initReactI18next)
        .init({
            resources: resources,
            lng: files[0].id,
            fallbackLng: files[0].id,
            interpolation: {escapeValue: false}
        });
}