import "./pages.css";
import React, { useContext, useEffect, useState } from "react";
import userService from "../service/user.service";
import { Input, Tag } from "antd";
import { ConfigContext } from "../ConfigContext";
import { useTranslation } from "react-i18next";

const AppAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const {t} = useTranslation();
  const configData = useContext(ConfigContext)


  const fetchAppAccounts = async () => {
    try {
      const result = await userService.getAppAccounts();
      if (result.data && result.status === 200) {
        const data = result.data;
        setAccounts(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAppAccounts();
  }, []);

  return (
    <div className="app-accounts-container">
      {accounts.map((item) => (
        <div className="account-item">
          <img src={item.product.image_mobile} alt="game-thumbnail" />
          <div className="account-content">
            <div>
              <p>{item.login}</p>
              <Input.Password className="pass" value={item.password} />
            </div>
            <Tag
              style={{ marginTop: "8px", fontWeight: "400",color:configData?configData.text_color :"",backgroundColor : configData?configData.secondary_color:"" }}
            >
              {item.product.category.toUpperCase()}
            </Tag>
          </div>
          <h3>
            0.00
          </h3>
        </div>
      ))}
    </div>
  );
};

export default AppAccounts;
